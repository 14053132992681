var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Grupo",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.filters.nome,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "nome", $$v);
      },
      expression: "form.filters.nome"
    }
  })], 1), _c('avp-select-ativo', {
    attrs: {
      "cols": 3
    },
    model: {
      value: _vm.form.filters.ativo,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "ativo", $$v);
      },
      expression: "form.filters.ativo"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-tooltip', {
    attrs: {
      "text": 'Buscar',
      "icon": 'mdi-magnify'
    },
    on: {
      "click": function ($event) {
        return _vm.buscarGrupos();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Limpar',
      "icon": 'mdi-eraser'
    },
    on: {
      "click": function ($event) {
        return _vm.resetForm();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Incluir',
      "icon": 'mdi-plus'
    },
    on: {
      "click": function ($event) {
        return _vm.openDialogManterGrupo();
      }
    }
  })], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "mt-5",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.form.lists.listGrupos,
      "items-per-page": 10,
      "footer-props": _vm.$footerProps,
      "show-expand": ""
    },
    scopedSlots: _vm._u([{
      key: `item.nome`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.nomeDeExibicao) + " ")];
      }
    }, {
      key: `item.ativo`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('avp-label-ativo', {
          attrs: {
            "ativo": item.ativo
          }
        })];
      }
    }, {
      key: `item.acoes`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('avp-tooltip', {
          attrs: {
            "text": 'Visualizar Grupo',
            "icon": 'mdi-eye-outline',
            "small": true
          },
          on: {
            "click": function ($event) {
              return _vm.openDialogVisualizarGrupo(item);
            }
          }
        }), _c('avp-tooltip', {
          attrs: {
            "text": 'Editar Grupo',
            "icon": 'mdi-pencil',
            "small": true
          },
          on: {
            "click": function ($event) {
              return _vm.openDialogManterGrupo(item);
            }
          }
        }), _c('avp-tooltip', {
          attrs: {
            "text": `${item.ativo ? 'Inativar' : 'Ativar'} Grupo`,
            "icon": `${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`,
            "small": true
          },
          on: {
            "click": function ($event) {
              return _vm.inativarAtivarGrupo(item);
            }
          }
        })];
      }
    }, {
      key: "expanded-item",
      fn: function (_ref4) {
        var headers = _ref4.headers,
            item = _ref4.item;
        return [_c('td', {
          staticClass: "permissoes",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-chip-group', {
          attrs: {
            "active-class": "primary--text",
            "column": ""
          }
        }, _vm._l(item.permissoes, function (permissao, i) {
          return _c('v-chip', {
            key: i,
            staticClass: "chip-permissao"
          }, [_c('v-icon', {
            staticClass: "permissao",
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-checkbox-marked-circle-outline")]), _vm._v(" " + _vm._s(permissao.nome) + " ")], 1);
        }), 1)], 1)];
      }
    }], null, true)
  })], 1), _c('CadastrarEditarGrupoDialog', {
    attrs: {
      "visible": _vm.dialogManterGrupo.visible,
      "content": _vm.dialogManterGrupo.content,
      "edit": _vm.dialogManterGrupo.edit
    },
    on: {
      "onClose": function ($event) {
        return _vm.dialogManterGrupo.close();
      },
      "onSave": function ($event) {
        return _vm.buscarGrupos();
      }
    }
  }), _c('VisualizarGrupoDialog', {
    attrs: {
      "visible": _vm.dialogVisualizarGrupo.visible,
      "content": _vm.dialogVisualizarGrupo.content
    },
    on: {
      "onClose": function ($event) {
        return _vm.dialogVisualizarGrupo.close();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }