<template>
    <div class="page">
      <v-container fluid>
        <avp-breadcrumbs></avp-breadcrumbs>
        <v-form ref="form">
          <v-row class="d-flex justify-center mt-3">
            <avp-select-lotacao
              :cols="4"
              v-model="form.filters"
              relatorio
            ></avp-select-lotacao>
            <avp-select-ano-semestre
              :cols="4"
              :sufix="'do Ciclo'"
              v-model="form.filters"
              required
              all
            ></avp-select-ano-semestre>
        </v-row>
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="3">
            <v-text-field
              label="Nome"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="CPF"
              v-mask="'###########'"
              v-model="form.filters.cpf"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <avp-switch-button
            v-model="form.filters.analitico"
            :cols="2"
            label="Tipo de Relatório"
            labelChecked="Analítico"
            labelUnchecked="Sintético"
          ></avp-switch-button>
        </v-row>
          <div class="d-flex justify-center"></div>
          <div class="text-center">
            <v-col cols="12">
              <avp-tooltip
                :text="'Buscar'"
                :icon="'mdi-magnify'"
                @click="buscarRelatorios()"
              ></avp-tooltip>
              <avp-tooltip
                :text="'Limpar'"
                :icon="'mdi-eraser'"
                @click="resetForm()"
              ></avp-tooltip>
            </v-col>
          </div>
        </v-form>
        <!-- Se o resultado da consulta de relatório for analítico -->
        <v-data-table
          v-if="form.mostrarAnalitico"
          class="mt-5 printable"
          :headers="getFilteredHeader(headersAnalitico)"
          :items="form.lists.listRelatorios"
          :items-per-page="form.itemsPerPage"
          :footer-props="$footerProps"
        >
          <template v-slot:[`item.ano`]="{ item }">
            {{ item.ano }}
          </template>
          <template v-slot:[`item.semestre`]="{ item }">
            {{ item.semestre }}
          </template>
          <template v-slot:[`item.nome`]="{ item }">
            {{ item.nome }}
          </template>
          <template v-slot:[`item.cpf`]="{ item }">
            <div class="hide-report">{{ $esconderCpf(item.cpf) }}</div>
            <div class="report">{{ $formatCpf(item.cpf) }}</div>
          </template>
          <template v-slot:[`item.matricula`]="{ item }">
            {{ item.matricula }}
          </template>
          <template v-slot:[`item.cargo`]="{ item }">
            {{ item.cargo }}
          </template>
          <template v-slot:[`item.lotacao`]="{ item }">
            {{ item.lotacao }}
          </template>
          <template v-slot:[`item.realizaLogin`]="{ item }">
            {{ item.realizaLogin ? "Sim" : "Não" }}
          </template>
          <template v-slot:[`item.aceiteTermo`]="{ item }">
            {{ item.aceiteTermo ? "Sim" : "Não" }}
          </template>
          <template v-slot:[`item.cadastroAcao`]="{ item }">
            {{ item.cadastroAcao ? "Sim" : "Não" }}
          </template>
          <template v-slot:[`item.validacaoComprovacao`]="{ item }">
            {{ item.validacaoComprovacao ? "Sim" : "Não" }}
          </template>
        </v-data-table>
        <!-- Se o resultado da consulta de relatório for sintético -->
        <v-data-table
          v-else
          class="mt-5 printable"
          :headers="getFilteredHeader(headersSintetico)"
          :items="form.lists.listRelatorios"
          :items-per-page="form.itemsPerPage"
          :footer-props="$footerProps"
        >
          <template v-slot:[`item.ano`]="{ item }">
            {{ item.ano }}
          </template>
          <template v-slot:[`item.semestre`]="{ item }">
            {{ item.semestre }}
          </template>
          <template v-slot:[`item.lotacao`]="{ item }">
            {{ item.lotacao }}
          </template>
          <template v-slot:[`item.qtdRealizaLogin`]="{ item }">
            {{ item.qtdRealizaLogin }}
          </template>
          <template v-slot:[`item.qtdAceiteTermo`]="{ item }">
            {{ item.qtdAceiteTermo }}
          </template>
          <template v-slot:[`item.qtdCadastroAcao`]="{ item }">
            {{ item.qtdCadastroAcao }}
          </template>
          <template v-slot:[`item.qtdValidacaoComprovacao`]="{ item }">
            {{ item.qtdValidacaoComprovacao }}
          </template>
        </v-data-table>
        <avp-buttons
          v-if="form.lists.listRelatorios.length"
          :actionText="'Imprimir'"
          :actionIcon="'mdi-printer'"
          :cancelText="'Baixar CSV'"
          :cancelIcon="'mdi-file'"
          @click="baixarRelatorioPdf()"
          @cancel="baixarCSVQuantitativoCiclo()"
        ></avp-buttons>
      </v-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "ListarRelatoriosQuantitativoPorCiclo",
    data: () => ({
      onReport: false,
      form: {
        filters: {
          lotacao: null,
          ano: null,
          semestre: null,
          nome: null,
          cpf: null,
          analitico: true,
          tipoRelatorio: 2,
        },
        lists: {
          listRelatorios: [],
        },
        itemsPerPage: 10,
        geradorRelatorio: null,
        horaDaGeracao: null,
        totalProfissionais: 0,
        mostrarAnalitico: true,
        labelRelatorio: "",
      },
      headersAnalitico: [
        { text: "Ano", align: "center", value: "ano", onlyReport: false },
        { text: "Semestre", align: "center", value: "semestre", onlyReport: false },
        { text: "Nome", align: "center", value: "nome" },
        { text: "CPF", align: "center", value: "cpf", sortable: false },
        { text: "Matrícula", align: "center", value: "matricula" },
        { text: "Cargo", align: "center", value: "cargo" },
        { text: "Lotação", align: "center", value: "lotacao" },
        {
          text: "Realizar Login",
          align: "center",
          value: "realizaLogin",
          sortable: false,
        },
        {
          text: "Aceite do Termo LGPD",
          align: "center",
          value: "aceiteTermo",
          sortable: false,
        },
        {
          text: "Cadastro de Ação de capacitação",
          align: "center",
          value: "cadastroAcao",
          sortable: false,
        },
        {
          text: "Validação de Comprovação",
          align: "center",
          value: "validacaoComprovacao",
          sortable: false,
        },
      ],
      headersSintetico: [
        { text: "Ano", align: "center", value: "ano" },
        { text: "Semestre", align: "center", value: "semestre" },
        { text: "Lotação", align: "center", value: "lotacao", sortable: false },
        {
          text: "Qtd. Realizar Login",
          align: "center",
          value: "qtdRealizaLogin",
        },
        {
          text: "Qtd. Aceite do Termo LGPD",
          align: "center",
          value: "qtdAceiteTermo",
        },
        {
          text: "Qtd. Cadastro de Ação de capacitação",
          align: "center",
          value: "qtdCadastroAcao",
        },
        {
          text: "Qtd. Validação de Comprovação",
          align: "center",
          value: "qtdValidacaoComprovacao",
        },
      ],
    }),
    computed: {
      isAnalitico() {
        return this.form.filters.analitico;
      }
    },
    methods: {
      getFilteredHeader(headers){
        return headers.filter(header => {
          if(header.onlyView) return !this.onReport;
          if(header.onlyReport) return this.onReport;
          return true
        })
  
      },
      labelRelatorio() {
        return this.form.filters.analitico ? "Analítico" : "Sintético";
      },
      buscarRelatorios() {
        if (this.$validarFormulario()) {
          const buscarRelatorio = (params) => {
            return this.$obterRelatorioQuantitativoPorCiclo(params);
          };
          buscarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
            ({ data }) => {
              this.form.mostrarAnalitico = this.isAnalitico;
              this.form.geradorRelatorio = data.relatorio.geradorRelatorio;
              this.form.horaDaGeracao = data.relatorio.horaDaGeracao;
              if(this.isAnalitico) {
                this.form.lists.listRelatorios = data.relatorio.analitico;
              } else {
                this.form.lists.listRelatorios = data.relatorio.sintetico;
              }
              this.form.totalProfissionais = data.relatorio.totalProfissionais;
            }
          );
        }
      },
      imprimirRelatorioQuantitativoCiclo() {
        this.onReport = true;
        this.form.itemsPerPage = -1;
        const importCSStoPrint = () =>
          import("!raw-loader!sass-loader!/src/assets/styles/_print.scss");
        importCSStoPrint().then((scss) => {
          let newWindow = window.open();
          newWindow.document.write(`
          <div class="report">
            <style>${scss.default}</style>
            <div class="report-header-title">
              <h1>Relatório Quantitativo por Ciclo - ${ this.labelRelatorio() }</h1>
            </div>
            ${document.querySelector(".printable").outerHTML}
            <div class="report-header-title">
              <h3>Quantidade Total de Profissionais: ${
                this.form.totalProfissionais
              }</h3>
              <h3>Nome do usuário de Geração: ${this.form.geradorRelatorio}</h3>
              <h3>Data/Hora da Geração: ${this.form.horaDaGeracao}</h3>
            </div>
          </div>`);
          newWindow.print();
          newWindow.close();
          this.form.itemsPerPage = 10;
          this.onReport = false;
        });
      },
      baixarCSVQuantitativoCiclo() {
        const baixarRelatorio = (params) => {
          return this.$obterRelatorioQuantitativoPorCicloCsv(params);
        };
        baixarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
          ({ data }) => this.$baixarRelatorioCSV(data, `relatorio-quantitativo-por-ciclo-${this.labelRelatorio()}`)
        );
      },
      baixarRelatorioPdf() {
        const baixarPdf = (params) => {
          return this.$obterRelatorioPdf(params);
        };
        baixarPdf(this.$montarParamsFilters(this.form.filters))
        .then(({data}) => {
          

          const file = this.$getFileFromBase64(
            data.resposta.arquivoBase64, 
            data.resposta.nome, 
            data.resposta.tipoConteudo, 
            data.resposta.extensao
            );

          this.$baixarArquivo(file, data.resposta.tipoConteudo);

        });
      },
      resetForm() {
        this.$resetForm();
        this.form.lists.listRelatorios = [];
      }
    },
  };
  </script>