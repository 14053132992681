<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <avp-date-picker
            v-model="form.filters.dataInicioVerba"
            label="Data Início da Verba"
            cols="3"
          ></avp-date-picker>
          <avp-date-picker
            v-model="form.filters.dataFimVerba"
            label="Data Fim da Verba"
            cols="3"
          ></avp-date-picker>
          <avp-select-ativo
            v-model="form.filters.ativo"
            :cols="2"
          ></avp-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarVerbaPagamento()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterVerbaPagamento()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listVerbaPagamentos"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.dataInicioVerba`]="{ item }">
          {{ item.dataInicioVerba | date(true) }}
        </template>
        <template v-slot:[`item.dataFimVerba`]="{ item }">
          {{ (item.dataFimVerba ? item.dataFimVerba : 'Não Possui') | date(true) }}
        </template>
        <template v-slot:[`item.verba`]="{ item }">
          {{ item.verba }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            :text="'Editar Verba de Pagamento'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterVerbaPagamento(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Verba de Pagamento`"
            :icon="`${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`"
            :small="true"
            @click="inativarAtivarVerbaPagamento(item)"
          ></avp-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarVerbaPagamentoDialog
      :visible="dialogManterVerbaPagamento.visible"
      :content="dialogManterVerbaPagamento.content"
      :edit="dialogManterVerbaPagamento.edit"
      @onClose="dialogManterVerbaPagamento.close()"
      @onSave="buscarVerbaPagamento()"
    ></CadastrarEditarVerbaPagamentoDialog>
  </div>
</template>

<script>
import { Dialog, VerbaPagamento } from "~/commons/classes";
import CadastrarEditarVerbaPagamentoDialog from "./dialogs/CadastrarEditarVerbaPagamentoDialog.vue";

export default {
  name: "ManterVerbaPagamentoView",
  components: { CadastrarEditarVerbaPagamentoDialog },
  data: () => ({
    dialogManterVerbaPagamento: new Dialog(),
    form: {
      filters: {
        dataInicioVerba: null,
        dataFimVerba: null,
        ativo: null,
      },
      lists: {
        listVerbaPagamentos: [],
      },
    },
    headers: [
      { text: "Data/Hora Início da Verba", align: "center", value: "dataInicioVerba" },
      { text: "Data/Hora Fim da Verba", align: "center", value: "dataFimVerba" },
      { text: "Verba", align: "center", value: "verba" },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ],
  }),
  methods: {
    openDialogManterVerbaPagamento(verba) {
      verba
        ? this.$obterDadosVerbaPagamento(verba).then(
          ({ data }) => {
            this.dialogManterVerbaPagamento.open(new VerbaPagamento(data.verbaPagamento).format(), true)
          })
        : this.dialogManterVerbaPagamento.open(VerbaPagamento.Build());
    },
    buscarVerbaPagamento() {
      this.$buscarVerbaPagamento(this.$montarParamsFilters(this.form.filters)).then(
        ({ data }) => (this.form.lists.listVerbaPagamentos = data.verbas)
      );
    },
    inativarAtivarVerbaPagamento(verba) {
      this.$swal({
        text: `Deseja confirmar a ${
          verba.ativo ? "inativação" : "ativação"
        } do Verba de Pagamento?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarAtivarVerbaPagamento({ ...verba, ativo: !verba.ativo,}, verba.id).then(
          ({ data }) => {
            this.$notifySuccess(data.mensagem);
            this.buscarVerbaPagamento();
          });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listVerbaPagamentos = [];
    },
  },
  mounted() {
    this.buscarVerbaPagamento();
  },
};
</script>