<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="1000">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon>
            Visualizar da Comprovação da Ação de Capacitação
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Profissional"
                :value="content.profissional"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="CPF"
                v-mask="'###.###.###-##'"
                :value="content.cpf"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Ano"
                :value="content.ano"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Semestre"
                :value="content.semestre"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Nome da Instituição"
                :value="
                  content.instituicao.nomeDesOutro
                    ? content.instituicao.nomeDesOutro
                    : content.instituicao.nome
                "
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Tipo da Ação de Capacitação"
                :value="content.tipoAcaoCapacitacao.nome"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Eixo Temático"
                :value="content.eixoTematico.nome"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Trilha de Aprendizagem"
                :value="content.trilhaDeAprendizagem.nome"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Nome da Capacitação"
                :value="content.nomeAcaoCapacitacao"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="d-block">
              <v-text-field
                label="Discente/Docente"
                :value="content.tipoDaCondicaoCapacitacao"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="d-block">
              <v-text-field
                label="Carga Horária"
                :value="content.cargaHoraria"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Dia, Mês e Ano de Início"
                :value="content.mesAnoInicio"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Dia, Mês e Ano de Conclusão"
                :value="content.mesAnoConclusao"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col cols="12" class="d-block">
              <v-btn color="primary" @click="exibirPreviewArquivo"
                >Ver arquivo</v-btn
              >
            </v-col>

            <avp-preview-file
              :previewUrl="previewUrl"
              :visible="visibleDialogPreview"
              :path="content.anexo.path"
              descricao="Baixar arquivo"
              @onClose="onCloseDialogPreview()"
            >
            </avp-preview-file>
          </v-row>
        </div>
        <div class="text-center">
          <v-btn color="primary" @click="$emit('onClose')">Fechar</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, ValidarComprovacao } from "~/commons/classes";
export default {
  data: () => ({
    visibleDialogPreview: false,
    previewUrl: undefined,
  }),
  props: new DialogProps(ValidarComprovacao),
  methods: {
    exibirPreviewArquivo() {
      this.showPageLoading();
      this.$realizarDownloadPorPath({
        params: {
          path: this.content.anexo.path,
          nomeDeUsuario: this.$user.username,
        },
        responseType: "blob",
      }).then(({ data }) => {
        this.previewUrl = URL.createObjectURL(data);
        this.visibleDialogPreview = true;
      });
      this.hidePageLoading();
    },
    onCloseDialogPreview() {
      this.visibleDialogPreview = false;
    },
  },
};
</script>
