<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="3">
            <v-text-field
              label="Trilha de Aprendizagem"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <avp-select-ano-semestre
            :cols="4"
            :sufix="'do Ciclo'"
            v-model="form.filters"
            all
          ></avp-select-ano-semestre>
          <avp-select-ativo :cols="2" v-model="form.filters.ativo"></avp-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarTrilhasDeAprendizagem()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterTrilhasDeAprendizagem()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listTrilhasDeAprendizagem"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template v-slot:[`item.semestre`]="{ item }">
          {{ item.semestre }}
        </template>
        <template v-slot:[`item.trilha`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            :text="'Visualizar Trilha de Aprendizagem'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarTrilhaDeAprendizagem(item)"
          ></avp-tooltip>
          <avp-tooltip
            v-if="item.ativo"
            :text="'Editar Trilha de Aprendizagem'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterTrilhasDeAprendizagem(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Trilha de Aprendizagem`"
            :icon="`${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`"
            :small="true"
            @click="inativarAtivarTrilhaDeAprendizagem(item)"
          ></avp-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarTrilhaDeAprendizagemDialog
      :visible="dialogManterTrilhaDeAprendizagem.visible"
      :content="dialogManterTrilhaDeAprendizagem.content"
      :edit="dialogManterTrilhaDeAprendizagem.edit"
      @onClose="dialogManterTrilhaDeAprendizagem.visible = false"
      @onSave="buscarTrilhasDeAprendizagem()"
    ></CadastrarEditarTrilhaDeAprendizagemDialog>
    <VisualizarTrilhaDeAprendizagemDialog
      :visible="dialogVisualizarTrilhaDeAprendizagem.visible"
      :content="dialogVisualizarTrilhaDeAprendizagem.content"
      @onClose="dialogVisualizarTrilhaDeAprendizagem.visible = false"
    ></VisualizarTrilhaDeAprendizagemDialog>
  </div>
</template>

<script>
import { Dialog, TrilhaDeAprendizagem } from "~/commons/classes";
import CadastrarEditarTrilhaDeAprendizagemDialog from "./dialogs/CadastrarEditarTrilhaDeAprendizagemDialog.vue";
import VisualizarTrilhaDeAprendizagemDialog from "./dialogs/VisualizarTrilhaDeAprendizagemDialog.vue";

export default {
  name: "ManterTrilhaDeAprendizagemView",
  components: {
    CadastrarEditarTrilhaDeAprendizagemDialog,
    VisualizarTrilhaDeAprendizagemDialog,
  },
  data: () => ({
    dialogManterTrilhaDeAprendizagem: new Dialog(),
    dialogVisualizarTrilhaDeAprendizagem: new Dialog(),
    form: {
      filters: {
        nome: null,
        ano: null,
        semestre: null,
        ativo: null,
      },
      lists: {
        listTrilhasDeAprendizagem: [],
      }
    },
    headers: [
      { text: "Ano", align: "center", value: "ano" },
      { text: "Semestre", align: "center", value: "semestre" },
      { text: "Trilha de Aprendizagem", align: "center", value: "trilha" },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ],
  }),
  methods: {
    openDialogManterTrilhasDeAprendizagem(trilha) {
      trilha
        ? this.$obterDadosTrilhaDeAprendizagem(trilha).then(({ data }) => {
            this.dialogManterTrilhaDeAprendizagem.open(new TrilhaDeAprendizagem(data.trilhaAprendizagem).format(), true);
          })
        : this.dialogManterTrilhaDeAprendizagem.open(TrilhaDeAprendizagem.Build())
    },
    openDialogVisualizarTrilhaDeAprendizagem(trilha) {
      this.$obterDadosTrilhaDeAprendizagem(trilha).then(
        ({ data }) => (this.dialogVisualizarTrilhaDeAprendizagem.open(data.trilhaAprendizagem))
      );
    },
    buscarTrilhasDeAprendizagem() {
      this.$buscarTrilhasDeAprendizagem(this.$montarParamsFilters(this.form.filters)).then(
        ({ data }) => (this.form.lists.listTrilhasDeAprendizagem = data.trilhasAprendizagem)
      );
    },
    inativarAtivarTrilhaDeAprendizagem(trilha) {
      this.$swal({
        text: `Deseja ${
          trilha.ativo ? "inativar" : "ativar"
        } a Trilha de Aprendizagem?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarAtivarTrilhaDeAprendizagem({ ...trilha, ativo: !trilha.ativo,}, trilha.id).then(
          ({ data }) => {
            this.$notifySuccess(data.mensagem);
            this.buscarTrilhasDeAprendizagem();
          });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listTrilhasDeAprendizagem = [];
    },
  },
  mounted() {
    this.buscarTrilhasDeAprendizagem();
  }
};
</script>