var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-pencil")]), _vm._v(" " + _vm._s(_vm.edit ? "Editar" : "Cadastrar") + " Parâmetro da Comprovação ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_vm.visible ? _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome",
      "outlined": "",
      "required": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 2000);
      }, function (v) {
        return _vm.$min(v, 5);
      }]
    },
    model: {
      value: _vm.content.nome,
      callback: function ($$v) {
        _vm.$set(_vm.content, "nome", $$v);
      },
      expression: "content.nome"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.edit ? _c('v-text-field', {
    attrs: {
      "label": "Ano da comprovação",
      "outlined": "",
      "readonly": "",
      "dense": "",
      "value": _vm.content.ano
    }
  }) : _c('v-select', {
    attrs: {
      "label": "Ano da comprovação",
      "outlined": "",
      "dense": "",
      "items": _vm.content.listAnos,
      "item-text": 'ano',
      "item-value": 'ano',
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.content.ano,
      callback: function ($$v) {
        _vm.$set(_vm.content, "ano", $$v);
      },
      expression: "content.ano"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.edit ? _c('v-text-field', {
    attrs: {
      "label": "Semestre da Comprovação",
      "outlined": "",
      "readonly": "",
      "dense": "",
      "value": _vm.content.semestre
    }
  }) : _c('v-select', {
    attrs: {
      "label": "Semestre da Comprovação",
      "outlined": "",
      "dense": "",
      "items": _vm.content.listSemestres,
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.content.semestre,
      callback: function ($$v) {
        _vm.$set(_vm.content, "semestre", $$v);
      },
      expression: "content.semestre"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('avp-date-picker', {
    attrs: {
      "required": "",
      "label": "Data Início da Comprovação",
      "cols": "4",
      "dataEdicao": _vm.content.dataInicioDaComprovacao
    },
    model: {
      value: _vm.content.dataInicioDaComprovacao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "dataInicioDaComprovacao", $$v);
      },
      expression: "content.dataInicioDaComprovacao"
    }
  }), _c('avp-time-picker', {
    attrs: {
      "required": "",
      "label": "Hora",
      "cols": "4",
      "horaEdicao": _vm.content.horaInicioDaComprovacao
    },
    model: {
      value: _vm.content.horaInicioDaComprovacao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "horaInicioDaComprovacao", $$v);
      },
      expression: "content.horaInicioDaComprovacao"
    }
  })], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('avp-date-picker', {
    attrs: {
      "label": "Data Fim da Comprovação",
      "cols": "4",
      "required": "",
      "dataEdicao": _vm.content.dataFimDaComprovacao
    },
    model: {
      value: _vm.content.dataFimDaComprovacao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "dataFimDaComprovacao", $$v);
      },
      expression: "content.dataFimDaComprovacao"
    }
  }), _c('avp-time-picker', {
    attrs: {
      "required": "",
      "label": "Hora",
      "cols": "4",
      "horaEdicao": _vm.content.horaFimDaComprovacao
    },
    model: {
      value: _vm.content.horaFimDaComprovacao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "horaFimDaComprovacao", $$v);
      },
      expression: "content.horaFimDaComprovacao"
    }
  })], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('avp-date-picker', {
    attrs: {
      "label": "Data Inicio da Validação",
      "cols": "4",
      "required": "",
      "dataEdicao": _vm.content.dataInicioDaValidacao
    },
    model: {
      value: _vm.content.dataInicioDaValidacao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "dataInicioDaValidacao", $$v);
      },
      expression: "content.dataInicioDaValidacao"
    }
  }), _c('avp-time-picker', {
    attrs: {
      "required": "",
      "label": "Hora",
      "cols": "4",
      "horaEdicao": _vm.content.horaInicioDaValidacao
    },
    model: {
      value: _vm.content.horaInicioDaValidacao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "horaInicioDaValidacao", $$v);
      },
      expression: "content.horaInicioDaValidacao"
    }
  })], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('avp-date-picker', {
    attrs: {
      "required": "",
      "label": "Data Fim da Validação",
      "cols": "4",
      "dataEdicao": _vm.content.dataFimDaValidacao
    },
    model: {
      value: _vm.content.dataFimDaValidacao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "dataFimDaValidacao", $$v);
      },
      expression: "content.dataFimDaValidacao"
    }
  }), _c('avp-time-picker', {
    attrs: {
      "required": "",
      "label": "Hora",
      "cols": "4",
      "horaEdicao": _vm.content.horaFimDaValidacao
    },
    model: {
      value: _vm.content.horaFimDaValidacao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "horaFimDaValidacao", $$v);
      },
      expression: "content.horaFimDaValidacao"
    }
  })], 1)], 1) : _vm._e()], 1), _c('avp-buttons', {
    attrs: {
      "actionText": 'Salvar'
    },
    on: {
      "click": function ($event) {
        return _vm.salvar();
      },
      "cancel": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }