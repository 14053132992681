<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <avp-select-ano-semestre
              :cols="4"
              :sufix="'do Ciclo'"
              v-model="form.filters"
              required
              all
            ></avp-select-ano-semestre>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarRelatorios()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5 printable"
        :headers="getFilteredHeader(headers)"
        :items="form.lists.listRelatorios"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template v-slot:[`item.semestre`]="{ item }">
          {{ item.semestre }}
        </template>
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.dtInicioSemestre`]="{ item }">
          {{ item.dtInicioSemestre }}
        </template>
        <template v-slot:[`item.dtFimSemestre`]="{ item }">
          {{ item.dtFimSemestre }}
        </template>
        <template v-slot:[`item.dtInicioComprovacao`]="{ item }">
          {{ item.dtInicioComprovacao }}
        </template>
        <template v-slot:[`item.dtFimComprovacao`]="{ item }">
          {{ item.dtFimComprovacao }}
        </template>
        <template v-slot:[`item.dtInicioValidacao`]="{ item }">
          {{ item.dtInicioValidacao }}
        </template>
        <template v-slot:[`item.dtFimValidacao`]="{ item }">
          {{ item.dtFimValidacao }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acao`]="{ item }">
          {{ item.acao }}
        </template>
        <template v-slot:[`item.DataAcao`]="{ item }">
          {{ item.DataAcao }}
        </template>
      </v-data-table>
      <avp-buttons
        v-if="form.lists.listRelatorios.length"
        :actionText="'Imprimir'"
        :actionIcon="'mdi-printer'"
        :cancelText="'Baixar CSV'"
        :cancelIcon="'mdi-file'"
        @click="baixarRelatorioPdf()"
        @cancel="baixarCSVRelatorio()"
      ></avp-buttons>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "ListarRelatoriosDeLogDeConfiguracaoEdicao",
  data: () => ({
    onReport: false,
    form: {
      filters: {
        ano: null,
        semestre: null,
        tipoRelatorio: 5,
      },
      lists: {
        listRelatorios: [],
      },
      itemsPerPage: 10,
      geradorRelatorio: null,
      horaDaGeracao: null,
      totalRegistros: 0,
    },
    headers: [
      { text: "Ano", align: "center", value: "ano" },
      { text: "Semestre", align: "center", value: "semestre" },
      { text: "Nome", align: "center", value: "nome" },
      { text: "Data Início do Semestre", align: "center", value: "dtInicioSemestre" },
      { text: "Data Fim do Semestre", align: "center", value: "dtFimSemestre" },
      { text: "Data/hora Início da Comprovação", align: "center", value: "dtInicioComprovacao" },
      { text: "Data/hora Fim da Comprovação", align: "center", value: "dtFimComprovacao" },
      { text: "Data/hora Início da Validação", align: "center", value: "dtInicioValidacao" },
      { text: "Data/hora Fim da Validação", align: "center", value: "dtFimValidacao" },
      { text: "Ativo", align: "center", value: "ativo"},
      { text: "Ação", align: "center", value: "acao" },
      { text: "Nome do Usuário da Ação", align: "center", value: "nomeUsuarioAcao", onlyReport: true },
      { text: "Usuário da Ação", align: "center", value: "usuario", onlyReport: true },
      { text: "Data/hora da Ação", align: "center", value: "dataHoraAcao", onlyReport: true },
    ],
  }),
  methods: {
    getFilteredHeader(headers) {
      return headers.filter(header => {
        if (header.onlyView) return !this.onReport;
        if (header.onlyReport) return this.onReport;
        return true
      })
    },
    buscarRelatorios() {
      if (this.$validarFormulario()) {
        this.$obterRelatorioLogConfiguracaoEdicao(this.$montarParamsFilters(this.form.filters)).then(
          ({ data }) => {
          this.form.geradorRelatorio = data.relatorio.geradorRelatorio;
          this.form.geradorRelatorioUsuario = data.relatorio.geradorRelatorioUsuario;
            this.form.horaDaGeracao = data.relatorio.horaDaGeracao;
            this.form.totalRegistros = data.relatorio.totalRegistros;
          this.form.lists.listRelatorios = data.relatorio.registros;
          }
        );
      }
    },
    imprimirRelatorio() {
      this.onReport = true;
      this.form.itemsPerPage = -1;
      const importCSStoPrint = () =>
        import("!raw-loader!sass-loader!/src/assets/styles/_print.scss");
      importCSStoPrint().then((scss) => {
        let newWindow = window.open();
        newWindow.document.write(`
        <div class="report">
          <style>${scss.default}</style>
          <div class="report-header-title">
            <h1>Relatório de log de Configuração de Edição</h1>
          </div>
          ${document.querySelector(".printable").outerHTML}
          <div class="report-header-title">
            <h3>Nome do usuário de Ação: ${this.form.geradorRelatorio}</h3>
            <h3>Usuario da Ação: ${
              this.form.geradorRelatorioUsuario
            }</h3>
            <h3>Data/Hora da Ação: ${this.form.horaDaGeracao}</h3>
          </div>
        </div>`);
        newWindow.print();
        newWindow.close();
        this.form.itemsPerPage = 10;
        this.onReport = false;
      });
    },
    baixarCSVRelatorio() {
      this.$obterRelatorioCSVLogConfiguracaoEdicao(this.$montarParamsFilters(this.form.filters)).then(
        ({ data }) => this.$baixarRelatorioCSV(data, "relatorio-log-configuracao-edicao")
      );
    },
    baixarRelatorioPdf() {
        const baixarPdf = (params) => {
          return this.$obterRelatorioPdf(params);
        };
        baixarPdf(this.$montarParamsFilters(this.form.filters))
        .then(({data}) => {
          

          const file = this.$getFileFromBase64(
            data.resposta.arquivoBase64, 
            data.resposta.nome, 
            data.resposta.tipoConteudo, 
            data.resposta.extensao
            );

          this.$baixarArquivo(file, data.resposta.tipoConteudo);

        });
      },
    resetForm() {

        this.$resetForm();

        this.form.lists.listRelatorios = [];

      }
  },
};
</script>