var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-3"
  }, [_c('avp-select-lotacao', {
    attrs: {
      "cols": 2,
      "relatorio": ""
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.filters.nome,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "nome", $$v);
      },
      expression: "form.filters.nome"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Perfil",
      "items": _vm.form.lists.listPerfis,
      "item-text": 'nome',
      "item-value": 'id',
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function (perfilId) {
        return _vm.desabilitarCiclo(perfilId);
      }
    },
    model: {
      value: _vm.form.filters.perfilId,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "perfilId", $$v);
      },
      expression: "form.filters.perfilId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Situação",
      "items": _vm.opcoesStatus,
      "item-text": "descricao",
      "item-value": "value",
      "id": "situacao",
      "dense": "",
      "outlined": "",
      "hide-details": "auto"
    },
    model: {
      value: _vm.form.filters.situacao,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "situacao", $$v);
      },
      expression: "form.filters.situacao"
    }
  })], 1), _c('avp-switch-button', {
    attrs: {
      "cols": 2,
      "label": "Tipo de Relatório",
      "labelChecked": "Analítico",
      "labelUnchecked": "Sintético"
    },
    model: {
      value: _vm.form.filters.analitico,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "analitico", $$v);
      },
      expression: "form.filters.analitico"
    }
  })], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('avp-select-ano-semestre', {
    key: _vm.componentKey,
    attrs: {
      "cols": 4,
      "sufix": 'da Comprovação',
      "all": "",
      "disabled": _vm.disabledAnoSemestre,
      "active": _vm.form.filters.active
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-tooltip', {
    attrs: {
      "text": 'Buscar',
      "icon": 'mdi-magnify'
    },
    on: {
      "click": function ($event) {
        return _vm.buscarRelatorios();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Limpar',
      "icon": 'mdi-eraser'
    },
    on: {
      "click": function ($event) {
        return _vm.resetForm();
      }
    }
  })], 1)], 1)], 1), _vm.form.mostrarAnalitico ? _c('v-data-table', {
    staticClass: "mt-5 printable",
    attrs: {
      "headers": _vm.getFilteredHeader(_vm.headersAnalitico),
      "items": _vm.form.lists.listRelatorios,
      "items-per-page": _vm.form.itemsPerPage,
      "footer-props": _vm.$footerProps,
      "show-expand": !_vm.onReport
    },
    scopedSlots: _vm._u([{
      key: "expanded-item",
      fn: function (_ref) {
        var headers = _ref.headers,
            item = _ref.item;
        return [_c('td', {
          staticClass: "perfis",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-chip-group', {
          attrs: {
            "active-class": "primary--text",
            "column": ""
          }
        }, _vm._l(item.perfis, function (perfil, i) {
          return _c('v-chip', {
            key: i,
            staticClass: "chip-permissao"
          }, [_c('v-icon', {
            staticClass: "perfil",
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-account-multiple")]), _vm._v(" " + _vm._s(perfil.nome) + " ")], 1);
        }), 1)], 1)];
      }
    }, {
      key: `item.situacao`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('avp-label-ativo', {
          attrs: {
            "ativo": item.situacao
          }
        })];
      }
    }, {
      key: `item.validador`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('avp-label-ativo', {
          attrs: {
            "ativo": item.validador
          }
        })];
      }
    }, {
      key: `item.cpf`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "hide-report"
        }, [_vm._v(" " + _vm._s(item.cpfComMascara) + " ")]), _c('div', {
          staticClass: "report"
        }, [_vm._v(" " + _vm._s(_vm.$formatCpf(item.cpfSemMascara)) + " ")])];
      }
    }], null, true)
  }) : _c('v-data-table', {
    staticClass: "mt-5 printable",
    attrs: {
      "headers": _vm.getFilteredHeader(_vm.headersSintetico),
      "items": _vm.form.lists.listRelatorios,
      "items-per-page": _vm.form.itemsPerPage,
      "footer-props": _vm.$footerProps
    },
    scopedSlots: _vm._u([{
      key: `item.nome`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.nomeProfissional) + " ")];
      }
    }, {
      key: `item.cpf`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "hide-report"
        }, [_vm._v(" " + _vm._s(item.cpfComMascara) + " ")]), _c('div', {
          staticClass: "report"
        }, [_vm._v(" " + _vm._s(_vm.$formatCpf(item.cpfSemMascara)) + " ")])];
      }
    }], null, true)
  }), _vm.form.lists.listRelatorios.length ? _c('avp-buttons', {
    attrs: {
      "actionText": 'Imprimir',
      "actionIcon": 'mdi-printer',
      "cancelText": 'Baixar CSV',
      "cancelIcon": 'mdi-file'
    },
    on: {
      "click": function ($event) {
        return _vm.imprimirRelatorio();
      },
      "cancel": function ($event) {
        return _vm.baixarCSVRelatorio();
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }