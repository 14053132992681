<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Editar" : "Cadastrar" }} pergunta/resposta do FAQ
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="8">
                <v-text-field
                  v-model="content.pergunta"
                  label="Pergunta"
                  counter="150"
                  outlined
                  dense
                  :rules="[
                    (v) => $required(v),
                    (v) => $max(v, 150),
                    (v) => $min(v, 10),
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="content.posicao"
                  label="Posição"
                  type="number"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $minValue(v, 1)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="12">
                <v-textarea
                  v-model="content.resposta"
                  label="Resposta"
                  counter="1000"
                  outlined
                  dense
                  :rules="[
                    (v) => $required(v),
                    (v) => $max(v, 1000),
                    (v) => $min(v, 10),
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center mt-7">
              <v-combobox
                v-model="content.palavrasChave"
                chips
                label="Palavras-Chave"
                hint="Informe as palavras chave para essa Pergunta/Resposta"
                multiple
                solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    close
                    v-bind="attrs"
                    :input-value="selected"
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>
                      <v-icon small>mdi-key</v-icon>
                      {{ item }}
                    </strong>
                  </v-chip>
                </template>
              </v-combobox>
            </v-row>
          </v-form>
        </div>
        <avp-buttons
          @click="salvar()"
          @cancel="$emit('onClose')"
          :actionText="'Salvar'"
        ></avp-buttons>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, FAQ } from "~/commons/classes";

export default {
  props: {
    ...new DialogProps(FAQ),
    positions: {
      type: Object,
      default: () => ({
        savedPosition: null,
        lastPosition: null,
        allPositions: [],
      }),
    },
  },
  watch: {
    visible(val) {
      if (!val) {
        this.$emit("onClose");
      } else if (!this.content.posicao) {
        this.content.posicao = this.positions.lastPosition;
      } else {
        this.positions.savedPosition = this.content.posicao;
      }
    },
  },
  methods: {
    salvar() {
      const save = () => {
        const jaExistePosicao = this.positions.allPositions.find(
          (p) => p == this.content.posicao && p != this.positions.savedPosition
        );
        const getTextoConfirmacao = `Confirma ${
          this.content.id ? "edição" : "cadastro"
        } da pergunta e resposta do FAQ?`;
        const getTextoPergunta = `A posição ${this.content.posicao} já está preenchida por outra pergunta, deseja atribuir a sua pergunta para a última posição (${this.positions.lastPosition}), podendo ser alterada futuramente?`;
        return this.$swal({
          text: jaExistePosicao ? getTextoPergunta : getTextoConfirmacao,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          jaExistePosicao &&
            (this.content.posicao = this.positions.lastPosition);
          return this.content.id
            ? this.$editarPerguntaFaq(this.content, this.content.id)
            : this.$cadastrarPerguntaFaq(this.content);
        });
      };
      if (this.$validarFormulario()) {
        save().then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.$emit("onClose");
          this.$emit("onSave");
        });
      }
    },
    remove(item) {
      this.content.palavrasChave.splice(
        this.content.palavrasChave.indexOf(item),
        1
      );
    },
  },
};
</script>
