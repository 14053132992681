<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Editar" : "Cadastrar" }} Verba
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <avp-date-picker
                v-model="content.dataInicioVerba"
                label="Data Início da Verba"
                required
                cols="8"
                :dataEdicao="content.dataInicioVerba"
              ></avp-date-picker>
              <avp-time-picker
                v-model="content.horaInicioVerba"
                label="Hora Início da Verba"
                required
                cols="4"
                :horaEdicao="content.horaInicioVerba"
              ></avp-time-picker>
              <avp-date-picker
                v-model="content.dataFimVerba"
                label="Data Fim da Verba"
                required
                cols="8"
                :dataEdicao="content.dataFimVerba"
              ></avp-date-picker>
              <avp-time-picker
                v-model="content.horaFimVerba"
                label="Hora Fim da Verba"
                cols="4"
                required
                :horaEdicao="content.horaFimVerba"
              ></avp-time-picker>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Verba"
                  counter="10"
                  v-model="content.verba"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $max(v, 10)]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <avp-buttons
          @click="salvar()"
          @cancel="$emit('onClose')"
          :actionText="'Salvar'"
        ></avp-buttons>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, VerbaPagamento } from "~/commons/classes";

export default {
  props: new DialogProps(VerbaPagamento),
  methods: {
    salvar() {
      const dataInicioFormatada = this.$formatarDateParaEnviar(this.content.dataInicioVerba, this.content.horaInicioVerba);
      const dataFimFormatada = this.$formatarDateParaEnviar(this.content.dataFimVerba, this.content.horaFimVerba);
      const save = () => {
        return this.$swal({
          text: `Deseja confirmar ${
            this.edit ? "a alteração" : "o cadastro"
          } da Verba de Pagamento?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          const objEnvio = new VerbaPagamento({
            ...this.content,
            dataInicioVerba: dataInicioFormatada,
            dataFimVerba: dataFimFormatada,
          });
          return this.edit
            ? this.$editarVerbaPagamento(objEnvio, this.content.id)
            : this.$cadastrarVerbaPagamento(objEnvio);
        });
      };
      if (this.$validarFormulario()) {
        return dataInicioFormatada > dataFimFormatada
          ? this.$notifyWarning("A Data inicial da verba não pode ser maior que a final!")
          : save().then(({ data }) => {
              this.$notifySuccess(data.mensagem);
              this.$emit("onClose");
              this.$emit("onSave");
            });
      }
    },
  },
};
</script>
