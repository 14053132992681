<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="3">
            <v-text-field
              label="Pesquisar Motivo"
              v-model="form.filters.descricao"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <avp-select-ativo
            :cols="2"
            v-model="form.filters.ativo"
          ></avp-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarMotivo()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterMotivo()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listMotivos"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.motivo`]="{ item }">
          {{ item.descricao }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            :text="'Editar Motivo'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterMotivo(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="'Visualizar Motivo'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarMotivo(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Motivo`"
            :icon="`${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`"
            :small="true"
            @click="inativarAtivarMotivo(item)"
          ></avp-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarMotivoDialog
      :visible="dialogManterMotivo.visible"
      :content="dialogManterMotivo.content"
      :edit="dialogManterMotivo.edit"
      @onClose="dialogManterMotivo.close()"
      @onSave="buscarMotivo()"
    ></CadastrarEditarMotivoDialog>
    <VisualizarMotivoDialog
      :visible="dialogVisualizarMotivo.visible"
      :content="dialogVisualizarMotivo.content"
      @onClose="dialogVisualizarMotivo.close()"
    ></VisualizarMotivoDialog>
  </div>
</template>

<script>
import { Dialog, Motivo } from "~/commons/classes";
import CadastrarEditarMotivoDialog from "./dialogs/CadastrarEditarMotivoDialog.vue";
import VisualizarMotivoDialog from "./dialogs/VisualizarMotivoDialog.vue";

export default {
  name: "ManterMotivoView",
  components: {
    CadastrarEditarMotivoDialog,
    VisualizarMotivoDialog,
  },
  data: () => ({
    dialogManterMotivo: new Dialog(),
    dialogVisualizarMotivo: new Dialog(),
    form: {
      filters: {
        descricao: null,
        ativo: null,
      },
      lists: {
        listMotivos: [],
      },
    },
    headers: [
      { text: "Motivo", align: "center", value: "motivo" },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ],
  }),
  methods: {
    openDialogManterMotivo(motivo) {
      motivo
        ? this.$obterDadosMotivo(motivo).then(({ data }) => {
            this.dialogManterMotivo.open(new Motivo(data.motivo), true);
          })
        : this.dialogManterMotivo.open(Motivo.Build());
    },
    openDialogVisualizarMotivo(motivo) {
      this.$obterDadosMotivo(motivo).then(
        ({ data }) => (this.dialogVisualizarMotivo.open(data.motivo))
      );
    },
    buscarMotivo() {
      this.$buscarMotivo(this.$montarParamsFilters(this.form.filters)).then(
        ({ data }) => (this.form.lists.listMotivos = data.motivos)
      );
    },
    inativarAtivarMotivo(motivo) {
      this.$swal({
        text: `Deseja confirmar a ${
          motivo.ativo ? "inativação" : "ativação"
        } do Motivo?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarAtivarMotivo({ ...motivo, ativo: !motivo.ativo,}, motivo.id).then(
          ({ data }) => {
            this.$notifySuccess(data.mensagem);
            this.buscarMotivo();
          });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listMotivos = [];
    },
  },
  mounted() {
    this.buscarMotivo();
  },
};
</script>