var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-3"
  }, [_c('avp-select-lotacao', {
    attrs: {
      "cols": 3,
      "required": !_vm.form.filters.cpf
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '###########',
      expression: "'###########'"
    }],
    attrs: {
      "label": "CPF",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.filters.cpf,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "cpf", $$v);
      },
      expression: "form.filters.cpf"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '###########',
      expression: "'###########'"
    }],
    attrs: {
      "label": "Matrícula",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.filters.matricula,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "matricula", $$v);
      },
      expression: "form.filters.matricula"
    }
  })], 1), _c('avp-select-ano-semestre', {
    attrs: {
      "all": "",
      "cols": 4,
      "sufix": 'da Comprovação'
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  }), _c('avp-select-situacao', {
    attrs: {
      "cols": 3
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-tooltip', {
    attrs: {
      "text": 'Buscar',
      "icon": 'mdi-magnify'
    },
    on: {
      "click": function ($event) {
        return _vm.buscarComprovacoes();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Limpar',
      "icon": 'mdi-eraser'
    },
    on: {
      "click": function ($event) {
        return _vm.$resetForm();
      }
    }
  })], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "mt-5",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.form.lists.listValidacoes,
      "items-per-page": 10,
      "footer-props": _vm.$footerProps,
      "show-expand": ""
    },
    scopedSlots: _vm._u([{
      key: `item.nomeProfissional`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.nomeProfissional) + " ")];
      }
    }, {
      key: `item.cpfProfissional`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.cpfProfissional) + " ")];
      }
    }, {
      key: `item.matricula`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.matricula) + " ")];
      }
    }, {
      key: `item.ano`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.ano) + " ")];
      }
    }, {
      key: `item.semestre`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.semestre) + " ")];
      }
    }, {
      key: `item.nomeCapacitacao`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.nomeCapacitacao) + " ")];
      }
    }, {
      key: `item.situacao`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.situacao) + " ")];
      }
    }, {
      key: `item.acoes`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [!item.flgVisualizar && !_vm.foraDoPeriodoDeValidacao && item.cicloAtivo ? _c('avp-tooltip', {
          attrs: {
            "text": item.flgPossuiValidacao ? 'Editar Validação' : 'Validar/Não Validar',
            "icon": item.flgPossuiValidacao ? 'mdi-pencil' : 'mdi-account-edit',
            "small": true
          },
          on: {
            "click": function ($event) {
              return _vm.openDialogValidarComprovacao(item);
            }
          }
        }) : _vm._e(), _c('avp-tooltip', {
          attrs: {
            "text": 'Visualizar Comprovação',
            "icon": 'mdi-eye-outline',
            "small": true
          },
          on: {
            "click": function ($event) {
              return _vm.openDialogVisualizarComprovacao(item);
            }
          }
        })];
      }
    }, {
      key: "expanded-item",
      fn: function (_ref9) {
        var headers = _ref9.headers,
            item = _ref9.item;
        return [_c('td', {
          staticClass: "motivo",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('div', {
          staticClass: "d-flex justify-center py-4"
        }, [_vm._v(" " + _vm._s(item.motivo ? item.motivo : 'Não há motivos a serem exibidos') + " ")])])];
      }
    }], null, true)
  })], 1), _c('ValidarComprovacaoAcaoDialog', {
    attrs: {
      "visible": _vm.dialogValidarComprovacao.visible,
      "content": _vm.dialogValidarComprovacao.content,
      "edit": _vm.dialogValidarComprovacao.edit
    },
    on: {
      "onClose": function ($event) {
        return _vm.dialogValidarComprovacao.close();
      },
      "onSelectValue": function ($event) {
        return _vm.buscarComprovacoes();
      }
    }
  }), _c('VisualizarComprovacaoAcaoDialog', {
    attrs: {
      "visible": _vm.dialogVisualizarComprovacao.visible,
      "content": _vm.dialogVisualizarComprovacao.content
    },
    on: {
      "onClose": function ($event) {
        return _vm.dialogVisualizarComprovacao.close();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }