<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="3">
            <v-text-field
              label="Pesquisar Tipo de Ação de Capacitação"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <avp-select-ano-semestre
            :cols="4"
            v-model="form.filters"
            all
          ></avp-select-ano-semestre>
          <avp-select-ativo :cols="2" v-model="form.filters.ativo"></avp-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarTiposAcaoCapacitacao()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterTipoAcaoCapacitacao()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listTiposAcaoCapacitacao"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template v-slot:[`item.semestre`]="{ item }">
          {{ item.semestre }}
        </template>
        <template v-slot:[`item.tipo`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            :text="'Visualizar Tipo de Ação'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarTipoAcaoCapacitacao(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="'Editar Tipo de Ação'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterTipoAcaoCapacitacao(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Tipo de Ação`"
            :icon="`${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`"
            :small="true"
            @click="inativarAtivarTipoAcaoCapacitacao(item)"
          ></avp-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarTipoAcaoCapacitacaoDialog
      :visible="dialogManterTipoAcaoCapacitacao.visible"
      :content="dialogManterTipoAcaoCapacitacao.content"
      :edit="dialogManterTipoAcaoCapacitacao.edit"
      @onClose="dialogManterTipoAcaoCapacitacao.close()"
      @onSave="buscarTiposAcaoCapacitacao()"
    ></CadastrarEditarTipoAcaoCapacitacaoDialog>
    <VisualizarTipoAcaoCapacitacaoDialog
      :visible="dialogVisualizarTipoAcaoCapacitacao.visible"
      :content="dialogVisualizarTipoAcaoCapacitacao.content"
      @onClose="dialogVisualizarTipoAcaoCapacitacao.close()"
    ></VisualizarTipoAcaoCapacitacaoDialog>
  </div>
</template>

<script>
import { Dialog, TipoAcaoCapacitacao } from "~/commons/classes";
import CadastrarEditarTipoAcaoCapacitacaoDialog from "./dialogs/CadastrarEditarTipoAcaoCapacitacaoDialog.vue";
import VisualizarTipoAcaoCapacitacaoDialog from "./dialogs/VisualizarTipoAcaoCapacitacaoDialog.vue";

export default {
  name: "ManterTipoAcaoCapacitacaoView",
  components: {
    CadastrarEditarTipoAcaoCapacitacaoDialog,
    VisualizarTipoAcaoCapacitacaoDialog,
  },
  data: () => ({
    dialogManterTipoAcaoCapacitacao: Dialog.Build(),
    dialogVisualizarTipoAcaoCapacitacao: Dialog.Build(),
    form: {
      filters: {
        ano: null,
        semestre: null,
        nome: null,
        ativo: null,
      },
      lists: {
        listTiposAcaoCapacitacao: [],
      }
    },
    headers: [
      { text: "Ano", align: "center", value: "ano" },
      { text: "Semestre", align: "center", value: "semestre" },
      { text: "Tipo de Ação de Capacitação", align: "center", value: "tipo" },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ],
  }),
  methods: {
    openDialogManterTipoAcaoCapacitacao(tpAcao) {
      tpAcao
        ? this.$obterDadosTipoAcaoCapacitacao(tpAcao).then(
            ({ data }) => this.dialogManterTipoAcaoCapacitacao.open(data.tipoAcaoCapacitacao, true)
          )
        : this.dialogManterTipoAcaoCapacitacao.open(TipoAcaoCapacitacao.Build());
    },
    openDialogVisualizarTipoAcaoCapacitacao(tpAcao) {
      this.$obterDadosTipoAcaoCapacitacao(tpAcao).then(
        ({ data }) => (this.dialogVisualizarTipoAcaoCapacitacao.open(data.tipoAcaoCapacitacao))
      );
    },
    buscarTiposAcaoCapacitacao() {
      this.$buscarTiposAcaoCapacitacao(this.$montarParamsFilters(this.form.filters)).then(
        ({ data }) => (this.form.lists.listTiposAcaoCapacitacao = data.tipos)
      );
    },
    inativarAtivarTipoAcaoCapacitacao(tpAcao) {
      this.$swal({
        text: `Deseja ${
          tpAcao.ativo ? "inativar" : "ativar"
        } o Tipo de Ação de Capacitação?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarAtivarTipoAcaoCapacitacao({ id: tpAcao.id, ativo: !tpAcao.ativo }, tpAcao.id).then(
          ({ data }) => {
            this.$notifySuccess(data.mensagem);
            this.buscarTiposAcaoCapacitacao();
          });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listTiposAcaoCapacitacao = [];
      this.form.filters = {
        ano: null,
        semestre: null,
        nome: null,
        ativo: null,
      };
    },
  },
};
</script>