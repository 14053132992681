var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-eye")]), _vm._v(" Visualizar Perfil ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.content.nome,
      "label": "Perfil",
      "readonly": "",
      "outlined": "",
      "dense": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.content.adm ? 'Sim' : 'Não',
      "label": "Administrador",
      "readonly": "",
      "outlined": "",
      "dense": ""
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "small"
  }, [_vm._v("Permissões por Grupo")]), _vm._l(this.content.grupos, function (grupo) {
    return _c('div', {
      key: grupo.id
    }, [_c('v-card', {
      staticClass: "border"
    }, [_c('v-card-title', [_c('v-icon', {
      staticClass: "grupo",
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-account-group")]), _vm._v(_vm._s(grupo.nome) + " ")], 1), _c('v-chip-group', {
      attrs: {
        "active-class": "primary--text",
        "column": ""
      }
    }, _vm._l(grupo.permissoes, function (permissao) {
      return _c('v-chip', {
        key: permissao.id
      }, [_c('v-icon', {
        staticClass: "grupo",
        attrs: {
          "small": ""
        }
      }, [_vm._v("mdi-ticket-percent")]), _vm._v(_vm._s(permissao.nome) + " ")], 1);
    }), 1)], 1)], 1);
  })], 2)], 1)], 1), _c('div', {
    staticClass: "btn-group text-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")]), _vm._v(" Fechar ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }