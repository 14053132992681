<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Editar" : "Cadastrar" }} Parâmetro da Comprovação
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="12">
                <v-text-field
                  label="Nome"
                  outlined
                  required
                  dense
                  v-model="content.nome"
                  :rules="[
                    (v) => $required(v),
                    (v) => $max(v, 2000),
                    (v) => $min(v, 5),
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-if="edit"
                  label="Ano da comprovação"
                  outlined
                  readonly
                  dense
                  :value="content.ano"
                ></v-text-field>
                <v-select
                  v-else
                  v-model="content.ano"
                  label="Ano da comprovação"
                  outlined
                  dense
                  :items="content.listAnos"
                  :item-text="'ano'"
                  :item-value="'ano'"
                  :rules="[(v) => $required(v)]"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-if="edit"
                  label="Semestre da Comprovação"
                  outlined
                  readonly
                  dense
                  :value="content.semestre"
                ></v-text-field>
                <v-select
                  v-else
                  v-model="content.semestre"
                  label="Semestre da Comprovação"
                  outlined
                  dense
                  :items="content.listSemestres"
                  :rules="[(v) => $required(v)]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center mt-7">
              <!-- Data e Hora Início da Comprovação -->
              <avp-date-picker
                v-model="content.dataInicioDaComprovacao"
                required
                label="Data Início da Comprovação"
                cols="4"
                :dataEdicao="content.dataInicioDaComprovacao"
              ></avp-date-picker>
              <avp-time-picker
                v-model="content.horaInicioDaComprovacao"
                required
                label="Hora"
                cols="4"
                :horaEdicao="content.horaInicioDaComprovacao"
              ></avp-time-picker>
            </v-row>
            <v-row class="d-flex justify-center mt-7">              
              <!-- Data e Hora Fim da Comprovação -->
              <avp-date-picker
                v-model="content.dataFimDaComprovacao"
                label="Data Fim da Comprovação"
                cols="4"
                required
                :dataEdicao="content.dataFimDaComprovacao"
              ></avp-date-picker>
              <avp-time-picker
                v-model="content.horaFimDaComprovacao"
                required
                label="Hora"
                cols="4"
                :horaEdicao="content.horaFimDaComprovacao"
              ></avp-time-picker>
            </v-row>
            <v-row class="d-flex justify-center mt-7">
              <!-- Data e Hora Inicial da Validação -->
              <avp-date-picker
                v-model="content.dataInicioDaValidacao"
                label="Data Inicio da Validação"
                cols="4"
                required
                :dataEdicao="content.dataInicioDaValidacao"
              ></avp-date-picker>
              <avp-time-picker
                v-model="content.horaInicioDaValidacao"
                required
                label="Hora"
                cols="4"
                :horaEdicao="content.horaInicioDaValidacao"
              ></avp-time-picker>
            </v-row>
            <v-row class="d-flex justify-center mt-7">
              <!-- Data e Hora Final da Validação -->
              <avp-date-picker
                v-model="content.dataFimDaValidacao"
                required
                label="Data Fim da Validação"
                cols="4"
                :dataEdicao="content.dataFimDaValidacao"
              ></avp-date-picker>
              <avp-time-picker
                v-model="content.horaFimDaValidacao"
                required
                label="Hora"
                cols="4"
                :horaEdicao="content.horaFimDaValidacao"
              ></avp-time-picker>
            </v-row>
          </v-form>
        </div>
        <avp-buttons
          @click="salvar()"
          @cancel="$emit('onClose')"
          :actionText="'Salvar'"
        ></avp-buttons>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, ParametroDaComprovacao } from "~/commons/classes";

export default {
  props: new DialogProps(ParametroDaComprovacao),
  watch: {
    "content.ano": {
      handler(value) {
        value &&
          !this.edit &&
          (this.content.listSemestres = this.content.listAnos.find((item) => {
            return item.ano == value;
          }).semestres);
      },
    },
  },
  methods: {
    salvar() {
      const save = () => {
        return this.$swal({
          text: `Confirma ${
            this.edit ? "a edição" : "o cadastro"
          } do parâmetro da comprovação de capacitação?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          const objEnvio = {
            ...this.content,
            dataInicioDaComprovacao: this.$formatarDateParaEnviar(
              this.content.dataInicioDaComprovacao,
              this.content.horaInicioDaComprovacao
            ),
            dataFimDaComprovacao: this.$formatarDateParaEnviar(
              this.content.dataFimDaComprovacao,
              this.content.horaFimDaComprovacao
            ),
            dataInicioDaValidacao: this.$formatarDateParaEnviar(
              this.content.dataInicioDaValidacao,
              this.content.horaInicioDaValidacao
            ),
            dataFimDaValidacao: this.$formatarDateParaEnviar(
              this.content.dataFimDaValidacao,
              this.content.horaFimDaValidacao
            ),
          };
          return this.edit
            ? this.$editarParametrizacaoComprovacao(objEnvio, this.content.id)
            : this.$cadastrarParametrizacaoComprovacao(objEnvio);
        });
      };
      if (this.$validarFormulario()) {
        save().then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.$emit("onClose");
          this.$emit("onSave");
        });
      }
    },
  },
};
</script>
