var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-eye")]), _vm._v(" Visualizar Dados do Ciclo ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Ano do Ciclo",
      "readonly": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.content.ano,
      callback: function ($$v) {
        _vm.$set(_vm.content, "ano", $$v);
      },
      expression: "content.ano"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Semestre do Ciclo",
      "readonly": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.content.semestre,
      callback: function ($$v) {
        _vm.$set(_vm.content, "semestre", $$v);
      },
      expression: "content.semestre"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Data Inicio",
      "outlined": "",
      "dense": "",
      "readonly": ""
    },
    model: {
      value: _vm.content.dataInicioSemestre,
      callback: function ($$v) {
        _vm.$set(_vm.content, "dataInicioSemestre", $$v);
      },
      expression: "content.dataInicioSemestre"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Data Fim",
      "outlined": "",
      "dense": "",
      "readonly": ""
    },
    model: {
      value: _vm.content.dataFimSemestre,
      callback: function ($$v) {
        _vm.$set(_vm.content, "dataFimSemestre", $$v);
      },
      expression: "content.dataFimSemestre"
    }
  })], 1)], 1), _c('div', {
    staticClass: "btn-group text-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")]), _vm._v(" Fechar ")], 1)], 1), _vm.exibirTemplate ? [_c('span', {
    staticClass: "small text-center"
  }, [_vm._v("Funcionalidades clonadas")]), _c('v-card', {
    staticClass: "border mt-5"
  }, [_vm.content.clonarTrilha.ano ? _c('v-row', {
    staticClass: "mt-0 d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_vm._v("Clonar trilha e eixo a partir do ciclo:")])]), _c('avp-select-ano-semestre', {
    attrs: {
      "cols": 6,
      "disabled": true,
      "active": false
    },
    model: {
      value: _vm.content.clonarTrilha,
      callback: function ($$v) {
        _vm.$set(_vm.content, "clonarTrilha", $$v);
      },
      expression: "content.clonarTrilha"
    }
  })], 1) : _vm._e(), _vm.content.clonarFaixaPagamento.ano ? _c('v-row', {
    staticClass: "mt-0 d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_vm._v("Clonar faixa de pagamento a partir do ciclo:")])]), _c('avp-select-ano-semestre', {
    attrs: {
      "cols": 6,
      "disabled": true,
      "active": false
    },
    model: {
      value: _vm.content.clonarFaixaPagamento,
      callback: function ($$v) {
        _vm.$set(_vm.content, "clonarFaixaPagamento", $$v);
      },
      expression: "content.clonarFaixaPagamento"
    }
  })], 1) : _vm._e(), _vm.content.clonarValidador.ano ? _c('v-row', {
    staticClass: "mt-0 d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_vm._v("Clonar validador a partir do ciclo:")])]), _c('avp-select-ano-semestre', {
    attrs: {
      "cols": 6,
      "disabled": true
    },
    model: {
      value: _vm.content.clonarValidador,
      callback: function ($$v) {
        _vm.$set(_vm.content, "clonarValidador", $$v);
      },
      expression: "content.clonarValidador"
    }
  })], 1) : _vm._e(), _vm.content.clonarTipoDeAcao.ano ? _c('v-row', {
    staticClass: "mt-0 d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_vm._v("Clonar tipo de ação partir do ciclo:")])]), _c('avp-select-ano-semestre', {
    attrs: {
      "cols": 6,
      "disabled": true,
      "active": false
    },
    model: {
      value: _vm.content.clonarTipoDeAcao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "clonarTipoDeAcao", $$v);
      },
      expression: "content.clonarTipoDeAcao"
    }
  })], 1) : _vm._e()], 1)] : _vm._e()], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }