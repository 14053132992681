<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <avp-select-lotacao
            :cols="2"
            v-model="form.filters"
            relatorio
          ></avp-select-lotacao>
          <v-col cols="2">
            <v-text-field
              label="Nome"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              label="Perfil"
              :items="form.lists.listPerfis"
              :item-text="'nome'"
              :item-value="'id'"
              v-model="form.filters.perfilId"
              outlined
              dense
              @change="(perfilId) => desabilitarCiclo(perfilId)"
            >
          </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-select
              label="Situação"
              :items="opcoesStatus"
              v-model="form.filters.situacao"
              item-text="descricao"
              item-value="value"
              id="situacao"
              dense
              outlined
              hide-details="auto"
            ></v-select>
          </v-col>
          <avp-switch-button
            v-model="form.filters.analitico"
            :cols="2"
            label="Tipo de Relatório"
            labelChecked="Analítico"
            labelUnchecked="Sintético"
          ></avp-switch-button>
        </v-row>
        <v-row class="d-flex justify-center">
          <avp-select-ano-semestre
            :key="componentKey"
            :cols="4"
            :sufix="'da Comprovação'"
            v-model="form.filters"
            all
            :disabled="disabledAnoSemestre"
            :active="form.filters.active"
          ></avp-select-ano-semestre>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarRelatorios()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <!-- Se o resultado da consulta de relatório for analítico -->
      <v-data-table
        v-if="form.mostrarAnalitico"
        class="mt-5 printable"
        :headers="getFilteredHeader(headersAnalitico)"
        :items="form.lists.listRelatorios"
        :items-per-page="form.itemsPerPage"
        :footer-props="$footerProps"
        :show-expand="!onReport"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td class="perfis" :colspan="headers.length">
            <v-chip-group active-class="primary--text" column>
              <v-chip
                class="chip-permissao"
                v-for="(perfil, i) in item.perfis"
                :key="i"
              >
                <v-icon class="perfil" small>mdi-account-multiple</v-icon>
                {{ perfil.nome }}
              </v-chip>
            </v-chip-group>
          </td>
        </template>

        <template v-slot:[`item.situacao`]="{ item }">
          <avp-label-ativo :ativo="item.situacao"></avp-label-ativo>
        </template>
        <template v-slot:[`item.validador`]="{ item }">
          <avp-label-ativo :ativo="item.validador"></avp-label-ativo>
        </template>
        <template v-slot:[`item.cpf`]="{ item }">
          <div class="hide-report">
            {{ item.cpfComMascara }}
          </div>
          <div class="report">
            {{ $formatCpf(item.cpfSemMascara) }}
          </div>
        </template>
      </v-data-table>
      <!-- Se o resultado da consulta de relatório for sintético -->
      <v-data-table
        v-else
        class="mt-5 printable"
        :headers="getFilteredHeader(headersSintetico)"
        :items="form.lists.listRelatorios"
        :items-per-page="form.itemsPerPage"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nomeProfissional }}
        </template>
        <template v-slot:[`item.cpf`]="{ item }">
          <div class="hide-report">
            {{ item.cpfComMascara }}
          </div>
          <div class="report">
            {{ $formatCpf(item.cpfSemMascara) }}
          </div>
        </template>
      </v-data-table>
      <avp-buttons
        v-if="form.lists.listRelatorios.length"
        :actionText="'Imprimir'"
        :actionIcon="'mdi-printer'"
        :cancelText="'Baixar CSV'"
        :cancelIcon="'mdi-file'"
        @click="imprimirRelatorio()"
        @cancel="baixarCSVRelatorio()"
      ></avp-buttons>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ListarRelatoriosUsuariosView",
  data: () => ({
    onReport: false,
    componentKey: 0,
    disabledAnoSemestre: false,
    form: {
      filters: {
        lotacao: null,
        ano: null,
        semestre: null,
        nome: null,
        cpf: null,
        situacao: null,
        analitico: true,
        perfilId: null,
      },
      lists: {
        listRelatorios: [],
        listPerfis: [],
      },
      itemsPerPage: 10,
      geradorRelatorio: null,
      horaDaGeracao: null,
      totalUsuarios: 0,
      mostrarAnalitico: true,
    },
    opcoesStatus: [
      { value: true, descricao: "Sim" },
      { value: false, descricao: "Não" },
    ],
    headersAnalitico: [
      { text: "CPF", align: "center", value: "cpfImpressao", onlyReport: true},
      { text: "Nome", align: "center", value: "nomeUsuario"},
      { text: "Usuário", align: "center", value: "usuarioCPF", onlyView: true},
      { text: "Lotação", align: "center", value: "lotacao", onlyReport: true},
      { text: "Ano", align: "center", value: "ano", onlyReport: true },
      { text: "Semestre", align: "center", value: "semestre", onlyReport: true },
      { text: "Perfil", align: "center", value: "data-table-expand",  onlyView: true},
      { text: "Ativo", align: "center", value: "situacao", onlyView: true},
      { text: "Validador", align: "center", value: "validador", onlyReport: true},
    ],
    headersSintetico: [
      { text: "Ano", align: "center", value: "ano" },
      { text: "Semestre", align: "center", value: "semestre" },
      { text: "Quantidade de Usuários SADRH", align: "center", value: "quantidadeUsuarioSADRH" },
      { text: "Quantidade de Usuários que Não São SADRH", align: "center", value: "quantidadeUsuarioNaoSADRH" },
      { text: "Quantidade de Validadores", align: "center", value: "quantidadeUsuarioValidador" },
    ],
  }),
  computed: {
    isAnalitico() {
      return this.form.filters.analitico;
    },
  },
  methods: {
    getFilteredHeader(headers) {
      return headers.filter((header) => {
        if (header.onlyView) return !this.onReport;
        if (header.onlyReport) return this.onReport;
        return true;
      });
    },
    buscarRelatorios() {
      if (this.$validarFormulario()) {
        const preparedRequest = JSON.parse(JSON.stringify(this.form.filters));
        if (preparedRequest.dataFimTentativaLogin)
          preparedRequest.dataFimTentativaLogin = this.$formatarDateParaEnviar(
            preparedRequest.dataFimTentativaLogin,
            "23:59:59"
          );
        const buscarRelatorio = (params) => {
          return this.isAnalitico
            ? this.$obterRelatorioUsuarios(params)
            : this.$obterRelatorioUsuariosSintetico(params);
        };
        buscarRelatorio(this.$montarParamsFilters(preparedRequest)).then(
          ({ data }) => {
            this.form.mostrarAnalitico = this.isAnalitico;
            this.form.geradorRelatorio = data.relatorio.geradorRelatorio;
            this.form.horaDaGeracao = data.relatorio.horaDaGeracao;
            this.form.totalUsuarios =
              data.relatorio.totalUsuarios;
            this.form.lists.listRelatorios = data.relatorio.usuarios;
          }
        );
      }
    },
    imprimirRelatorio() {
      this.onReport = true;
      this.form.itemsPerPage = -1;
      const importCSStoPrint = () =>
        import("!raw-loader!sass-loader!/src/assets/styles/_print.scss");
      importCSStoPrint().then((scss) => {
        let newWindow = window.open();
        newWindow.document.write(`
        <div class="report">
          <style>${scss.default}</style>
          <div class="report-header-title">
            <h1>Relatório de Usuários</h1>
          </div>
          ${document.querySelector(".printable").outerHTML}
          <div class="report-header-title">
            <h3>Quantidade Total de Usuários: ${
              this.form.totalUsuarios
            }</h3>
            <h3>Nome do usuário de Geração: ${this.form.geradorRelatorio}</h3>
            <h3>Data/Hora da Geração: ${this.form.horaDaGeracao}</h3>
          </div>
        </div>`);
        newWindow.print();
        newWindow.close();
        this.form.itemsPerPage = 10;
        this.onReport = false;
      });
    },
    baixarCSVRelatorio() {
      const baixarRelatorio = (params) => {
        return this.isAnalitico
          ? this.$obterRelatorioCSVUsuarios(params)
          : this.$obterRelatorioCSVUsuariosSintetico(params);
      };
      baixarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
        ({ data }) => this.$baixarRelatorioCSV(data, "relatorio-usuarios")
      );
    },
    desabilitarCiclo(perfilId) {
      var perfil = this.form.lists.listPerfis.find((x) => x.id == perfilId);
      if(perfil.adm != undefined && perfil.adm)
      {
        this.form.filters.ano = "Todos";
        this.form.filters.semestre = "Todos";
        this.disabledAnoSemestre = true;
        return;
      }
      this.disabledAnoSemestre = false;
      this.form.filters.active = true;
      this.forceRender();
    },
    forceRender() {
      this.componentKey += 1;
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listRelatorios = [];
      this.disabledAnoSemestre = false;
      this.form.filters.active = true;
      this.forceRender();
    }
  },
  mounted() {
    this.$buscarPerfis().then(({ data }) => {
      this.form.lists.listPerfis = data.perfis;
    })
  }
};
</script>
<style scoped>
.perfis {
  padding: 1em !important;
  text-align: center;
  font-weight: 500;
}
.perfil {
  padding-right: 0.5em;
}
</style>