<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="3">
            <v-text-field
              label="Pesquisar Instituição"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <avp-select-ativo
            :cols="2"
            v-model="form.filters.ativo"
          ></avp-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarInstituicoes()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterInstituicao()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listInstituicoes"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            :text="'Visualizar Instituição'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarInstituicao(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="'Editar Instituição'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterInstituicao(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Instituição`"
            :icon="`${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`"
            :small="true"
            @click="inativarAtivarInstituicao(item)"
          ></avp-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarInstituicaoDialog
      :visible="dialogManterInstituicao.visible"
      :content="dialogManterInstituicao.content"
      :edit="dialogManterInstituicao.edit"
      @onClose="dialogManterInstituicao.close()"
      @onSave="buscarInstituicoes()"
    ></CadastrarEditarInstituicaoDialog>
    <VisualizarInstituicaoDialog
      :visible="dialogVisualizarInstituicao.visible"
      :content="dialogVisualizarInstituicao.content"
      @onClose="dialogVisualizarInstituicao.close()"
    ></VisualizarInstituicaoDialog>
  </div>
</template>

<script>
import { Dialog, Instituicao } from "~/commons/classes";
import CadastrarEditarInstituicaoDialog from "./dialogs/CadastrarEditarInstituicaoDialog.vue";
import VisualizarInstituicaoDialog from "./dialogs/VisualizarInstituicaoDialog.vue";

export default {
  name: "ManterInstituicaoView",
  components: {
    CadastrarEditarInstituicaoDialog,
    VisualizarInstituicaoDialog,
  },
  data: () => ({
    dialogManterInstituicao: Dialog.Build(),
    dialogVisualizarInstituicao: Dialog.Build(),
    form: {
      filters: {
        nome: null,
        ativo: null,
      },
      lists: {
        listInstituicoes: [],
      }
    },
    headers: [
      { text: "Instituição", align: "center", value: "nome" },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ]
  }),
  methods: {
    openDialogManterInstituicao(inst) {
      inst
        ? this.$buscarDadosInstituicao(inst).then(
            ({ data }) =>
              this.dialogManterInstituicao.open(data.instituicao, true)
          )
        : this.dialogManterInstituicao.open(Instituicao.Build());
    },
    openDialogVisualizarInstituicao(inst) {
      this.$buscarDadosInstituicao(inst).then(
        ({ data }) => this.dialogVisualizarInstituicao.open(data.instituicao)
      );
    },
    buscarInstituicoes() {
      this.$buscarInstituicoes({ params: this.form.filters }).then(
        ({ data }) => (this.form.lists.listInstituicoes = data.instituicoes)
      );
    },
    inativarAtivarInstituicao(inst) {
      this.$swal({
        text: `Deseja confirmar a ${
          inst.ativo ? "inativação" : "ativação"
        } da Instituição?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarAtivarInstituicao(
          { id: inst.id, ativo: !inst.ativo },
          inst.id
        ).then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.buscarInstituicoes();
        });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listInstituicoes = [];
    },
  },
};
</script>