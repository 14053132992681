<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="2">
            <v-text-field
              label="Nome De Usuário"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="CPF"
              v-model="form.filters.login"
              v-mask="'###.###.###-##'"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <avp-select-ano-semestre
            :cols="3"
            :sufix="'do Ciclo'"
            :disabled="disabledAnoSemestre"
            :active="form.filters.active"
            v-model="form.filters"
            required
            all
          ></avp-select-ano-semestre>
          <v-col cols="2">
            <v-autocomplete
              label="Perfil"
              :items="form.lists.listPerfis"
              :item-text="'nome'"
              :item-value="'id'"
              v-model="form.filters.perfil"
              outlined
              dense
              @change="(perfilId) => desabilitarCiclo(perfilId)"
            ></v-autocomplete>
          </v-col>
          <avp-select-ativo
            :cols="2"
            v-model="form.filters.ativo"
          ></avp-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarUsuarios()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Novo'"
              :icon="'mdi-plus'"
              @click="openDialogManterUsuario()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listUsuarios"
        :items-per-page="10"
        :footer-props="$footerProps"
        sort-by="nome"
        show-expand
      >
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.usuario`]="{ item }">
          {{ $formatCpf(item.cpf) }}
        </template>
        <template v-slot:[`item.perfil`]="{ item }">
          {{ item.perfil }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            :text="'Visualizar Usuario'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarUsuario(item)"
          ></avp-tooltip>
          <avp-tooltip
          v-if="form.filters.ano !== 'Todos' || varificarSeTemPerfilAdm(item)"
            :text="'Editar Usuario'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterUsuario(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Usuario`"
            :icon="`${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`"
            :small="true"
            @click="inativarAtivarUsuario(item)"
          ></avp-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="grupos" :colspan="headers.length">
            <v-chip-group active-class="primary--text" column>
              <v-chip
                class="chip-permissao"
                v-for="(grupo, i) in item.perfis"
                :key="i"
              >
                <v-icon class="grupo" small>mdi-account-multiple</v-icon>
                {{ grupo.nome }}
              </v-chip>
            </v-chip-group>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarUsuarioDialog
      :visible="dialogManterUsuario.visible"
      :content="dialogManterUsuario.content"
      :edit="dialogManterUsuario.edit"
      @onClose="dialogManterUsuario.close()"
      @onSave="buscarUsuarios()"
    ></CadastrarEditarUsuarioDialog>
    <VisualizarUsuarioDialog
      :visible="dialogVisualizarUsuario.visible"
      :content="dialogVisualizarUsuario.content"
      @onClose="dialogVisualizarUsuario.close()"
    ></VisualizarUsuarioDialog>
  </div>
</template>

<script>
import { Dialog, Usuario } from "~/commons/classes";
import CadastrarEditarUsuarioDialog from "./dialogs/CadastrarEditarUsuarioDialog.vue";
import VisualizarUsuarioDialog from "./dialogs/VisualizarUsuarioDialog.vue";

export default {
  name: "ManterUsuariosView",
  components: {
    CadastrarEditarUsuarioDialog,
    VisualizarUsuarioDialog,
  },
  data: () => ({
    dialogManterUsuario: Dialog.Build(),
    dialogVisualizarUsuario: Dialog.Build(),
    disabledAnoSemestre: false,
    form: {
      filters: {
        nome: null,
        login: null,
        nomeDoPerfil: null,
        ano: null,
        semestre: null,
        perfil: null,
        ativo: null,
      },
      lists: {
        listGrupos: [],
        listPerfis: [],
        listUsuarios: []
      },
    },
    headers: [
      { text: "Nome De Usuário", align: "center", value: "nome" },
      { text: "CPF", align: "center", value: "usuario" },
      { text: "Perfil", align: "center", value: "data-table-expand" },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ],
  }),
  methods: {
    openDialogManterUsuario(usuario) {
      usuario
        ? this.$visualizarUsuario(usuario.id, this.$montarParamsFilters(usuario)).then(
          ({ data }) => (this.dialogManterUsuario.open(new Usuario({
            ...usuario,
            ...data.usuario,
          }).format(), true)))
        : this.dialogManterUsuario.open(Usuario.Build());
    },
    openDialogVisualizarUsuario(usuario) {
      this.$visualizarUsuario(usuario.id, this.$montarParamsFilters(usuario)).then(
        ({ data }) => (this.dialogVisualizarUsuario.open(new Usuario(data.usuario)))
      );
    },
    varificarSeTemPerfilAdm(item){
      if(item != undefined && item.perfis) {
        return  item?.perfis?.some(p => p.adm);
      }
      return false;
    },
    buscarUsuarios() {
      if (this.$validarFormulario()) {
        this.form.filters.login = this.form.filters.login != null ? 
          this.form.filters.login.replaceAll(".","").replaceAll("-","") : ""; 
        const filters = this.$montarParamsFilters(this.form.filters);
        this.$buscarUsuarios(filters).then(({ data }) => {
          this.form.lists.listUsuarios = data.usuarios.map((u) => ({
            ...u,
            ano: filters.params.ano,
            semestre: filters.params.semestre,
          }));
        });
      }
    },
    inativarAtivarUsuario(usuario) {
      this.$swal({
        text: `Deseja confirmar a ${
          usuario.ativo ? "inativação" : "ativação"
        } do Usuário ${usuario.nome}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarAtivarUsuario(
          { id: usuario.id, ativo: !usuario.ativo },
          usuario.id
        ).then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.buscarUsuarios();
        });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listUsuarios = [];
      this.disabledAnoSemestre = false;
      this.form.filters = {
        nome: null,
        login: null,
        nomeDoPerfil: null,
        ano: null,
        semestre: null,
        perfil: null,
        ativo: null,
      };
    },
    desabilitarCiclo(perfilId) {
      var perfil = this.form.lists.listPerfis.find((x) => x.id == perfilId);
      if(perfil.adm != undefined && perfil.adm)
      {
        this.form.filters.ano = "Todos";
        this.form.filters.semestre = "Todos";
        this.disabledAnoSemestre = true;
        return;
      }
      this.disabledAnoSemestre = false;
      this.form.filters.active = true;
      this.forceRender();
    },
  },
  mounted() {
    this.$listarGrupos().then(({ data }) => {
      this.form.lists.listGrupos = data.grupos;
    });
    this.$buscarPerfis().then(({ data }) =>  {
      this.form.lists.listPerfis = data.perfis;
    });
  },
};
</script>
<style scoped>
.grupos {
  padding: 1em !important;
  text-align: center;
  font-weight: 500;
}
.grupo {
  padding-right: 0.5em;
}
</style>