<template>
    <div class="page">
      <v-container fluid>
        <avp-breadcrumbs></avp-breadcrumbs>
        <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="3">
            <v-text-field
              label="Nome"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Usuário"
              v-mask="'###########'"
              v-model="form.filters.login"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="d-flex justify-center"></div>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarRelatorios()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
          </v-col>
        </div>
        </v-form>

        <v-data-table
          class="mt-5 printable"
          :headers="getFilteredHeader(headers)"
          :items="form.lists.listRelatorios"
          :items-per-page="form.itemsPerPage"
          :footer-props="$footerProps"
        >
          <template v-slot:[`item.nome`]="{ item }">
            {{ item.nome }}
          </template>
          <template v-slot:[`item.sobrenome`]="{ item }">
            {{ item.sobrenome }}
          </template>
          <template v-slot:[`item.usuario`]="{ item }">
            <div class="hide-report">{{ $esconderCpf(item.usuario) }}</div>
            <div class="report">{{ $formatCpf(item.usuario) }}</div>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            {{ item.email }}
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
          </template>
          <template class="report" v-slot:[`item.nomeUsuarioAcao`]="{ item }">
            {{ item.nomeUsuarioAcao }}
          </template>
          <template v-slot:[`item.dataHoraAcao`]="{ item }">
            {{ item.dataHoraAcao }}
          </template>
          <template v-slot:[`item.acao`]="{ item }">
            {{ item.acao }}
          </template>
        </v-data-table>

        <avp-buttons
          v-if="form.lists.listRelatorios.length"
          :actionText="'Imprimir'"
          :actionIcon="'mdi-printer'"
          :cancelText="'Baixar CSV'"
          :cancelIcon="'mdi-file'"
          @click="baixarRelatorioPdf()"
          @cancel="baixarCSVLogUsuarios()"
        ></avp-buttons>
      </v-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "ListarRelatoriosLogUsuario",
    data: () => ({
      onReport: false,
      form: {
        filters: {
          nome: null,
          login: null,
          tipoRelatorio: 0,
        },
        lists: {
          listRelatorios: [],
        },
        itemsPerPage: 10,
        geradorRelatorio: null,
        horaDaGeracao: null,
        totalUsuarios: 0,
      },
      headers: [
        { text: "Nome", align: "center", value: "nome" },
        { text: "Sobrenome", align: "center", value: "sobrenome" },
        { text: "Usuário", align: "center", value: "usuario", sortable: false },
        { text: "E-mail", align: "center", value: "email" },
        { text: "Ativo", align: "center", value: "ativo" },
        { text: "Usuário da Ação", align: "center", value: "nomeUsuarioAcao", onlyReport: true },
        { text: "Data/hora da Ação", align: "center", value: "dataHoraAcao" },
        { text: "Ação", align: "center", value: "acao" },
      ],
    }),
    methods: {
      getFilteredHeader(headers){
        return headers.filter(header => {
          if(header.onlyView) return !this.onReport;
          if(header.onlyReport) return this.onReport;
          return true
        })
  
      },
      buscarRelatorios() {
        if(!this.form.filters.nome && !this.form.filters.login) {
          this.$notifyWarning("Por favor, informe ao menos um usuário para podermos efetuar a consulta de suas atividades no sistema.")
          return;
        }

        if (this.$validarFormulario()) {
          const buscarRelatorio = (params) => {
            return this.$obterRelatorioLogUsuario(params);
          };
          buscarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
            ({ data }) => {
              this.form.geradorRelatorio = data.relatorio.geradorRelatorio;
              this.form.horaDaGeracao = data.relatorio.horaDaGeracao;
              this.form.lists.listRelatorios = data.relatorio.lista;
              this.form.totalUsuarios = data.relatorio.totalUsuarios;
            }
          );
        }
      },
      imprimirRelatorioLogUsuario() {
        this.onReport = true;
        this.form.itemsPerPage = -1;
        const importCSStoPrint = () =>
          import("!raw-loader!sass-loader!/src/assets/styles/_print.scss");
        importCSStoPrint().then((scss) => {
          let newWindow = window.open();
          newWindow.document.write(`
          <div class="report">
            <style>${scss.default}</style>
            <div class="report-header-title">
              <h1>Relatório de Log de Usuários</h1>
            </div>
            ${document.querySelector(".printable").outerHTML}
            <div class="report-header-title">
              <h3>Quantidade Total de Registros: ${
                this.form.totalUsuarios
              }</h3>
              <h3>Nome do usuário de Geração: ${this.form.geradorRelatorio}</h3>
              <h3>Data/Hora da Geração: ${this.form.horaDaGeracao}</h3>
            </div>
          </div>`);
          newWindow.print();
          newWindow.close();
          this.form.itemsPerPage = 10;
          this.onReport = false;
        });
      },
      baixarCSVLogUsuarios() {
        const baixarRelatorio = (params) => {
          return this.$obterRelatorioLogUsuarioCsv(params);
        };
        baixarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
          ({ data }) => this.$baixarRelatorioCSV(data, `relatorio-log-usuarios`)
        );
      },
      baixarRelatorioPdf() {
        const baixarPdf = (params) => {
          return this.$obterRelatorioPdf(params);
        };
        baixarPdf(this.$montarParamsFilters(this.form.filters))
        .then(({data}) => {
          

          const file = this.$getFileFromBase64(
            data.resposta.arquivoBase64, 
            data.resposta.nome, 
            data.resposta.tipoConteudo, 
            data.resposta.extensao
            );

          this.$baixarArquivo(file, data.resposta.tipoConteudo);

        });
      },
      resetForm() {
        this.$resetForm();
        this.form.lists.listRelatorios = [];
      }
    },
  };
  </script>