<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon>
            Visualizar dados da pergunta/resposta do FAQ
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="8">
              <v-text-field
                v-model="content.pergunta"
                label="Pergunta"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="content.posicao"
                label="Posição"
                type="number"
                outlined
                readonly
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="12">
              <v-textarea
                v-model="content.resposta"
                label="Resposta"
                outlined
                readonly
                dense
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12">
              <v-sheet elevation="10">
                <div class="title-palavras-chave">Palavras Chave</div>
                <div class="pa-4">
                  <v-chip-group active-class="primary--text" column>
                    <v-chip v-for="tag in content.palavrasChave" :key="tag">
                      <v-icon small>mdi-key</v-icon>{{ tag }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </div>
        <div class="btn-group text-center">
          <v-btn color="primary" @click="$emit('onClose')">
            <v-icon>mdi-close</v-icon>
            Fechar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, FAQ } from '~/commons/classes';

export default {
  props: new DialogProps(FAQ),
};
</script>

<style scoped>
.v-icon.notranslate.mdi.mdi-key.theme--light {
  padding: 0.4em;
}

.title-palavras-chave {
  display: flex;
  padding: 1.5em;
  justify-content: center;
  border-bottom: 1px solid #d7d7d7;
}
</style>
