<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Editar" : "Cadastrar" }} Ciclo
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="6">
                <v-text-field
                  v-model="content.ano"
                  v-mask="'####'"
                  label="Ano do Ciclo"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $min(v, 4)]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="content.semestre"
                  v-mask="'####'"
                  label="Semestre do Ciclo"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $max(v, 4)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center mt-7">
              <avp-date-picker
                v-model="content.dataInicioSemestre"
                label="Data Início"
                required
                removeIcon
                :dataEdicao="content.dataInicioSemestre"
              ></avp-date-picker>
              <avp-date-picker
                v-model="content.dataFimSemestre"
                label="Data Fim"
                required
                removeIcon
                :dataEdicao="content.dataFimSemestre"
              ></avp-date-picker>
              <v-checkbox class="mt-0" v-model="content.clonarCiclo">
                <template v-slot:label>
                  <div>{{ edit ? "Editar Clone do" : "Clonar" }} Ciclo?</div>
                </template>
              </v-checkbox>
            </v-row>
            <template v-if="content.clonarCiclo">
              <v-row
                class="mt-0 d-flex justify-center"
                v-if="content.clonarCiclo"
              >
                <v-col cols="6">
                  <div>Clonar trilha e eixo a partir do Ciclo:</div>
                </v-col>
                <avp-select-ano-semestre-ciclo
                  :cols="6"
                  v-model="content.clonarTrilha"
                  noClone
                  :tipo="0"
                ></avp-select-ano-semestre-ciclo>
              </v-row>
              <v-row
                class="mt-0 d-flex justify-center"
                v-if="content.clonarCiclo"
              >
                <v-col cols="6">
                  <div>Clonar faixa de pagamento a partir do Ciclo:</div>
                </v-col>
                <avp-select-ano-semestre-ciclo
                  :cols="6"
                  v-model="content.clonarFaixaPagamento"
                  noClone
                  :tipo="1"
                ></avp-select-ano-semestre-ciclo>
              </v-row>
              <v-row
                class="mt-0 d-flex justify-center"
                v-if="content.clonarCiclo"
              >
                <v-col cols="6">
                  <div>Clonar validador a partir do Ciclo:</div>
                </v-col>
                <avp-select-ano-semestre-ciclo
                  :cols="6"
                  v-model="content.clonarValidador"
                  noClone
                  :tipo="2"
                ></avp-select-ano-semestre-ciclo>
              </v-row>
              <v-row
                class="mt-0 d-flex justify-center"
                v-if="content.clonarCiclo"
              >
                <v-col cols="6">
                  <div>Clonar tipo de ação partir do Ciclo:</div>
                </v-col>
                <avp-select-ano-semestre-ciclo
                  :cols="6"
                  v-model="content.clonarTipoDeAcao" 
                  noClone
                  :tipo="3"
                ></avp-select-ano-semestre-ciclo>
              </v-row>
            </template>
          </v-form>
        </div>
        <avp-buttons
          @click="salvar()"
          @cancel="$emit('onClose')"
          :actionText="'Salvar'"
        ></avp-buttons>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, Ciclo } from "~/commons/classes";

export default {
  props: new DialogProps(Ciclo),
  data: () => ({}),
  computed: {
    obterListaDeClones() {
      var listaDeClones = [
        this.content.clonarTrilha,
        this.content.clonarFaixaPagamento,
        this.content.clonarValidador,
        this.content.clonarTipoDeAcao,
      ];
      // Verifique se o valor é "Não Clonar" e defina como null
      listaDeClones = listaDeClones.map((el) => {

        if (el && el.ano == "Não Clonar") {
          el.ano = null;
          el.semestre = null;
        }
        if (el) {
          el.clonar = !!el.ano || el.ano == null;
        }
        return el;
      });
      return listaDeClones.filter((el) => el && el.clonar);
    },
  },
  methods: {
    salvar() {
      const save = () => {
        return this.$swal({
          text: `Confirma ${this.edit ? "a edição" : "o cadastro"} do Ciclo?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          const objEnvio = {
            ...this.content,
            clones: this.obterListaDeClones,
            dataInicioSemestre: this.$formatarDateParaEnviar(
              this.content.dataInicioSemestre
            ),
            dataFimSemestre: this.$formatarDateParaEnviar(
              this.content.dataFimSemestre
            ),
          };
          return this.edit
            ? this.$editarCiclo(objEnvio, this.content.id)
            : this.$cadastrarCiclo(objEnvio);
        });
      };
      if (this.$validarFormulario()) {
        save().then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.$emit("onClose");
          this.$emit("onSave");
          window.location.reload();
        });
      }
    },
  },
};
</script>
