<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon> Visualizar Perfil
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="9">
              <v-text-field
                :value="content.nome"
                label="Perfil"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                :value="content.adm ? 'Sim' : 'Não'"
                label="Administrador"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="12">
              <span class="small">Permissões por Grupo</span>
              <div v-for="grupo in this.content.grupos" :key="grupo.id">
                <v-card class="border">
                  <v-card-title>
                    <v-icon class="grupo" small>mdi-account-group</v-icon>{{ grupo.nome }}
                  </v-card-title>
                  <v-chip-group active-class="primary--text" column>
                    <v-chip v-for="permissao in grupo.permissoes" :key="permissao.id">
                      <v-icon class="grupo" small>mdi-ticket-percent</v-icon>{{ permissao.nome }}
                    </v-chip>
                  </v-chip-group>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="btn-group text-center">
          <v-btn color="primary" @click="$emit('onClose')">
            <v-icon>mdi-close</v-icon>
            Fechar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, Perfil } from '~/commons/classes';

export default {
  props: new DialogProps(Perfil),
};
</script>
<style scoped>
.grupo {
  padding-right: 0.5em;
}
.border {
  border: 1px solid #9e9e9e;
  box-shadow: none !important;
}
</style>
