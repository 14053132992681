<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="3">
            <v-text-field
              label="Grupo"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <avp-select-ativo
            :cols="3"
            v-model="form.filters.ativo"
          ></avp-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarGrupos()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterGrupo()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listGrupos"
        :items-per-page="10"
        :footer-props="$footerProps"
        show-expand
      >
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nomeDeExibicao }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            :text="'Visualizar Grupo'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarGrupo(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="'Editar Grupo'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterGrupo(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Grupo`"
            :icon="`${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`"
            :small="true"
            @click="inativarAtivarGrupo(item)"
          ></avp-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="permissoes" :colspan="headers.length">
            <v-chip-group active-class="primary--text" column>
              <v-chip
                class="chip-permissao"
                v-for="(permissao, i) in item.permissoes"
                :key="i"
              >
                <v-icon class="permissao" small
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                {{ permissao.nome }}
              </v-chip>
            </v-chip-group>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarGrupoDialog
      :visible="dialogManterGrupo.visible"
      :content="dialogManterGrupo.content"
      :edit="dialogManterGrupo.edit"
      @onClose="dialogManterGrupo.close()"
      @onSave="buscarGrupos()"
    ></CadastrarEditarGrupoDialog>
    <VisualizarGrupoDialog
      :visible="dialogVisualizarGrupo.visible"
      :content="dialogVisualizarGrupo.content"
      @onClose="dialogVisualizarGrupo.close()"
    ></VisualizarGrupoDialog>
  </div>
</template>

<script>
import { Dialog, Grupo } from "~/commons/classes";
import CadastrarEditarGrupoDialog from "./dialogs/CadastrarEditarGrupoDialog.vue";
import VisualizarGrupoDialog from "./dialogs/VisualizarGrupoDialog.vue";

export default {
  name: "ManterGrupoView",
  components: {
    CadastrarEditarGrupoDialog,
    VisualizarGrupoDialog,
  },
  data: () => ({
    dialogManterGrupo: Dialog.Build(),
    dialogVisualizarGrupo: Dialog.Build(),
    form: {
      filters: {
        nome: null,
        ativo: null,
      },
      lists: {
        listGrupos: []
      }
    },
    headers: [
      { text: "Descrição", align: "center", value: "nome" },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Permissões", align: "center", value: "data-table-expand" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ]
  }),
  methods: {
    openDialogManterGrupo(grupo) {
      grupo
        ? this.$visualizarGrupos(grupo).then(({ data }) => {
            this.dialogManterGrupo.open(new Grupo(data.grupo).format(), true);
          })
        : this.dialogManterGrupo.open(Grupo.Build());
    },
    openDialogVisualizarGrupo(grupo) {
      this.$visualizarGrupos(grupo).then(
        ({ data }) => (this.dialogVisualizarGrupo.open(data.grupo))
      );
    },
    buscarGrupos() {
      this.$buscarGrupos({ params: this.form.filters }).then(
        ({ data }) => (this.form.lists.listGrupos = data.grupos)
      );
    },
    inativarAtivarGrupo(grupo) {
      this.$swal({
        text: `Deseja confirmar a ${
          grupo.ativo ? "inativação" : "ativação"
        } do Grupo?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarAtivarGrupo(
          { id: grupo.id, ativo: !grupo.ativo },
          grupo.id
        ).then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.buscarGrupos();
        });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listGrupos = [];
    },
  },
};
</script>
<style scoped>
.permissoes {
  padding: 1em !important;
  text-align: center;
  font-weight: 500;
}
.permissao {
  padding-right: 0.5em;
}
</style>