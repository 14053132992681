<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Editar" : "Cadastrar" }} Instituição
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="12">
                <v-text-field
                  v-model="content.nome"
                  label="Nome da Instituição"
                  counter="250"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $max(v, 250)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="6">
                <v-select
                  v-model="content.esfera"
                  :items="form.lists.esferas"
                  :item-text="'label'"
                  :item-value="'value'"
                  :rules="[(v) => $required(v)]"
                  label="Esfera"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="content.governo"
                  :items="form.lists.governos"
                  :item-text="'label'"
                  :item-value="'value'"
                  :rules="[(v) => $required(v)]"
                  label="Governo"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="4">
                <v-select
                  v-model="content.poder"
                  :items="form.lists.poderes"
                  :item-text="'label'"
                  :item-value="'value'"
                  :rules="[(v) => $required(v)]"
                  label="Poder"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="content.investimento"
                  :items="form.lists.investimentos"
                  :item-text="'label'"
                  :item-value="'value'"
                  :rules="[(v) => $required(v)]"
                  label="Investimento"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="content.modalidade"
                  :items="form.lists.modalidades"
                  :item-text="'label'"
                  :item-value="'value'"
                  :rules="[(v) => $required(v)]"
                  label="Modalidade"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="12">
                <v-text-field
                  v-model="content.url"
                  label="Link"
                  outlined
                  dense
                  :rules="[(v) => $required(v)]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <avp-buttons
          @click="salvar()"
          @cancel="$emit('onClose')"
          :actionText="edit ? 'Editar' : 'Salvar'"
        ></avp-buttons>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, Instituicao } from '~/commons/classes';

export default {
  props: new DialogProps(Instituicao),
  data: () => ({
    form: {
      lists: {
        esferas: [
          { label: "Público", value: "publico" },
          { label: "Privado", value: "privado" },
        ],
        governos: [
          { label: "Federal", value: "federal" },
          { label: "Estadual", value: "estadual" },
          { label: "Municipal", value: "municipal" },
          { label: "Privado", value: "privado" },
        ],
        poderes: [
          { label: "Executivo", value: "executivo" },
          { label: "Legislativo", value: "legislativo" },
          { label: "Privado", value: "privado" },
        ],
        investimentos: [
          { label: "Gratuito", value: "gratuito" },
          { label: "Pago", value: "pago" },
        ],
        modalidades: [
          { label: "Presencial", value: "presencial" },
          { label: "EAD", value: "ead" },
          { label: "Presencial/EAD", value: "presencialead" },
        ],
      },
    }
  }),
  methods: {
    salvar() {
      const save = () => {
        return this.$swal({
          text: `Confirma ${
            this.edit ? "a edição" : "o cadastro"
          } da Instituição?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          return this.edit
            ? this.$editarInstituicao(this.content, this.content.id)
            : this.$cadastrarInstituicao(this.content);
        });
      };
      if (this.$validarFormulario()) {
        save().then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.$emit("onClose");
          this.$emit("onSave");
        });
      }
    },
  },
};
</script>
