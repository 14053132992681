<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon> Visualizar Grupo
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="12">
              <v-text-field
                :value="content.nome"
                label="Nome do Grupo"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="6">
              <v-text-field
                :value="content.nomeDeExibicao"
                label="Descrição do Grupo"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="content.posicao"
                label="Posição Menu"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="12">
              <span class="small">Permissões</span>
              <v-chip-group active-class="primary--text" column>
                <v-chip v-for="permissao in content.permissoes" :key="permissao.id">
                  {{ permissao.nome }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </div>
        <div class="btn-group text-center">
          <v-btn color="primary" @click="$emit('onClose')">
            <v-icon>mdi-close</v-icon>
            Fechar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, Grupo } from '~/commons/classes';

export default {
  props: new DialogProps(Grupo),
};
</script>
