var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-3"
  }, [_c('avp-select-lotacao', {
    attrs: {
      "cols": 2,
      "relatorio": ""
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.form.filters.nome,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "nome", $$v);
      },
      expression: "form.filters.nome"
    }
  })], 1), _c('avp-select-ano-semestre', {
    attrs: {
      "cols": 4,
      "sufix": 'da Comprovação',
      "all": "",
      "active": _vm.form.filters.active
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-tooltip', {
    attrs: {
      "text": 'Buscar',
      "icon": 'mdi-magnify'
    },
    on: {
      "click": function ($event) {
        return _vm.buscarRelatorios();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Limpar',
      "icon": 'mdi-eraser'
    },
    on: {
      "click": function ($event) {
        return _vm.resetForm();
      }
    }
  })], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "mt-5 printable",
    attrs: {
      "headers": _vm.getFilteredHeader(_vm.headers),
      "items": _vm.form.lists.listRelatorios,
      "items-per-page": 10,
      "footer-props": _vm.$footerProps
    },
    scopedSlots: _vm._u([{
      key: `item.ano`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.ano) + " ")];
      }
    }, {
      key: `item.semestre`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.semestre) + " ")];
      }
    }, {
      key: `item.nomeProfissional`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.nomeProfissional) + " ")];
      }
    }, {
      key: `item.cpf`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "report"
        }, [_vm._v(_vm._s(_vm.$formatCpf(item.cpf)))])];
      }
    }, {
      key: `item.lotacao`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.lotacao) + " ")];
      }
    }, {
      key: `item.nomeCapacitacao`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.nomeCapacitacao) + " ")];
      }
    }, {
      key: `item.tipoAcaoCapacitacao`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.tipoAcaoCapacitacao) + " ")];
      }
    }, {
      key: `item.eixo`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.eixoTematico) + " ")];
      }
    }, {
      key: `item.trilhaAprendizagem`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.trilhaAprendizagem) + " ")];
      }
    }, {
      key: `item.acao`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.acao) + " ")];
      }
    }, {
      key: `item.DataAcao`,
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(item.DataAcao) + "h ")];
      }
    }], null, true)
  }), _vm.form.lists.listRelatorios.length ? _c('avp-buttons', {
    attrs: {
      "actionText": 'Imprimir',
      "actionIcon": 'mdi-printer',
      "cancelText": 'Baixar CSV',
      "cancelIcon": 'mdi-file'
    },
    on: {
      "click": function ($event) {
        return _vm.baixarRelatorioPdf();
      },
      "cancel": function ($event) {
        return _vm.baixarCSVRelatorio();
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }