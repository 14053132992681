var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "1000"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-eye")]), _vm._v(" Visualizar da Comprovação da Ação de Capacitação ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Profissional",
      "value": _vm.content.profissional,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '###.###.###-##',
      expression: "'###.###.###-##'"
    }],
    attrs: {
      "label": "CPF",
      "value": _vm.content.cpf,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Ano",
      "value": _vm.content.ano,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Semestre",
      "value": _vm.content.semestre,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome da Instituição",
      "value": _vm.content.instituicao.nomeDesOutro ? _vm.content.instituicao.nomeDesOutro : _vm.content.instituicao.nome,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Tipo da Ação de Capacitação",
      "value": _vm.content.tipoAcaoCapacitacao.nome,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Eixo Temático",
      "value": _vm.content.eixoTematico.nome,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Trilha de Aprendizagem",
      "value": _vm.content.trilhaDeAprendizagem.nome,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome da Capacitação",
      "value": _vm.content.nomeAcaoCapacitacao,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Discente/Docente",
      "value": _vm.content.tipoDaCondicaoCapacitacao,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Carga Horária",
      "value": _vm.content.cargaHoraria,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Dia, Mês e Ano de Início",
      "value": _vm.content.mesAnoInicio,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Dia, Mês e Ano de Conclusão",
      "value": _vm.content.mesAnoConclusao,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "text-center"
  }, [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.exibirPreviewArquivo
    }
  }, [_vm._v("Ver arquivo")])], 1), _c('avp-preview-file', {
    attrs: {
      "previewUrl": _vm.previewUrl,
      "visible": _vm.visibleDialogPreview,
      "path": _vm.content.anexo.path,
      "descricao": "Baixar arquivo"
    },
    on: {
      "onClose": function ($event) {
        return _vm.onCloseDialogPreview();
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  }, [_vm._v("Fechar")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }