<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Editar" : "Cadastrar" }} Trilha de Aprendizagem
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="12">
                <v-text-field
                  v-model="content.nome"
                  label="Descrição da Trilha de Aprendizagem"
                  counter="300"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $max(v, 300)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <avp-select-ano-semestre
                :cols="12"
                :active="!edit"
                :sufix="'do Ciclo'"
                required
                v-model="content"
              ></avp-select-ano-semestre>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="12">
                <v-select
                  v-model="content.eixoItens"
                  label="Eixo Temático"
                  multiple
                  outlined
                  :items="form.lists.listEixosTematicos"
                  :item-text="'descricao'"
                  :item-value="'id'"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <avp-buttons
          @click="salvar()"
          @cancel="$emit('onClose')"
          :actionText="'Salvar'"
        ></avp-buttons>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, TrilhaDeAprendizagem } from "~/commons/classes";

export default {
  props: new DialogProps(TrilhaDeAprendizagem),
  data: () => ({
    form: {
      lists: {
        listEixosTematicos: [],
      },
    },
  }),
  methods: {
    salvar() {
      const save = () => {
        return this.$swal({
          text: `Confirma ${
            this.edit ? "a edição" : "o cadastro"
          } da Trilha de Aprendizagem?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          return this.edit
            ? this.$editarTrilhaDeAprendizagem(this.content, this.content.id)
            : this.$cadastrarTrilhaDeAprendizagem(this.content);
        });
      };
      if (this.$validarFormulario()) {
        save().then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.$emit("onClose");
          this.$emit("onSave");
        });
      }
    },
  },
  mounted() {
    this.$listarEixosTematicosAcaoComprovacao().then(({ data }) => {
      this.form.lists.listEixosTematicos = data.eixosTematicos;
    });
  },
};
</script>
