<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterParametroComprovacao()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listParametros"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template v-slot:[`item.semestre`]="{ item }">
          {{ item.semestre }}º
        </template>
        <template v-slot:[`item.dataHoraInicio`]="{ item }">
          {{ item.dataInicioDaComprovacao | date }}
        </template>
        <template v-slot:[`item.dataHoraFim`]="{ item }">
          {{ item.dataFimDaComprovacao | date }}
        </template>
        <template v-slot:[`item.dataHoraInicioValidacao`]="{ item }">
          {{ item.dataInicioDaValidacao | date }}
        </template>
        <template v-slot:[`item.dataHoraFimValidacao`]="{ item }">
          {{ item.dataFimDaValidacao | date }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            :text="'Visualizar Parâmetro'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarParametroComprovacao(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="'Editar Parâmetro'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterParametroComprovacao(item)"
          ></avp-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarParametroComprovacao
      :visible="dialogManterParametroComprovacao.visible"
      :content="dialogManterParametroComprovacao.content"
      :edit="dialogManterParametroComprovacao.edit"
      @onClose="dialogManterParametroComprovacao.close()"
      @onSave="obterParametrizacaoComprovacao()"
    ></CadastrarEditarParametroComprovacao>
    <VisualizarParametroComprovacao
      :visible="dialogVisualizarParametroComprovacao.visible"
      :content="dialogVisualizarParametroComprovacao.content"
      @onClose="dialogVisualizarParametroComprovacao.close()"
    ></VisualizarParametroComprovacao>
  </div>
</template>

<script>
import { Dialog, ParametroDaComprovacao } from "~/commons/classes";
import CadastrarEditarParametroComprovacao from "./dialogs/CadastrarEditarParametroComprovacao.vue";
import VisualizarParametroComprovacao from "./dialogs/VisualizarParametroComprovacao.vue";

export default {
  name: "ManterInstituicaoView",
  components: {
    CadastrarEditarParametroComprovacao,
    VisualizarParametroComprovacao,
  },
  data: () => ({
    dialogManterParametroComprovacao: Dialog.Build(),
    dialogVisualizarParametroComprovacao: Dialog.Build(),
    form: {
      lists: {
        listParametros: [],
      },
    },
    headers: [
      { text: "Nome", align: "center", value: "nome" },
      { text: "Ano", align: "center", value: "ano" },
      { text: "Semestre", align: "center", value: "semestre" },
      {
        text: "Data/Hora Início Comprovação",
        align: "center",
        value: "dataHoraInicio",
      },
      {
        text: "Data/Hora Fim Comprovação",
        align: "center",
        value: "dataHoraFim",
      },
      {
        text: "Data/Hora Início Validação",
        align: "center",
        value: "dataHoraInicioValidacao",
      },
      {
        text: "Data/Hora Fim Validação",
        align: "center",
        value: "dataHoraFimValidacao",
      },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ],
  }),
  methods: {
    openDialogManterParametroComprovacao(parametro) {
      parametro
        ? this.$buscarDadosParametrizacaoComprovacao(parametro.id).then(
            ({ data }) => {
              this.dialogManterParametroComprovacao.open(
                new ParametroDaComprovacao(data.parametrizacaoEdicao),
                true
              );
            }
          )
        : this.$listarAnoSemestreParametrizacao().then(({ data }) =>
            this.dialogManterParametroComprovacao.open(
              new ParametroDaComprovacao({ listAnos: data.anosSemestres })
            )
          );
    },
    openDialogVisualizarParametroComprovacao(parametro) {
      this.$buscarDadosParametrizacaoComprovacao(parametro.id).then(
        ({ data }) =>
          this.dialogVisualizarParametroComprovacao.open(
            data.parametrizacaoEdicao
          )
      );
    },
    obterParametrizacaoComprovacao() {
      this.$obterParametrizacaoComprovacao().then(
        ({ data }) => (this.form.lists.listParametros = data.edicoes)
      );
    },
    inativarParametrizacaoComprovacao(parametro) {
      this.$swal({
        text: `Deseja confirmar a ${
          parametro.ativo ? "inativação" : "ativação"
        } do Parâmetro da Comprovação?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarParametrizacaoComprovacao(
          { id: parametro.id },
          parametro.id
        ).then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.obterParametrizacaoComprovacao();
        });
      });
    },
  },
  mounted() {
    this.obterParametrizacaoComprovacao();
  },
};
</script>