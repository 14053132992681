<template>
  <div class="text-center">
    <v-dialog v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Editar" : "Cadastrar" }} Termo
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="6">
                <v-text-field
                  v-model="content.nome"
                  label="Nome do Termo*"
                  counter="50"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $max(v, 50)]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-if="cadastrarTipoTermo"
                  v-model="content.tipoDoTermo"
                  label="Nome do Novo Tipo do Termo"
                  counter="50"
                  append-icon="mdi-close"
                  @click:append="alterarFlagCadastrarTipoTermo()"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $max(v, 50)]"
                ></v-text-field>
                <v-autocomplete
                  v-else
                  v-model="content.tipoDoTermo"
                  label="Tipo do Termo*"
                  :items="form.lists.tiposDeTermo"
                  :item-text="'descricao'"
                  :item-value="'descricao'"
                  :rules="[(v) => $required(v)]"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <avp-date-picker
                v-model="content.dataInicio"
                :dataEdicao="content.dataInicio"
                label="Data Início*"
                cols="6"
                required
              ></avp-date-picker>
              <avp-date-picker
                v-model="content.dataFim"
                :dataEdicao="content.dataFim"
                label="Data Fim*"
                cols="6"
                required
              ></avp-date-picker>
            </v-row>
            <v-row>
              <v-col cols="12">
                <avp-editor-html v-model="content.html"></avp-editor-html>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <avp-buttons
          @click="salvar()"
          @cancel="$emit('onClose')"
          :actionText="'Salvar'"
        ></avp-buttons>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, Termo } from '~/commons/classes';

export default {
  props: new DialogProps(Termo),
  data: () => ({
    form: {
      lists: {
        tiposDeTermo: []
      }
    },
    cadastrarTipoTermo: false
  }),
  watch: {
    "content.tipoDoTermo": {
      handler(v) {
        if (v == "Cadastrar Novo Tipo") {
          this.alterarFlagCadastrarTipoTermo();
        }
      }
    }
  },
  methods: {
    salvar() {
      const save = (objEnvio) => {
        return this.$swal({
          text: `Confirma ${
            this.edit ? "a edição" : "o cadastro"
          } do Termo?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          return this.edit
            ? this.$editarTermo(new Termo(objEnvio), this.content.id)
            : this.$cadastrarTermo(new Termo(objEnvio));
        });
      };
      if (this.$validarFormulario() && this.content.html) {
        const objEnvio = {
          ...this.content,
          dataInicio: this.$formatarDateParaEnviar(this.content.dataInicio),
          dataFim: this.$formatarDateParaEnviar(this.content.dataFim)
        }
        return objEnvio.dataInicio > objEnvio.dataFim
          ? this.$notifyWarning("A Data inicial da não pode ser maior que a final!")
          : save(objEnvio).then(({ data }) => {
            this.$notifySuccess(data.mensagem);
            this.$emit("onClose");
            this.$emit("onSave");
          });
      } else {
        this.$notifyWarning("Preencha todos os campos obrigatórios!");
      }
    },
    alterarFlagCadastrarTipoTermo() {
      this.cadastrarTipoTermo = !this.cadastrarTipoTermo;
      this.content.tipoDoTermo = null;
    }
  },
  mounted() {
    this.$listarTiposDeTermo().then(({ data }) => {
      this.form.lists.tiposDeTermo = data.termoTipos;
      this.form.lists.tiposDeTermo.push('Cadastrar Novo Tipo');
    })
  }
};
</script>