var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-pencil")]), _vm._v(" " + _vm._s(_vm.edit ? "Editar" : "Cadastrar") + " Usuário ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_vm.visible ? _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome do Usuário*",
      "counter": "252",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 252);
      }]
    },
    model: {
      value: _vm.content.nome,
      callback: function ($$v) {
        _vm.$set(_vm.content, "nome", $$v);
      },
      expression: "content.nome"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '###.###.###-##',
      expression: "'###.###.###-##'"
    }],
    attrs: {
      "label": "CPF*",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.content.cpf,
      callback: function ($$v) {
        _vm.$set(_vm.content, "cpf", $$v);
      },
      expression: "content.cpf"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "E-mail*",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$email(v);
      }]
    },
    model: {
      value: _vm.content.email,
      callback: function ($$v) {
        _vm.$set(_vm.content, "email", $$v);
      },
      expression: "content.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Perfil*",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "items": _vm.form.lists.listPerfis,
      "item-text": 'nome',
      "item-value": 'id',
      "rules": [function (p) {
        return p.some(function (v) {
          return _vm.$required(v);
        }) || 'Campo obrigatório';
      }]
    },
    model: {
      value: _vm.content.perfis,
      callback: function ($$v) {
        _vm.$set(_vm.content, "perfis", $$v);
      },
      expression: "content.perfis"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-switch', {
    staticClass: "no-margin",
    attrs: {
      "disabled": _vm.edit,
      "label": "Usuário SADRH"
    },
    model: {
      value: _vm.content.usuarioSadRh,
      callback: function ($$v) {
        _vm.$set(_vm.content, "usuarioSadRh", $$v);
      },
      expression: "content.usuarioSadRh"
    }
  })], 1)], 1), _vm.content.perfis.includes(5) || _vm.content.usuarioValidador ? _c('v-row', [_c('avp-select-ano-semestre', {
    attrs: {
      "cols": 12,
      "active": false,
      "sufix": '*',
      "required": ""
    },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1) : _vm._e(), _vm.content.usuarioValidador ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Lotações",
      "multiple": "",
      "outlined": "",
      "dense": "",
      "items": _vm.form.lists.listLotacoes,
      "item-text": 'nome',
      "item-value": 'codigo',
      "search-input": _vm.searchInput
    },
    on: {
      "input": function ($event) {
        _vm.searchInput = null;
      },
      "update:searchInput": function ($event) {
        _vm.searchInput = $event;
      },
      "update:search-input": function ($event) {
        _vm.searchInput = $event;
      }
    },
    model: {
      value: _vm.content.lotacoes,
      callback: function ($$v) {
        _vm.$set(_vm.content, "lotacoes", $$v);
      },
      expression: "content.lotacoes"
    }
  })], 1)], 1) : _vm._e(), _c('v-card', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Validador"
    },
    model: {
      value: _vm.content.usuarioValidador,
      callback: function ($$v) {
        _vm.$set(_vm.content, "usuarioValidador", $$v);
      },
      expression: "content.usuarioValidador"
    }
  })], 1), _vm.content.usuarioValidador ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Validar Todas as Lotações"
    },
    model: {
      value: _vm.content.validarTodasLotacoes,
      callback: function ($$v) {
        _vm.$set(_vm.content, "validarTodasLotacoes", $$v);
      },
      expression: "content.validarTodasLotacoes"
    }
  })], 1) : _vm._e(), _vm.content.usuarioValidador ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Visualizar Todas as Lotações"
    },
    model: {
      value: _vm.content.visualizarTodasLotacoes,
      callback: function ($$v) {
        _vm.$set(_vm.content, "visualizarTodasLotacoes", $$v);
      },
      expression: "content.visualizarTodasLotacoes"
    }
  })], 1) : _vm._e(), _vm.content.usuarioValidador ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Validador Ativo"
    },
    model: {
      value: _vm.content.validadorAtivo,
      callback: function ($$v) {
        _vm.$set(_vm.content, "validadorAtivo", $$v);
      },
      expression: "content.validadorAtivo"
    }
  })], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1), _c('avp-buttons', {
    attrs: {
      "actionText": _vm.edit ? 'Editar' : 'Salvar'
    },
    on: {
      "click": function ($event) {
        return _vm.salvar();
      },
      "cancel": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }