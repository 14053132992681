<template>
  <div class="text-center">
    <v-dialog v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon> Visualizar Termo
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="6">
              <v-text-field
                :value="content.nome"
                label="Nome do Termo"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="content.tipoDoTermo"
                label="Tipo de Termo"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="6">
              <v-text-field
                :value="content.dataInicio | date"
                label="Data Início"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="content.dataFim | date"
                label="Data Fim"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="12">
              <avp-editor-html
                v-model="content.html"
                disabled
              ></avp-editor-html>
            </v-col>
          </v-row>
        </div>
        <div class="btn-group text-center">
          <v-btn color="primary" @click="$emit('onClose')">
            <v-icon>mdi-close</v-icon>
            Fechar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, Termo } from '~/commons/classes';

export default {
  props: new DialogProps(Termo),
};
</script>
