<template>
    <div class="page">
      <v-container fluid>
        <avp-breadcrumbs></avp-breadcrumbs>
        <v-form ref="form">
          <v-row class="d-flex justify-center mt-3">
            <avp-select-lotacao
              :cols="2"
              v-model="form.filters"
              relatorio
            ></avp-select-lotacao>
            <v-col cols="2">
              <v-text-field
                label="Nome"
                v-model="form.filters.nome"
                outlined
                dense
              ></v-text-field>
            </v-col>
           <avp-select-situacao
            :cols="2"
            :exclude="[3]"
            v-model="form.filters"
            all
          ></avp-select-situacao>
            <avp-select-ano-semestre
              :key="componentKey"
              :cols="4"
              :sufix="'do Ciclo'"
              v-model="form.filters"
              all
              :disabled="disabledAnoSemestre"
              :active="form.filters.active"
            ></avp-select-ano-semestre>
          </v-row>
          <div class="text-center">
            <v-col cols="12">
              <avp-tooltip
                :text="'Buscar'"
                :icon="'mdi-magnify'"
                @click="buscarRelatorios()"
              ></avp-tooltip>
              <avp-tooltip
                :text="'Limpar'"
                :icon="'mdi-eraser'"
                @click="resetForm()"
              ></avp-tooltip>
            </v-col>
          </div>
        </v-form>
        <v-data-table
          class="mt-5 printable"
          :headers="getFilteredHeader(headers)"
          :items="form.lists.listRelatorios"
          :items-per-page="form.itemsPerPage"
          :footer-props="$footerProps"
          show-expand
        >
          <template v-slot:[`item.ano`]="{ item }">
            {{ item.ano }}
          </template>
          <template v-slot:[`item.semestre`]="{ item }">
            {{ item.semestre }}
          </template>
          <template v-slot:[`item.validado`]="{ item }">
            <avp-label-ativo :ativo="item.validado"></avp-label-ativo>
          </template>
            <template v-slot:[`item.nome`]="{ item }">
              {{ item.nome }}
          </template>
          <template class="report" v-slot:[`item.cpf`]="{ item }">
            {{ $formatCpf(item.cpf) }}
          </template>
          <template v-slot:[`item.lotacao`]="{ item }">
            {{ item.lotacao }}
          </template>
          <template class="hide-report" v-slot:expanded-item="{ headers, item  }">
            <td class="resposta hide-report" :colspan="headers.length">
              {{ item.motivo }}
            </td>
          </template>
          <template class="report" v-slot:[`item.motivo`]="{ item }">
            {{ item.motivo }}
          </template>

          
          <template v-slot:[`item.motivoDescricao`]="{ item }">
            {{ item.motivoDescricao }}
          </template>
          <template v-slot:[`item.nomeDoValidador`]="{ item }">
            {{ item.nomeDoValidador }}
          </template>
          <template v-slot:[`item.dataDaValidacao`]="{ item }">
            {{ item.dataDaValidacao }}
          </template>
          <template v-slot:[`item.acao`]="{ item }">
            {{ item.acao }}
          </template>
        </v-data-table>
        <avp-buttons
          v-if="form.lists.listRelatorios.length"
          :actionText="'Imprimir'"
          :actionIcon="'mdi-printer'"
          :cancelText="'Baixar CSV'"
          :cancelIcon="'mdi-file'"
          @click="baixarRelatorioPdf()"
          @cancel="baixarCSVRelatorio()"
        ></avp-buttons>
      </v-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "ListarLogValidacaoComprovacao",
    data: () => ({
      onReport: false,
      componentKey: 0,
      disabledAnoSemestre: false,
      form: {
        filters: {
          lotacao: null,
          ano: null,
          semestre: null,
          nome: null,
          situacao: null,
          tipoRelatorio: 6,
        },
        lists: {
          listRelatorios: [],
          listPerfis: [],
        },
        itemsPerPage: 10,
        geradorRelatorio: null,
        horaDaGeracao: null,
        totalValidacao: 0,
        mostrarAnalitico: true,
      },
      headers: [
        { text: "Ano", align: "center", value: "ano" },
        { text: "Semestre", align: "center", value: "semestre" },
        { text: "Validado", align: "center", value: "validado" },
        { text: "Nome", align: "center", value: "nome" },
        { text: "CPF", align: "center", value: "cpf", onlyReport: true},
        { text: "Lotação", align: "center", value: "lotacao" , onlyReport: true},
        { text: "Motivo", align: "center", value: "data-table-expand", sortable: false, onlyView: true },
        { text: "Motivo", align: "center", value: "motivo", onlyReport: true },
        { text: "Descrição do Motivo", align: "center", value: "motivoDescricao", onlyReport: true },
        { text: "Nome do Validador", align: "center", value: "nomeDoValidador" },
        { text: "Data/hora da Validação", align: "center", value: "dataDaValidacao" },
        { text: "Ação", align: "center", value: "acao" },
      ],
    }),
    methods: {
      getFilteredHeader(headers) {
        return headers.filter((header) => {
          if (header.onlyView) return !this.onReport;
          if (header.onlyReport) return this.onReport;
          return true;
        });
      },
      buscarRelatorios() {
        if (this.$validarFormulario()) {
          const preparedRequest = JSON.parse(JSON.stringify(this.form.filters));
          if (preparedRequest.dataFimTentativaLogin)
            preparedRequest.dataFimTentativaLogin = this.$formatarDateParaEnviar(
              preparedRequest.dataFimTentativaLogin,
              "23:59:59"
            );
          const buscarRelatorio = (params) => {
            return this.$obterRelatoriosLogValidacaoComprovacao(params);
          };
          buscarRelatorio(this.$montarParamsFilters(preparedRequest)).then(
            ({ data }) => {
              this.form.geradorRelatorio = data.relatorio.geradorRelatorio;
              this.form.horaDaGeracao = data.relatorio.horaDaGeracao;
              this.form.totalValidacao =
                data.relatorio.totalValidacao;
              this.form.lists.listRelatorios = data.relatorio.lista;
            }
          );
        }
      },
      imprimirRelatorio() {
        this.onReport = true;
        this.form.itemsPerPage = -1;
        const importCSStoPrint = () =>
          import("!raw-loader!sass-loader!/src/assets/styles/_print.scss");
        importCSStoPrint().then((scss) => {
          let newWindow = window.open();
          newWindow.document.write(`
          <div class="report">
            <style>${scss.default}</style>
            <div class="report-header-title">
              <h1>Relatório de Logs de Validação de Comprovação de Capacitação</h1>
            </div>
            ${document.querySelector(".printable").outerHTML}
            <div class="report-header-title">
              <h3>Quantidade Total de Registros: ${
                this.form.totalValidacao
              }</h3>
              <h3>Nome do usuário de Geração: ${this.form.geradorRelatorio}</h3>
              <h3>Data/Hora da Geração: ${this.form.horaDaGeracao}</h3>
            </div>
          </div>`);
          newWindow.print();
          newWindow.close();
          this.form.itemsPerPage = 10;
          this.onReport = false;
        });
      },
      baixarCSVRelatorio() {
        const baixarRelatorio = (params) => {
          return this.$obterRelatorioCSVLogValidacaoComprovacao(params);
        };
        baixarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
          ({ data }) => this.$baixarRelatorioCSV(data, "relatorio-log-validacao-comprovacao")
        );
      },
      baixarRelatorioPdf() {
        const baixarPdf = (params) => {
          return this.$obterRelatorioPdf(params);
        };
        baixarPdf(this.$montarParamsFilters(this.form.filters))
        .then(({data}) => {
          

          const file = this.$getFileFromBase64(
            data.resposta.arquivoBase64, 
            data.resposta.nome, 
            data.resposta.tipoConteudo, 
            data.resposta.extensao
            );

          this.$baixarArquivo(file, data.resposta.tipoConteudo);

        });
      },
      forceRender() {
        this.componentKey += 1;
      },
      resetForm() {
        this.$resetForm();
        this.form.lists.listRelatorios = [];
        this.disabledAnoSemestre = false;
        this.form.filters.active = true;
        this.forceRender();
      }
    }
  };
  </script>

<style scoped>
.resposta {
  white-space: pre-wrap;
  padding: 1em !important;
  text-align: center;
  font-weight: bold;
}
</style>