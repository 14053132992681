<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <avp-select-ano-semestre
            :cols="4"
            :sufix="'da Comprovação'"
            v-model="form.filters"
          ></avp-select-ano-semestre>
          <avp-select-ativo :cols="2" v-model="form.filters.ativo"></avp-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarCiclo()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterCiclo()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listCiclos"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template v-slot:[`item.semestre`]="{ item }">
          {{ item.semestre }}
        </template>
        <template v-slot:[`item.dataInicioSemestre`]="{ item }">
          {{ $moment(item.dataInicioSemestre).format("DD/MM/YYYY") }}
        </template>
        <template v-slot:[`item.dataFimSemestre`]="{ item }">
          {{ $moment(item.dataFimSemestre).format("DD/MM/YYYY") }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            :text="'Visualizar Ciclo'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarCiclo(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="'Editar Ciclo'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterCiclo(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Ciclo`"
            :icon="`${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`"
            :small="true"
            @click="inativarAtivarCiclo(item)"
          ></avp-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarManterCicloDialog
      :visible="dialogManterCiclo.visible"
      :content="dialogManterCiclo.content"
      :edit="dialogManterCiclo.edit"
      @onClose="dialogManterCiclo.close()"
      @onSave="buscarCiclo()"
    ></CadastrarEditarManterCicloDialog>
    <VisualizarManterCicloDialog
      :visible="dialogVisualizarCiclo.visible"
      :content="dialogVisualizarCiclo.content"
      @onClose="dialogVisualizarCiclo.close()"
    ></VisualizarManterCicloDialog>
  </div>
</template>

<script>
import CadastrarEditarManterCicloDialog from "./dialogs/CadastrarEditarManterCicloDialog.vue";
import VisualizarManterCicloDialog from "./dialogs/VisualizarManterCicloDialog.vue";
import { Dialog, Ciclo } from "~/commons/classes";

export default {
  name: "ManterCicloView",
  components: {
    CadastrarEditarManterCicloDialog,
    VisualizarManterCicloDialog,
  },
  data: () => ({
    dialogManterCiclo: Dialog.Build(),
    dialogVisualizarCiclo: Dialog.Build(),
    form: {
      filters: {
        ano: null,
        semestre: null,
        dataInicio: null,
        dataFim: null,
        ativo: null,
      },
      lists: {
        listCiclos: []
      }
    },
    headers: [
      { text: "Ano", align: "center", value: "ano" },
      { text: "Semestre", align: "center", value: "semestre" },
      { text: "Data Inicio", align: "center", value: "dataInicioSemestre" },
      { text: "Data Fim", align: "center", value: "dataFimSemestre" },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ],
  }),
  methods: {
    openDialogManterCiclo(ciclo) {
      ciclo
        ? this.$buscarDadosCiclo(ciclo).then(({ data }) => {
            this.dialogManterCiclo.open({
              ...data.ciclo,
              dataInicioSemestre: this.$moment(data.ciclo.dataInicioSemestre).format("DD/MM/YYYY"),
              dataFimSemestre: this.$moment(data.ciclo.dataFimSemestre).format("DD/MM/YYYY"),
            }, true)
          })
        : this.dialogManterCiclo.open(Ciclo.Build())
    },
    openDialogVisualizarCiclo(ciclo) {
      this.$buscarDadosCiclo(ciclo).then(({ data }) => {
        this.dialogVisualizarCiclo.open({
          ...data.ciclo,
          dataInicioSemestre: this.$moment(data.ciclo.dataInicioSemestre).format("DD/MM/YYYY"),
          dataFimSemestre: this.$moment(data.ciclo.dataFimSemestre).format("DD/MM/YYYY"),
        });
      });
    },
    buscarCiclo() {
      this.$buscarCiclo(this.$montarParamsFilters(this.form.filters)).then(
        ({ data }) => (this.form.lists.listCiclos = data.ciclo)
      );
    },
    inativarAtivarCiclo(ciclo) {
      this.$swal({
        text: `Deseja confirmar a ${
          !ciclo.ativo ? "ativação" : "inativação"
        } do Ciclo?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarAtivarCiclo(
          { id: ciclo.id, ativo: !ciclo.ativo },
          ciclo.id
        ).then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.buscarCiclo();
        });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listCiclos = [];
    },
  },
};
</script>