var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-pencil")]), _vm._v(" " + _vm._s(_vm.edit ? "Editar" : "Cadastrar") + " Trilha de Aprendizagem ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_vm.visible ? _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Descrição da Trilha de Aprendizagem",
      "counter": "300",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 300);
      }]
    },
    model: {
      value: _vm.content.nome,
      callback: function ($$v) {
        _vm.$set(_vm.content, "nome", $$v);
      },
      expression: "content.nome"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('avp-select-ano-semestre', {
    attrs: {
      "cols": 12,
      "active": !_vm.edit,
      "sufix": 'do Ciclo',
      "required": ""
    },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Eixo Temático",
      "multiple": "",
      "outlined": "",
      "items": _vm.form.lists.listEixosTematicos,
      "item-text": 'descricao',
      "item-value": 'id'
    },
    model: {
      value: _vm.content.eixoItens,
      callback: function ($$v) {
        _vm.$set(_vm.content, "eixoItens", $$v);
      },
      expression: "content.eixoItens"
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('avp-buttons', {
    attrs: {
      "actionText": 'Salvar'
    },
    on: {
      "click": function ($event) {
        return _vm.salvar();
      },
      "cancel": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }