<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Editar" : "Cadastrar" }} Tipo de Ação de Capacitação
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="12">
                <v-text-field
                  v-model="content.nome"
                  label="Tipo de Ação de Capacitação"
                  counter="50"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $max(v, 50)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="6">
                <v-text-field
                  v-model="content.cargaHorariaMinima"
                  label="Carga Horária Mínima"
                  type="number"
                  outlined
                  dense
                  :rules="[
                    (v) => $required(v),
                    (v) => $min(v, 1),
                    (v) => $minValue(v, 2),
                    (v) => $maxValue(v, 99),
                    (v) => $onlyTextAndNumbers(v),
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="content.cargaHorariaMaxima"
                  label="Carga Horária Máxima"
                  type="number"
                  outlined
                  dense
                  :rules="[
                    (v) => $required(v),
                    (v) => $min(v, 1),
                    (v) => $minValue(v, 2),
                    (v) => $maxValue(v, 99),
                    (v) => $onlyTextAndNumbers(v),
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <avp-select-ano-semestre
                :cols="12"
                :sufix="'do Ciclo'"
                v-model="content"
                required
              ></avp-select-ano-semestre>
            </v-row>
          </v-form>
        </div>
        <avp-buttons
          @click="salvar()"
          @cancel="$emit('onClose')"
          :actionText="edit ? 'Editar' : 'Salvar'"
        ></avp-buttons>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, TipoAcaoCapacitacao } from '~/commons/classes';

export default {
  props: new DialogProps(TipoAcaoCapacitacao),
  methods: {
    salvar() {
      const save = () => {
        return this.$swal({
          text: `Confirma ${
            this.edit ? "a edição" : "o cadastro"
          } do Tipo de Ação de Capacitação?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          return this.edit
            ? this.$editarTipoAcaoCapacitacao(this.content, this.content.id)
            : this.$cadastrarTipoAcaoCapacitacao(this.content);
        });
      };
      if (this.$validarFormulario()) {
        save().then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.$emit("onClose");
          this.$emit("onSave");
        });
      }
    },
  },
};
</script>
