<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon> Visualizar Dados da Instituição
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="12">
              <v-text-field
                :value="content.nome"
                label="Nome da Instituição"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="6">
              <v-select
                :value="content.esfera"
                :items="lists.esferas"
                :item-text="'label'"
                label="Esfera"
                readonly
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                :value="content.governo"
                :items="lists.governos"
                :item-text="'label'"
                label="Governo"
                readonly
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="4">
              <v-select
                :value="content.poder"
                :items="lists.poderes"
                :item-text="'label'"
                label="Poder"
                readonly
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :value="content.investimento"
                :items="lists.investimentos"
                :item-text="'label'"
                label="Investimento"
                readonly
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :value="content.modalidade"
                :items="lists.modalidades"
                :item-text="'label'"
                label="Modalidade"
                readonly
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="12">
              <v-text-field
                :value="content.url"
                label="Link"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="btn-group text-center">
          <v-btn color="primary" @click="$emit('onClose')">
            <v-icon>mdi-close</v-icon>
            Fechar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, Instituicao } from '~/commons/classes';

export default {
  props: new DialogProps(Instituicao),
  data: () => ({
    lists: {
      esferas: [
        { label: "Público", value: "publico" },
        { label: "Privado", value: "privado" },
      ],
      governos: [
        { label: "Federal", value: "federal" },
        { label: "Estadual", value: "estadual" },
        { label: "Municipal", value: "municipal" },
        { label: "Privado", value: "privado" },
      ],
      poderes: [
        { label: "Executivo", value: "executivo" },
        { label: "Legislativo", value: "legislativo" },
        { label: "Privado", value: "privado" },
      ],
      investimentos: [
        { label: "Gratuito", value: "gratuito" },
        { label: "Pago", value: "pago" },
      ],
      modalidades: [
        { label: "Presencial", value: "presencial" },
        { label: "EAD", value: "ead" },
        { label: "Presencial/EAD", value: "presencialead" },
      ],
    },
  }),
};
</script>
