var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-form', {
    ref: "form"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-tooltip', {
    attrs: {
      "text": 'Incluir',
      "icon": 'mdi-plus'
    },
    on: {
      "click": function ($event) {
        return _vm.openDialogManterParametroComprovacao();
      }
    }
  })], 1)], 1)]), _c('v-data-table', {
    staticClass: "mt-5",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.form.lists.listParametros,
      "items-per-page": 10,
      "footer-props": _vm.$footerProps
    },
    scopedSlots: _vm._u([{
      key: `item.nome`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.nome) + " ")];
      }
    }, {
      key: `item.ano`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.ano) + " ")];
      }
    }, {
      key: `item.semestre`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.semestre) + "º ")];
      }
    }, {
      key: `item.dataHoraInicio`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm._f("date")(item.dataInicioDaComprovacao)) + " ")];
      }
    }, {
      key: `item.dataHoraFim`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm._f("date")(item.dataFimDaComprovacao)) + " ")];
      }
    }, {
      key: `item.dataHoraInicioValidacao`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm._f("date")(item.dataInicioDaValidacao)) + " ")];
      }
    }, {
      key: `item.dataHoraFimValidacao`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm._f("date")(item.dataFimDaValidacao)) + " ")];
      }
    }, {
      key: `item.ativo`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('avp-label-ativo', {
          attrs: {
            "ativo": item.ativo
          }
        })];
      }
    }, {
      key: `item.acoes`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('avp-tooltip', {
          attrs: {
            "text": 'Visualizar Parâmetro',
            "icon": 'mdi-eye-outline',
            "small": true
          },
          on: {
            "click": function ($event) {
              return _vm.openDialogVisualizarParametroComprovacao(item);
            }
          }
        }), _c('avp-tooltip', {
          attrs: {
            "text": 'Editar Parâmetro',
            "icon": 'mdi-pencil',
            "small": true
          },
          on: {
            "click": function ($event) {
              return _vm.openDialogManterParametroComprovacao(item);
            }
          }
        })];
      }
    }], null, true)
  })], 1), _c('CadastrarEditarParametroComprovacao', {
    attrs: {
      "visible": _vm.dialogManterParametroComprovacao.visible,
      "content": _vm.dialogManterParametroComprovacao.content,
      "edit": _vm.dialogManterParametroComprovacao.edit
    },
    on: {
      "onClose": function ($event) {
        return _vm.dialogManterParametroComprovacao.close();
      },
      "onSave": function ($event) {
        return _vm.obterParametrizacaoComprovacao();
      }
    }
  }), _c('VisualizarParametroComprovacao', {
    attrs: {
      "visible": _vm.dialogVisualizarParametroComprovacao.visible,
      "content": _vm.dialogVisualizarParametroComprovacao.content
    },
    on: {
      "onClose": function ($event) {
        return _vm.dialogVisualizarParametroComprovacao.close();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }