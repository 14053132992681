<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="700">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-account-edit</v-icon>
            Informe o Motivo da Não Validação da Comprovação
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="12">
                <v-select
                  outlined
                  dense
                  v-model="form.motivoSelecionado"
                  label="Motivo"
                  :items="content.motivos"
                  :item-text="'descricao'"
                  :item-value="'id'"
                  :rules="[(v) => $required(v)]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="12">
                <v-textarea
                  outlined
                  dense
                  v-model="form.detalhamentoMotivo"
                  counter="400"
                  label="Detalhamento do Motivo"
                  :rules="[(v) => $required(v), (v) => $max(v, 400)]"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <v-card-actions>
          <avp-buttons
            @click="salvar()"
            @cancel="$emit('onClose')"
            :actionText="'Salvar'"
          ></avp-buttons>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, MotivoNaoValidacao } from '~/commons/classes';

export default {
  props: new DialogProps(MotivoNaoValidacao),
  data: () => ({
    form: {
      motivoSelecionado: null,
      detalhamentoMotivo: null,
    },
  }),
  watch: {
    visible(val) {
      if (!val) {
        this.form.motivoSelecionado = null;
        this.form.detalhamentoMotivo = null;
        this.$emit("onClose");
      } else {
        this.form.motivoSelecionado = this.content.validacao.motivoRejeicaoId;
        this.form.detalhamentoMotivo = this.content.validacao.motivoRejeicaoDescricao;
      }
    },
  },
  methods: {
    salvar() {
      const confirmar = () => {
        return this.$swal({
          text: `Confirma a Não Validação da comprovação da ação de capacitação?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        });
      };
      if (this.$validarFormulario()) {
        confirmar().then(
          () => this.$emit("onSelectValue", this.form) && this.$emit("onClose")
        );
      }
    },
  },
};
</script>