<template>
    <div class="page">
      <v-container fluid>
        <avp-breadcrumbs></avp-breadcrumbs>
        <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="4">
            <v-text-field
              label="Pergunta"
              v-model="form.filters.pergunta"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
          <div class="d-flex justify-center"></div>
          <div class="text-center">
            <v-col cols="12">
              <avp-tooltip
                :text="'Buscar'"
                :icon="'mdi-magnify'"
                @click="buscarRelatorios()"
              ></avp-tooltip>
              <avp-tooltip
                :text="'Limpar'"
                :icon="'mdi-eraser'"
                @click="resetForm()"
              ></avp-tooltip>
            </v-col>
          </div>
        </v-form>

        <v-data-table
          class="mt-5 printable"
          :headers="getFilteredHeader(headers)"
          :items="form.lists.listRelatorios"
          :items-per-page="form.itemsPerPage"
          :footer-props="$footerProps"
          show-expand
        >
          <template v-slot:[`item.pergunta`]="{ item }">
            {{ item.pergunta }}
          </template>
          <template v-slot:[`item.palavrasChaveStr`]="{ item }">
            {{ item.palavrasChaveStr }}
          </template>
          <template v-slot:[`item.posicao`]="{ item }">
            {{ item.posicao }}º
          </template>
          <template class="hide-report" v-slot:expanded-item="{ headers, item  }">
            <td class="resposta hide-report" :colspan="headers.length">
              {{ item.resposta }}
            </td>
          </template>
          <template class="report" v-slot:[`item.resposta`]="{ item }">
            {{ item.resposta }}
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
          </template>
          <template class="report" v-slot:[`item.nomeUsuarioAcao`]="{ item }">
            {{ item.nomeUsuarioAcao }}
          </template>
          <template class="report" v-slot:[`item.usuarioAcao`]="{ item }">
            {{ item.usuarioAcao }}
          </template>
          <template class="report" v-slot:[`item.dataHoraAcao`]="{ item }">
            {{ item.dataHoraAcao }}
          </template>
        </v-data-table>
        <avp-buttons
          v-if="form.lists.listRelatorios.length"
          :actionText="'Imprimir'"
          :actionIcon="'mdi-printer'"
          :cancelText="'Baixar CSV'"
          :cancelIcon="'mdi-file'"
          @click="baixarRelatorioPdf()"
          @cancel="baixarCSVLogFaq()"
        ></avp-buttons>
      </v-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "ListarRelatoriosLogFaq",
    data: () => ({
      onReport: false,
      form: {
        filters: {
          pergunta: null,
          tipoRelatorio: 3,
        },
        lists: {
          listRelatorios: [],
        },
        itemsPerPage: 10,
        geradorRelatorio: null,
        horaDaGeracao: null,
        totalRegistros: 0,
      },
      headers: [
        { text: "Pergunta", align: "center", value: "pergunta" },
        { text: "Palavra-chave", align: "center", value: "palavrasChaveStr" },
        { text: "Posição", align: "center", value: "posicao" },
        { text: "Resposta", align: "center", value: "data-table-expand", sortable: false, onlyView: true },
        { text: "Resposta", align: "center", value: "resposta", onlyReport: true },
        { text: "Ativo", align: "center", value: "ativo" },
        { text: "Nome do Usuário da Ação", align: "center", value: "nomeUsuarioAcao", onlyReport: true },
        { text: "Usuário da Ação", align: "center", value: "usuarioAcao", onlyReport: true },
        { text: "Data/hora da Ação", align: "center", value: "dataHoraAcao", onlyReport: true },
        { text: "Ação", align: "center", value: "acao" },
      ],
    }),
    methods: {
      getFilteredHeader(headers){
        return headers.filter(header => {
          if(header.onlyView) return !this.onReport;
          if(header.onlyReport) return this.onReport;
          return true
        })
  
      },
      buscarRelatorios() {
        if (this.$validarFormulario()) {
          const buscarRelatorio = (params) => {
            return this.$obterRelatorioLogFaq(params);
          };
          buscarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
            ({ data }) => {
              this.form.geradorRelatorio = data.relatorio.geradorRelatorio;
              this.form.horaDaGeracao = data.relatorio.horaDaGeracao;
              this.form.lists.listRelatorios = data.relatorio.lista;
              this.form.totalRegistros = data.relatorio.totalRegistros;
            }
          );
        }
      },
      imprimirRelatorioLogFaq() {
        this.onReport = true;
        this.form.itemsPerPage = -1;
        const importCSStoPrint = () =>
          import("!raw-loader!sass-loader!/src/assets/styles/_print.scss");
        importCSStoPrint().then((scss) => {
          let newWindow = window.open();
          newWindow.document.write(`
          <div class="report">
            <style>${scss.default}</style>
            <div class="report-header-title">
              <h1>Relatório de Log de FAQ</h1>
            </div>
            ${document.querySelector(".printable").outerHTML}
            <div class="report-header-title">
              <h3>Quantidade Total de Registros: ${
                this.form.totalRegistros
              }</h3>
              <h3>Nome do usuário de Geração: ${this.form.geradorRelatorio}</h3>
              <h3>Data/Hora da Geração: ${this.form.horaDaGeracao}</h3>
            </div>
          </div>`);
          newWindow.print();
          newWindow.close();
          this.form.itemsPerPage = 10;
          this.onReport = false;
        });
      },
      baixarCSVLogFaq() {
        const baixarRelatorio = (params) => {
          return this.$obterRelatorioLogFaqCsv(params);
        };
        baixarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
          ({ data }) => this.$baixarRelatorioCSV(data, `relatorio-log-faq`)
        );
      },
      resetForm() {
        this.$resetForm();
        this.form.lists.listRelatorios = [];
      },
      baixarRelatorioPdf() {
        const baixarPdf = (params) => {
          return this.$obterRelatorioPdf(params);
        };
        baixarPdf(this.$montarParamsFilters(this.form.filters))
        .then(({data}) => {
          

          const file = this.$getFileFromBase64(
            data.resposta.arquivoBase64, 
            data.resposta.nome, 
            data.resposta.tipoConteudo, 
            data.resposta.extensao
            );

          this.$baixarArquivo(file, data.resposta.tipoConteudo);

        });
      },
    },
  };
  </script>


<style scoped>
.resposta {
  white-space: pre-wrap;
  padding: 1em !important;
  text-align: center;
  font-weight: bold;
}
</style>