<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon>
            Visualizar Eixo Temático
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="12">
                <v-text-field
                  :value="content.nome"
                  label="Descrição Eixo Temático"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="6">
                <v-text-field
                  :value="content.ano"
                  label="Ano do Ciclo"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="content.semestre"
                  label="Semestre do Ciclo"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="12">
                <span class="small">Trilhas de Aprendizagem</span>
                <v-chip-group active-class="primary--text" column>
                  <v-chip v-for="trilha in trilhaItens" :key="trilha">
                    {{ trilha }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <div class="btn-group text-center">
          <v-btn color="primary" @click="$emit('onClose')">
            <v-icon>mdi-close</v-icon>
            Fechar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, EixoTematico } from '~/commons/classes';

export default {
  props: new DialogProps(EixoTematico),
  computed: {
    trilhaItens() {
      return this.content.trilhaItens.map(t => t.descricao)
    }
  }
};
</script>
