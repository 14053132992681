<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Edição" : "Cadastro" }} de Motivo
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="12">
                <v-text-field
                  v-model="content.descricao"
                  label="Motivo *"
                  counter="252"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $max(v, 252)]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <avp-buttons
          @click="salvar()"
          @cancel="$emit('onClose')"
          :actionText="'Salvar'"
        ></avp-buttons>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, Motivo } from "~/commons/classes";

export default {
  props: new DialogProps(Motivo),
  methods: {
    salvar() {
      const save = () => {
        return this.$swal({
          text: `Deseja confirmar ${
            this.edit ? "a alteração" : "o cadastro"
          } do Motivo?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          return this.edit
            ? this.$editarMotivo(this.content, this.content.id)
            : this.$cadastrarMotivo(this.content);
        });
      };
      if (this.$validarFormulario()) {
        save().then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.$emit("onClose");
          this.$emit("onSave");
        });
      }
    },
  },
};
</script>
