<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <avp-select-ano-semestre
            :cols="6"
            :sufix="'do Ciclo'"
            v-model="form.filters"
            all
          ></avp-select-ano-semestre>
          <avp-select-ativo :cols="3" v-model="form.filters.ativo"></avp-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarFaixasDePagamento()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterFaixasDePagamento()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listFaixasDePagamento"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template v-slot:[`item.semestre`]="{ item }">
          {{ item.semestre }}
        </template>
        <template v-slot:[`item.porcentagem`]="{ item }">
          {{ item.porcentagemDePagamento }}%
        </template>
        <template v-slot:[`item.condicao`]="{ item }">
          {{ item.condicaoDeCapacitacao | capitalize }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            :text="'Visualizar Faixa De Pagamento'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarFaixaDePagamento(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="'Editar Faixa De Pagamento'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterFaixasDePagamento(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Faixa De Pagamento`"
            :icon="`${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`"
            :small="true"
            @click="inativarAtivarFaixaDePagamento(item)"
          ></avp-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarFaixaDePagamentoDialog
      :visible="dialogManterFaixaDePagamento.visible"
      :content="dialogManterFaixaDePagamento.content"
      :edit="dialogManterFaixaDePagamento.edit"
      @onClose="dialogManterFaixaDePagamento.visible = false"
      @onSave="buscarFaixasDePagamento()"
    ></CadastrarEditarFaixaDePagamentoDialog>
    <VisualizarFaixaDePagamentoDialog
      :visible="dialogVisualizarFaixaDePagamento.visible"
      :content="dialogVisualizarFaixaDePagamento.content"
      @onClose="dialogVisualizarFaixaDePagamento.visible = false"
    ></VisualizarFaixaDePagamentoDialog>
  </div>
</template>

<script>
import { Dialog, FaixaDePagamento } from "~/commons/classes";
import CadastrarEditarFaixaDePagamentoDialog from "./dialogs/CadastrarEditarFaixaDePagamentoDialog.vue";
import VisualizarFaixaDePagamentoDialog from "./dialogs/VisualizarFaixaDePagamentoDialog.vue";

export default {
  name: "ManterFaixaDePagamentoView",
  components: {
    CadastrarEditarFaixaDePagamentoDialog,
    VisualizarFaixaDePagamentoDialog,
  },
  data: () => ({
    dialogManterFaixaDePagamento: new Dialog(),
    dialogVisualizarFaixaDePagamento: new Dialog(),
    form: {
      filters: {
        ano: null,
        semestre: null,
        ativo: null,
      },
      lists: {
        listFaixasDePagamento: [],
      }
    },
    headers: [
      { text: "Ano do Ciclo", align: "center", value: "ano" },
      { text: "Semestre do Ciclo", align: "center", value: "semestre" },
      { text: "Porcentagem de Pagamento", align: "center", value: "porcentagem" },
      { text: "Condição da Capacitação", align: "center", value: "condicao" },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ],
  }),
  methods: {
    openDialogManterFaixasDePagamento(faixa) {
      faixa
        ? this.$obterDadosFaixaDePagamento(faixa).then(({ data }) => {
            this.dialogManterFaixaDePagamento.open(new FaixaDePagamento(data.faixaDePagamento), true)
          })
        : this.dialogManterFaixaDePagamento.open(FaixaDePagamento.Build())
    },
    openDialogVisualizarFaixaDePagamento(faixa) {
      this.$obterDadosFaixaDePagamento(faixa).then(
        ({ data }) => (this.dialogVisualizarFaixaDePagamento.open(data.faixaDePagamento)));
    },
    buscarFaixasDePagamento() {
      this.$buscarFaixasDePagamento(this.$montarParamsFilters(this.form.filters)).then(
        ({ data }) => (this.form.lists.listFaixasDePagamento = data.faixaPagamento)
      );
    },
    inativarAtivarFaixaDePagamento(faixa) {
      this.$swal({
        text: `Deseja confirmar a ${
          faixa.ativo ? "inativação" : "ativação"
        } da Faixa De Pagamento?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarAtivarFaixaDePagamento({
          ...faixa,
          ativo: !faixa.ativo,
        }, faixa.id).then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.buscarFaixasDePagamento();
        });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listFaixasDePagamento = [];
    },
  },
  mounted() {
    // TODO: Essa consulta já é feita no componente <avp-select-ano-semestre>,
    // verificar se existe uma forma de não fazer novamente
    // talvez criar uma variavel no store para setar pois usa em 3 lugares fora o componente
    this.$listarAnoSemestreEdicoes().then(({ data }) => {
      const anoSemestreAtivo = data.anosSemestres.find((l) => l.ativo);
      this.form.filters.semestre = anoSemestreAtivo.semestre;
      this.form.filters.ano = anoSemestreAtivo.ano;
      this.buscarFaixasDePagamento();
    });
  }
};
</script>