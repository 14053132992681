var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-pencil")]), _vm._v(" " + _vm._s(_vm.edit ? "Editar" : "Cadastrar") + " Verba ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_vm.visible ? _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('avp-date-picker', {
    attrs: {
      "label": "Data Início da Verba",
      "required": "",
      "cols": "8",
      "dataEdicao": _vm.content.dataInicioVerba
    },
    model: {
      value: _vm.content.dataInicioVerba,
      callback: function ($$v) {
        _vm.$set(_vm.content, "dataInicioVerba", $$v);
      },
      expression: "content.dataInicioVerba"
    }
  }), _c('avp-time-picker', {
    attrs: {
      "label": "Hora Início da Verba",
      "required": "",
      "cols": "4",
      "horaEdicao": _vm.content.horaInicioVerba
    },
    model: {
      value: _vm.content.horaInicioVerba,
      callback: function ($$v) {
        _vm.$set(_vm.content, "horaInicioVerba", $$v);
      },
      expression: "content.horaInicioVerba"
    }
  }), _c('avp-date-picker', {
    attrs: {
      "label": "Data Fim da Verba",
      "required": "",
      "cols": "8",
      "dataEdicao": _vm.content.dataFimVerba
    },
    model: {
      value: _vm.content.dataFimVerba,
      callback: function ($$v) {
        _vm.$set(_vm.content, "dataFimVerba", $$v);
      },
      expression: "content.dataFimVerba"
    }
  }), _c('avp-time-picker', {
    attrs: {
      "label": "Hora Fim da Verba",
      "cols": "4",
      "required": "",
      "horaEdicao": _vm.content.horaFimVerba
    },
    model: {
      value: _vm.content.horaFimVerba,
      callback: function ($$v) {
        _vm.$set(_vm.content, "horaFimVerba", $$v);
      },
      expression: "content.horaFimVerba"
    }
  })], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Verba",
      "counter": "10",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 10);
      }]
    },
    model: {
      value: _vm.content.verba,
      callback: function ($$v) {
        _vm.$set(_vm.content, "verba", $$v);
      },
      expression: "content.verba"
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('avp-buttons', {
    attrs: {
      "actionText": 'Salvar'
    },
    on: {
      "click": function ($event) {
        return _vm.salvar();
      },
      "cancel": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }