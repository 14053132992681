<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon> Visualizar Usuário
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-card class="mt-7" v-for="(dadosUsuario, i) in maiorLista" :key="i">
            <v-row v-if="dadosUsuario.ano && dadosUsuario.semestre">
              <v-col cols="6">
                <v-text-field
                  :value="dadosUsuario.ano"
                  label="Ano"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="dadosUsuario.semestre"
                  label="Semestre"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  :value="content.nome"
                  label="Nome De Usuário"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  :value="$formatCpf(content.cpf)"
                  label="CPF"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  :value="content.usuarioSadRh ? 'Sim' : 'Não'"
                  label="Usuário SADRH"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  :value="content.email"
                  label="E-mail"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card>
              <v-row align="center">
                <v-col cols="3">
                  <v-switch
                    v-model="content.usuarioValidador"
                    label="Validador"
                    disabled
                  ></v-switch>
                </v-col>
                <v-col cols="3">
                  <v-switch
                    v-model="dadosUsuario.validarTodasLotacoes"
                    label="Validar Todas as Lotações"
                    disabled
                  ></v-switch>
                </v-col>
                <v-col cols="3">
                  <v-switch
                    v-model="dadosUsuario.visualizarTodasLotacoes"
                    label="Visualizar Todas as Lotações"
                    disabled
                  ></v-switch>
                </v-col>
                <v-col cols="3">
                  <v-switch
                    v-model="dadosUsuario.validadorAtivo"
                    label="Validador Ativo"
                    disabled
                  ></v-switch>
                </v-col>
              </v-row>
            </v-card>
            <v-card>
              <v-row>
                <v-col cols="12">
                  <span class="small">Perfis</span>
                  <v-chip-group active-class="primary--text" column>
                    <v-chip v-for="perfil in content.perfis" :key="perfil.id">
                      <v-icon class="chip" small>mdi-account-multiple</v-icon>
                      <span>{{ perfil.nome }}</span>
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-card>
            <v-card v-if="getMatriculasPorAnoSemestre(dadosUsuario).length">
              <v-row>
                <v-col cols="12">
                  <span class="small">Matrículas</span>
                  <v-chip-group active-class="primary--text" column>
                    <v-chip v-for="(mat, i) in getMatriculasPorAnoSemestre(dadosUsuario)" :key="i">
                      <v-icon class="chip" small>mdi-counter</v-icon>
                      <strong>{{ mat.matricula }}</strong>
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-card>
            <v-card v-if="content.usuarioValidador">
              <v-row>
                <v-col cols="12">
                  <span class="small">Lotações</span>
                  <v-chip-group active-class="primary--text" column>
                    <template v-for="(lotacao, i) in dadosUsuario.lotacoes">
                      <v-chip v-if="lotacao.ativa" :key="i">
                        <v-icon class="chip" small>mdi-account-multiple</v-icon>
                        <span>{{ lotacao.nome }}</span>
                      </v-chip>
                    </template>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </div>
        <div class="btn-group text-center">
          <v-btn color="primary" @click="$emit('onClose')">
            <v-icon>mdi-close</v-icon>
            Fechar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, Usuario } from "~/commons/classes";

export default {
  props: new DialogProps(Usuario),
  computed: {
    maiorLista() {
      if (
        !this.content.matriculas ||
        !this.content.validadores ||
        (!this.content.matriculas.length && !this.content.validadores.length)
      )
        return this.content.perfis

      return this.content.matriculas.length > this.content.validadores.length
        ? this.content.matriculas
        : this.content.validadores;
    },
  },
  data: () => ({
    form: {
      lists: {
        listLotacoes: [],
      },
    },
  }),
  methods: {
    getMatriculasPorAnoSemestre(dados) {
      return this.content.matriculas
        ? this.content.matriculas.filter(
            (mat) => mat.ano == dados.ano && mat.semestre == dados.semestre
          )
        : new Array();
    },
  },
};
</script>
<style scoped>
.chip {
  padding-right: 0.5em;
}
</style>