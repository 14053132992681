<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon>
            Visualizar Faixa de Pagamento
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="6">
                <v-text-field
                  :value="content.ano"
                  label="Ano do Ciclo"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="content.semestre"
                  label="Semestre do Ciclo"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="6">
                <v-text-field
                  :value="`${content.porcentagemDePagamento}%`"
                  label="Porcentagem do Pagamento"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="content.condicaoDeCapacitacao | capitalize"
                  label="Condição da Capacitação"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="6">
                <v-text-field
                  :value="`${content.cargaHorariaMinima}h`"
                  label="Carga Horária Mínima"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="content.cargaHorariaMaxima ? `${content.cargaHorariaMaxima}h` : 'Não Possui'"
                  label="Carga Horária Máxima"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <div class="btn-group text-center">
          <v-btn color="primary" @click="$emit('onClose')">
            <v-icon>mdi-close</v-icon>
            Fechar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, FaixaDePagamento } from '~/commons/classes';

export default {
  props: new DialogProps(FaixaDePagamento),
};
</script>
