var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "700"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-account-edit")]), _vm._v(" Informe o Motivo da Não Validação da Comprovação ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "Motivo",
      "items": _vm.content.motivos,
      "item-text": 'descricao',
      "item-value": 'id',
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.form.motivoSelecionado,
      callback: function ($$v) {
        _vm.$set(_vm.form, "motivoSelecionado", $$v);
      },
      expression: "form.motivoSelecionado"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "dense": "",
      "counter": "400",
      "label": "Detalhamento do Motivo",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 400);
      }]
    },
    model: {
      value: _vm.form.detalhamentoMotivo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "detalhamentoMotivo", $$v);
      },
      expression: "form.detalhamentoMotivo"
    }
  })], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('avp-buttons', {
    attrs: {
      "actionText": 'Salvar'
    },
    on: {
      "click": function ($event) {
        return _vm.salvar();
      },
      "cancel": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }