var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-eye")]), _vm._v(" Visualizar Eixo Temático ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_vm.visible ? _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.content.nome,
      "label": "Descrição Eixo Temático",
      "readonly": "",
      "outlined": "",
      "dense": ""
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.content.ano,
      "label": "Ano do Ciclo",
      "readonly": "",
      "outlined": "",
      "dense": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.content.semestre,
      "label": "Semestre do Ciclo",
      "readonly": "",
      "outlined": "",
      "dense": ""
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "small"
  }, [_vm._v("Trilhas de Aprendizagem")]), _c('v-chip-group', {
    attrs: {
      "active-class": "primary--text",
      "column": ""
    }
  }, _vm._l(_vm.trilhaItens, function (trilha) {
    return _c('v-chip', {
      key: trilha
    }, [_vm._v(" " + _vm._s(trilha) + " ")]);
  }), 1)], 1)], 1)], 1) : _vm._e()], 1), _c('div', {
    staticClass: "btn-group text-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")]), _vm._v(" Fechar ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }