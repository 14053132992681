var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-pencil")]), _vm._v(" " + _vm._s(_vm.edit ? "Editar" : "Cadastrar") + " Grupo ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_vm.visible ? _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome",
      "counter": "252",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 252);
      }]
    },
    model: {
      value: _vm.content.nome,
      callback: function ($$v) {
        _vm.$set(_vm.content, "nome", $$v);
      },
      expression: "content.nome"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Descrição",
      "counter": "252",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 252);
      }]
    },
    model: {
      value: _vm.content.nomeDeExibicao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "nomeDeExibicao", $$v);
      },
      expression: "content.nomeDeExibicao"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '##',
      expression: "'##'"
    }],
    attrs: {
      "counter": "2",
      "label": "Posição do Menu",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 2);
      }]
    },
    model: {
      value: _vm.content.posicao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "posicao", $$v);
      },
      expression: "content.posicao"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.form.lists.permissoes,
      "item-text": 'nome',
      "item-value": 'id',
      "rules": [function (p) {
        return p.some(function (v) {
          return _vm.$required(v);
        }) || 'Informe uma ou mais permissões associadas';
      }],
      "label": "Permissões *",
      "multiple": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.content.permissoes,
      callback: function ($$v) {
        _vm.$set(_vm.content, "permissoes", $$v);
      },
      expression: "content.permissoes"
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('avp-buttons', {
    attrs: {
      "actionText": _vm.edit ? 'Editar' : 'Salvar'
    },
    on: {
      "click": function ($event) {
        return _vm.salvar();
      },
      "cancel": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }