<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon>
            Visualizar Tipo de Ação de Capacitação
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="12">
                <v-text-field
                  :value="content.nome"
                  label="Tipo de Ação de Capacitação"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="6">
                <v-text-field
                  :value="content.cargaHorariaMinima"
                  label="Carga Horária Mínima"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="content.cargaHorariaMaxima"
                  label="Carga Horária Máxima"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <avp-select-ano-semestre
                :cols="12"
                :sufix="'do Ciclo'"
                v-model="content"
                disabled
              ></avp-select-ano-semestre>
            </v-row>
          </v-form>
        </div>
        <div class="btn-group text-center">
          <v-btn color="primary" @click="$emit('onClose')">
            <v-icon>mdi-close</v-icon>
            Fechar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, TipoAcaoCapacitacao } from '~/commons/classes';

export default {
  props: new DialogProps(TipoAcaoCapacitacao),
};
</script>
