<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="3">
            <v-text-field
              label="Pesquisar Termo"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <avp-select-ativo
            :cols="3"
            v-model="form.filters.ativo"
          ></avp-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarTermos()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Novo'"
              :icon="'mdi-plus'"
              @click="openDialogManterTermo()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listTermos"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.tipo`]="{ item }">
          {{ item.tipo }}
        </template>
        <template v-slot:[`item.dataInicio`]="{ item }">
          {{ item.dataInicio | date }}
        </template>
        <template v-slot:[`item.dataFim`]="{ item }">
          {{ item.dataFim | date }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            :text="'Visualizar Termo'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarTermo(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="'Editar Termo'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterTermo(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Termo`"
            :icon="`${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`"
            :small="true"
            @click="inativarAtivarTermo(item)"
          ></avp-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarTermoDialog
      :visible="dialogManterTermo.visible"
      :content="dialogManterTermo.content"
      :edit="dialogManterTermo.edit"
      @onClose="dialogManterTermo.close()"
      @onSave="buscarTermos()"
    ></CadastrarEditarTermoDialog>
    <VisualizarTermoDialog
      :visible="dialogVisualizarTermo.visible"
      :content="dialogVisualizarTermo.content"
      @onClose="dialogVisualizarTermo.close()"
    ></VisualizarTermoDialog>
  </div>
</template>

<script>
import { Dialog, Termo } from "~/commons/classes";
import CadastrarEditarTermoDialog from "./dialogs/CadastrarEditarTermosDialog.vue";
import VisualizarTermoDialog from "./dialogs/VisualizarTermosDialog.vue";

export default {
  name: "ManterTermoView",
  components: {
    CadastrarEditarTermoDialog,
    VisualizarTermoDialog,
  },
  data: () => ({
    dialogManterTermo: Dialog.Build(),
    dialogVisualizarTermo: Dialog.Build(),
    form: {
      filters: {
        nome: null,
        ativo: null,
      },
      lists: {
        listTermos: []
      }
    },
    headers: [
      { text: "Nome", align: "center", value: "nome" },
      { text: "Tipo do Termo", align: "center", value: "tipo" },
      { text: "Data Início", align: "center", value: "dataInicio" },
      { text: "Data Fim", align: "center", value: "dataFim" },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ]
  }),
  methods: {
    openDialogManterTermo(termo) {
      termo
        ? this.$visualizarTermo(termo).then(({ data }) => {
            this.dialogManterTermo.open(new Termo(data.termo).format(), true);
          })
        : this.dialogManterTermo.open(Termo.Build());
    },
    openDialogVisualizarTermo(termo) {
      this.$visualizarTermo(termo).then(
        ({ data }) => (this.dialogVisualizarTermo.open(data.termo))
      );
    },
    buscarTermos() {
      this.$buscarTermos({ params: this.form.filters }).then(
        ({ data }) => (this.form.lists.listTermos = data.termos)
      );
    },
    inativarAtivarTermo(termo) {
      this.$swal({
        text: `Deseja confirmar a ${
          termo.ativo ? "inativação" : "ativação"
        } do Termo ${termo.nome}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarAtivarTermo(
          { id: termo.id, ativo: !termo.ativo },
          termo.id
        ).then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.buscarTermos();
        });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listTermos = [];
      this.form.filters.ativo = null;
    },
  },
};
</script>
<style scoped>
.grupos {
  padding: 1em !important;
  text-align: center;
  font-weight: 500;
}
.grupo {
  padding-right: 0.5em;
}
</style>
