var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-eye")]), _vm._v(" Visualizar Usuário ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, _vm._l(_vm.maiorLista, function (dadosUsuario, i) {
    return _c('v-card', {
      key: i,
      staticClass: "mt-7"
    }, [dadosUsuario.ano && dadosUsuario.semestre ? _c('v-row', [_c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-text-field', {
      attrs: {
        "value": dadosUsuario.ano,
        "label": "Ano",
        "readonly": "",
        "outlined": "",
        "dense": ""
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-text-field', {
      attrs: {
        "value": dadosUsuario.semestre,
        "label": "Semestre",
        "readonly": "",
        "outlined": "",
        "dense": ""
      }
    })], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('v-text-field', {
      attrs: {
        "value": _vm.content.nome,
        "label": "Nome De Usuário",
        "readonly": "",
        "outlined": "",
        "dense": ""
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('v-text-field', {
      attrs: {
        "value": _vm.$formatCpf(_vm.content.cpf),
        "label": "CPF",
        "readonly": "",
        "outlined": "",
        "dense": ""
      }
    })], 1)], 1), _c('v-row', [_c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('v-text-field', {
      attrs: {
        "value": _vm.content.usuarioSadRh ? 'Sim' : 'Não',
        "label": "Usuário SADRH",
        "readonly": "",
        "outlined": "",
        "dense": ""
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('v-text-field', {
      attrs: {
        "value": _vm.content.email,
        "label": "E-mail",
        "readonly": "",
        "outlined": "",
        "dense": ""
      }
    })], 1)], 1), _c('v-card', [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('v-switch', {
      attrs: {
        "label": "Validador",
        "disabled": ""
      },
      model: {
        value: _vm.content.usuarioValidador,
        callback: function ($$v) {
          _vm.$set(_vm.content, "usuarioValidador", $$v);
        },
        expression: "content.usuarioValidador"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('v-switch', {
      attrs: {
        "label": "Validar Todas as Lotações",
        "disabled": ""
      },
      model: {
        value: dadosUsuario.validarTodasLotacoes,
        callback: function ($$v) {
          _vm.$set(dadosUsuario, "validarTodasLotacoes", $$v);
        },
        expression: "dadosUsuario.validarTodasLotacoes"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('v-switch', {
      attrs: {
        "label": "Visualizar Todas as Lotações",
        "disabled": ""
      },
      model: {
        value: dadosUsuario.visualizarTodasLotacoes,
        callback: function ($$v) {
          _vm.$set(dadosUsuario, "visualizarTodasLotacoes", $$v);
        },
        expression: "dadosUsuario.visualizarTodasLotacoes"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('v-switch', {
      attrs: {
        "label": "Validador Ativo",
        "disabled": ""
      },
      model: {
        value: dadosUsuario.validadorAtivo,
        callback: function ($$v) {
          _vm.$set(dadosUsuario, "validadorAtivo", $$v);
        },
        expression: "dadosUsuario.validadorAtivo"
      }
    })], 1)], 1)], 1), _c('v-card', [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('span', {
      staticClass: "small"
    }, [_vm._v("Perfis")]), _c('v-chip-group', {
      attrs: {
        "active-class": "primary--text",
        "column": ""
      }
    }, _vm._l(_vm.content.perfis, function (perfil) {
      return _c('v-chip', {
        key: perfil.id
      }, [_c('v-icon', {
        staticClass: "chip",
        attrs: {
          "small": ""
        }
      }, [_vm._v("mdi-account-multiple")]), _c('span', [_vm._v(_vm._s(perfil.nome))])], 1);
    }), 1)], 1)], 1)], 1), _vm.getMatriculasPorAnoSemestre(dadosUsuario).length ? _c('v-card', [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('span', {
      staticClass: "small"
    }, [_vm._v("Matrículas")]), _c('v-chip-group', {
      attrs: {
        "active-class": "primary--text",
        "column": ""
      }
    }, _vm._l(_vm.getMatriculasPorAnoSemestre(dadosUsuario), function (mat, i) {
      return _c('v-chip', {
        key: i
      }, [_c('v-icon', {
        staticClass: "chip",
        attrs: {
          "small": ""
        }
      }, [_vm._v("mdi-counter")]), _c('strong', [_vm._v(_vm._s(mat.matricula))])], 1);
    }), 1)], 1)], 1)], 1) : _vm._e(), _vm.content.usuarioValidador ? _c('v-card', [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('span', {
      staticClass: "small"
    }, [_vm._v("Lotações")]), _c('v-chip-group', {
      attrs: {
        "active-class": "primary--text",
        "column": ""
      }
    }, [_vm._l(dadosUsuario.lotacoes, function (lotacao, i) {
      return [lotacao.ativa ? _c('v-chip', {
        key: i
      }, [_c('v-icon', {
        staticClass: "chip",
        attrs: {
          "small": ""
        }
      }, [_vm._v("mdi-account-multiple")]), _c('span', [_vm._v(_vm._s(lotacao.nome))])], 1) : _vm._e()];
    })], 2)], 1)], 1)], 1) : _vm._e()], 1);
  }), 1), _c('div', {
    staticClass: "btn-group text-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")]), _vm._v(" Fechar ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }