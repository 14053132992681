<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="3">
            <v-text-field
              label="Eixo Temático"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <avp-select-ano-semestre
            :cols="4"
            :sufix="'do Ciclo'"
            v-model="form.filters"
            all
          ></avp-select-ano-semestre>
          <avp-select-ativo :cols="2" v-model="form.filters.ativo"></avp-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarEixoTematico()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterEixoTematico()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listEixoTematico"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template v-slot:[`item.semestre`]="{ item }">
          {{ item.semestre }}º
        </template>
        <template v-slot:[`item.eixo`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            :text="'Editar Eixo Tematico'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterEixoTematico(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="'Visualizar Eixo Tematico'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarEixoTematico(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Eixo Tematico`"
            :icon="`${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`"
            :small="true"
            @click="inativarAtivarEixoTematico(item)"
          ></avp-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarEixoTematicoDialog
      :visible="dialogManterEixoTematico.visible"
      :content="dialogManterEixoTematico.content"
      :edit="dialogManterEixoTematico.edit"
      @onClose="dialogManterEixoTematico.close()"
      @onSave="buscarEixoTematico()"
    ></CadastrarEditarEixoTematicoDialog>
    <VisualizarEixoTematicoDialog
      :visible="dialogVisualizarEixoTematico.visible"
      :content="dialogVisualizarEixoTematico.content"
      @onClose="dialogVisualizarEixoTematico.close()"
    ></VisualizarEixoTematicoDialog>
  </div>
</template>

<script>
import { Dialog, EixoTematico } from "~/commons/classes";
import CadastrarEditarEixoTematicoDialog from "./dialogs/CadastrarEditarEixoTematicoDialog.vue";
import VisualizarEixoTematicoDialog from "./dialogs/VisualizarEixoTematicoDialog.vue";

export default {
  name: "ManterEixoTematicoView",
  components: {
    CadastrarEditarEixoTematicoDialog,
    VisualizarEixoTematicoDialog,
  },
  data: () => ({
    dialogManterEixoTematico: new Dialog(),
    dialogVisualizarEixoTematico: new Dialog(),
    form: {
      filters: {
        nome: null,
        ano: null,
        semestre: null,
        ativo: null,
      },
      lists: {
        listEixoTematico: [],
      },
    },
    headers: [
      { text: "Ano", align: "center", value: "ano" },
      { text: "Semestre", align: "center", value: "semestre" },
      { text: "Eixo Temático", align: "center", value: "eixo" },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ],
  }),
  methods: {
    openDialogManterEixoTematico(eixo) {
      eixo
        ? this.$obterDadosEixoTematico(eixo).then(({ data }) => {
            this.dialogManterEixoTematico.open(new EixoTematico(data.eixoTematico).format(), true);
          })
        : this.dialogManterEixoTematico.open(EixoTematico.Build())
    },
    openDialogVisualizarEixoTematico(eixo) {
      this.$obterDadosEixoTematico(eixo).then(
        ({ data }) => this.dialogVisualizarEixoTematico.open(data.eixoTematico)
      );
    },
    buscarEixoTematico() {
      this.$buscarEixoTematico(this.$montarParamsFilters(this.form.filters)).then(
        ({ data }) => this.form.lists.listEixoTematico = data.eixoTematico
      );
    },
    inativarAtivarEixoTematico(eixo) {
      this.$swal({
        text: `Deseja confirmar a ${
          eixo.ativo ? "inativação" : "ativação"
        } do Eixo Tematico?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        const objEnvio = {
          ...eixo,
          ativo: !eixo.ativo,
        };
        this.$inativarAtivarEixoTematico(objEnvio, eixo.id).then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.buscarEixoTematico();
        });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listEixoTematico = [];
    },
  },
  mounted() {
    this.buscarEixoTematico();
  }
};
</script>