<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Editar" : "Cadastrar" }} Faixa de Pagamento
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <avp-select-ano-semestre
                :cols="12"
                :sufix="'do Ciclo'"
                :active="!edit"
                required
                onlyActive
                v-model="content"
              ></avp-select-ano-semestre>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="6">
                <v-text-field
                  v-model="content.porcentagemDePagamento"
                  label="Porcentagem do Pagamento (%)"
                  v-mask="'###'"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $minValue(v, 1, 'A porcentagem de pagamento deve ser maior que zero')]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="content.condicaoDeCapacitacao"
                  label="Condição da Capacitação"
                  outlined
                  dense
                  :items="$tipoCondicaoOptions"
                  :item-text="'descricao'"
                  :item-value="'value'"
                  :rules="[(v) => $required(v)]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="6">
                <v-text-field
                  v-model.number="content.cargaHorariaMinima"
                  label="Carga Horária Mínima"
                  v-mask="'######'"
                  outlined
                  dense
                  @keypress="$isNumber($event)"
                  :rules="[(v) => $minValue(v, 1, 'A carga horária mínima deve ser mais que zero')]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model.number="content.cargaHorariaMaxima"
                  label="Carga Horária Máxima"
                  v-mask="'######'"
                  outlined
                  dense
                  @keypress="$isNumber($event)"
                  :disabled="!content.cargaHorariaMinima"
                  :rules="[(v) => v ? $minValue(v, content.cargaHorariaMinima) : true]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <avp-buttons
          @click="salvar()"
          @cancel="$emit('onClose')"
          :actionText="'Salvar'"
        ></avp-buttons>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, FaixaDePagamento } from "~/commons/classes";

export default {
  props: new DialogProps(FaixaDePagamento),
  data: () => ({
    form: {
      lists: {
        listFaixasDePagamento: [],
      },
    },
  }),
  methods: {
    salvar() {
      const save = () => {
        return this.$swal({
          text: `Confirma ${
            this.edit ? "a edição" : "o cadastro"
          } da Faixa de Pagamento?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          return this.edit
            ? this.$editarFaixaDePagamento(this.content, this.content.id)
            : this.$cadastrarFaixaDePagamento(this.content);
        });
      };
      if (this.$validarFormulario()) {
        save().then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.$emit("onClose");
          this.$emit("onSave");
        });
      }
    },
  },
};
</script>
