<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <avp-select-ano-semestre
            :cols="4"
            v-model="form.filters"
            all
          ></avp-select-ano-semestre>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarRelatorios()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="$resetForm()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5 printable"
        :headers="headers"
        :items="form.lists.listRelatorios"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.estabelecimento`]="{ item }">
          {{ item.codigoEstabelecimento }}
        </template>
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.cpf`]="{ item }">
          <div class="hide-report">{{ $esconderCpf(item.cpf) }}</div>
          <div class="report">{{ $formatCpf(item.cpf) }}</div>
        </template>
        <template v-slot:[`item.matricula`]="{ item }">
          {{ item.matricula }}
        </template>
        <template v-slot:[`item.cargaHoraria`]="{ item }">
          {{ item.cargaHorariaTotal }}h
        </template>
        <template v-slot:[`item.verba`]="{ item }">
          {{ item.verba }}
        </template>
        <template v-slot:[`item.percentual`]="{ item }">
          {{ item.percentual }}%
        </template>
      </v-data-table>
      <avp-buttons
        v-if="form.lists.listRelatorios.length"
        :actionText="'Imprimir'"
        :actionIcon="'mdi-printer'"
        :cancelText="'Baixar CSV'"
        :cancelIcon="'mdi-file'"
        @click="imprimirRelatorioPagamento()"
        @cancel="baixarCSVRelatorioPagamento()"
      ></avp-buttons>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "ListarRelatoriosDePagamentoView",
  data: () => ({
    form: {
      filters: {
        ano: null,
        semestre: null,
      },
      lists: {
        listRelatorios: [],
      },
    },
    headers: [
      { text: "Estabelecimento", align: "center", value: "estabelecimento" },
      { text: "Nome", align: "center", value: "nome" },
      { text: "CPF", align: "center", value: "cpf" },
      { text: "Matrícula", align: "center", value: "matricula" },
      { text: "Carga Horária Total", align: "center", value: "cargaHoraria" },
      { text: "Verba", align: "center", value: "verba" },
      { text: "Percentual", align: "center", value: "percentual" },
    ],
  }),
  methods: {
    buscarRelatorios() {
      if (this.$validarFormulario()) {
        this.$obterRelatorioPagamento(this.$montarParamsFilters(this.form.filters)).then(
          ({ data }) => this.form.lists.listRelatorios = data.relatorio
        );
      }
    },
    imprimirRelatorioPagamento() {
      const importCSStoPrint = () =>
        import("!raw-loader!sass-loader!/src/assets/styles/_print.scss");
      importCSStoPrint().then((scss) => {
        let newWindow = window.open();
        newWindow.document.write(`
        <div class="report">
          <style>${scss.default}</style>
          <div class="report-header-title">
            <h1>Relatório de Pagamento de Adicional de Valorização Profissional</h1>
          </div>
          ${document.querySelector(".printable").outerHTML}
        </div>`);
        newWindow.print();
        newWindow.close();
      });
    },
    baixarCSVRelatorioPagamento() {
      this.$obterRelatorioCSVPagamento(this.$montarParamsFilters(this.form.filters)).then(
        ({ data }) => this.$baixarRelatorioCSV(data, "relatorio-pagamento")
      );
    },
  },
};
</script>