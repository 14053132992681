<template>
    <div class="page">
      <v-container fluid>
        <avp-breadcrumbs></avp-breadcrumbs>
        <v-form ref="form">
          <v-row class="d-flex justify-center mt-3">
            <v-col cols="4">
              <v-autocomplete
              v-model="form.filters.tipoTermo"
              label="Tipo do Termo*"
              :items="form.lists.tiposDeTermo"
              :item-text="'descricao'"
              :item-value="'id'"
              outlined
              dense
            ></v-autocomplete>
            </v-col>
          <avp-date-picker
            v-model="form.filters.dataInicio"
            label="Data início"
            removeIcon
            cols="auto"
            :dataEdicao="form.filters.dataInicio"
          ></avp-date-picker>
          <avp-date-picker
            v-model="form.filters.dataFim"
            label="Data Fim"
            removeIcon
            cols="auto"
            :dataEdicao="form.filters.dataFim"
          ></avp-date-picker>
        </v-row>
          <div class="d-flex justify-center"></div>
          <div class="text-center">
            <v-col cols="12">
              <avp-tooltip
                :text="'Buscar'"
                :icon="'mdi-magnify'"
                @click="buscarRelatorios()"
              ></avp-tooltip>
              <avp-tooltip
                :text="'Limpar'"
                :icon="'mdi-eraser'"
                @click="resetForm()"
              ></avp-tooltip>
            </v-col>
          </div>
        </v-form>

        <v-data-table
          class="mt-5 printable"
          :headers="getFilteredHeader(headers)"
          :items="form.lists.listRelatorios"
          :items-per-page="form.itemsPerPage"
          :footer-props="$footerProps"
        >
          <template v-slot:[`item.nome`]="{ item }">
            {{ item.nome }}
          </template>
          <template v-slot:[`item.tipoTermo`]="{ item }">
            {{ item.tipoTermo }}
          </template>
          <template v-slot:[`item.dataInicio`]="{ item }">
            {{ item.dataInicio }}
          </template>
          <template v-slot:[`item.dataFim`]="{ item }">
            {{ item.dataFim }}
          </template>
          <template v-slot:[`item.dataHoraAcao`]="{ item }">
            {{ item.dataHoraAcao }}
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
          </template>
          <template v-slot:[`item.acao`]="{ item }">
            {{ item.acao }}
          </template>
          <template class="report" v-slot:[`item.nomeUsuarioAcao`]="{ item }">
            {{ item.nomeUsuarioAcao }}
          </template>
          <template class="report" v-slot:[`item.usuarioAcao`]="{ item }">
            {{ item.usuarioAcao }}
          </template>
          <template class="report" v-slot:[`item.dataHoraAcao`]="{ item }">
            {{ item.dataHoraAcao }}
          </template>
        </v-data-table>
        <avp-buttons
          v-if="form.lists.listRelatorios.length"
          :actionText="'Imprimir'"
          :actionIcon="'mdi-printer'"
          :cancelText="'Baixar CSV'"
          :cancelIcon="'mdi-file'"
          @click="baixarRelatorioPdf()"
          @cancel="baixarCSVLogTermo()"
        ></avp-buttons>
      </v-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "ListarRelatoriosLogTermo",
    data: () => ({
      onReport: false,
      form: {
        filters: {
          tipoTermo: null,
          dataInicio: null,
          dataFim: null,
          tipoRelatorio: 4,
        },
        lists: {
          listRelatorios: [],
          tiposDeTermo: [],
        },
        itemsPerPage: 10,
        geradorRelatorio: null,
        horaDaGeracao: null,
        totalRegistros: 0,
      },
      headers: [
        { text: "Nome do Termo", align: "center", value: "nome" },
        { text: "Tipo do Termo", align: "center", value: "tipoTermo" },
        { text: "Data Início", align: "center", value: "dataInicio" },
        { text: "Data Fim", align: "center", value: "dataFim" },
        { text: "Data/hora da Ação", align: "center", value: "dataHoraAcao" },
        { text: "Ativo", align: "center", value: "ativo" },
        { text: "Ação", align: "center", value: "acao" },
        { text: "Nome do Usuário da Ação", align: "center", value: "nomeUsuarioAcao", onlyReport: true },
        { text: "Usuário da Ação", align: "center", value: "usuarioAcao", onlyReport: true },
      ],
    }),
    methods: {
      getFilteredHeader(headers){
        return headers.filter(header => {
          if(header.onlyView) return !this.onReport;
          if(header.onlyReport) return this.onReport;
          return true
        })
  
      },
      buscarRelatorios() {
        if (this.$validarFormulario()) {
          const buscarRelatorio = (params) => {
            return this.$obterRelatorioLogTermo(params);
          };
          buscarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
            ({ data }) => {
              this.form.geradorRelatorio = data.relatorio.geradorRelatorio;
              this.form.horaDaGeracao = data.relatorio.horaDaGeracao;
              this.form.lists.listRelatorios = data.relatorio.lista;
              this.form.totalRegistros = data.relatorio.totalRegistros;
            }
          );
        }
      },
      imprimirRelatorioLogTermo() {
        this.onReport = true;
        this.form.itemsPerPage = -1;
        const importCSStoPrint = () =>
          import("!raw-loader!sass-loader!/src/assets/styles/_print.scss");
        importCSStoPrint().then((scss) => {
          let newWindow = window.open();
          newWindow.document.write(`
          <div class="report">
            <style>${scss.default}</style>
            <div class="report-header-title">
              <h1>Relatório de Log de Termo</h1>
            </div>
            ${document.querySelector(".printable").outerHTML}
            <div class="report-header-title">
              <h3>Quantidade Total de Registros: ${
                this.form.totalRegistros
              }</h3>
              <h3>Nome do usuário de Geração: ${this.form.geradorRelatorio}</h3>
              <h3>Data/Hora da Geração: ${this.form.horaDaGeracao}</h3>
            </div>
          </div>`);
          newWindow.print();
          newWindow.close();
          this.form.itemsPerPage = 10;
          this.onReport = false;
        });
      },
      baixarCSVLogTermo() {
        const baixarRelatorio = (params) => {
          return this.$obterRelatorioLogTermoCsv(params);
        };
        baixarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
          ({ data }) => this.$baixarRelatorioCSV(data, `relatorio-log-termo`)
        );
      },
      baixarRelatorioPdf() {
        const baixarPdf = (params) => {
          return this.$obterRelatorioPdf(params);
        };
        baixarPdf(this.$montarParamsFilters(this.form.filters))
        .then(({data}) => {
          

          const file = this.$getFileFromBase64(
            data.resposta.arquivoBase64, 
            data.resposta.nome, 
            data.resposta.tipoConteudo, 
            data.resposta.extensao
            );

          this.$baixarArquivo(file, data.resposta.tipoConteudo);

        });
      },
      resetForm() {
        this.$resetForm();
        this.form.lists.listRelatorios = [];
        this.form.filters.dataInicio = null;
        this.form.filters.dataFim = null;
      }
    },
    mounted() {
      this.$listarTiposDeTermo().then(({ data }) => {
        this.form.lists.tiposDeTermo = data.termoTipos;
      })
    },
  };
  </script>

