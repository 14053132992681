<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <avp-select-lotacao
            :cols="2"
            v-model="form.filters"
            relatorio
          ></avp-select-lotacao>
          <avp-select-ano-semestre
            :cols="4"
            :sufix="'da Comprovação'"
            v-model="form.filters"
            required
            all
          ></avp-select-ano-semestre>
          <avp-select-situacao
            :cols="2"
            :exclude="[3]"
            v-model="form.filters"
            all
          ></avp-select-situacao>
          <avp-switch-button
            v-model="form.filters.analitico"
            :cols="2"
            label="Tipo de Relatório"
            labelChecked="Analítico"
            labelUnchecked="Sintético"
          ></avp-switch-button>
        </v-row>
        <div class="d-flex justify-center"></div>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarRelatorios()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <!-- Se o resultado da consulta de relatório for analítico -->
      <v-data-table
        v-if="form.mostrarAnalitico"
        class="mt-5 printable"
        :headers="getFilteredHeader(headersAnalitico)"
        :items="form.lists.listRelatorios"
        :items-per-page="form.itemsPerPage"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.profissional`]="{ item }">
          <avp-label-ativo :ativo="item.profissionalValidado"></avp-label-ativo>
        </template>
        <template class="report" v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template class="report" v-slot:[`item.semestre`]="{ item }">
          {{ item.semestre }}
        </template>
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nomeProfissional }}
        </template>
        <template v-slot:[`item.cpf`]="{ item }">
          <div class="hide-report">{{ item.cpfComMascara }}</div>
          <div class="report">{{ $formatCpf(item.cpfSemMascara) }}</div>
        </template>
        <template v-slot:[`item.matricula`]="{ item }">
          {{ item.matricula }}
        </template>
        <template v-slot:[`item.cargo`]="{ item }">
          {{ item.cargo }}
        </template>
        <template v-slot:[`item.lotacao`]="{ item }">
          {{ item.lotacao }}
        </template>
        <template v-slot:[`item.validador`]="{ item }">
          <div class="hide-report">
            {{ item.cpfUsuarioValidadorComMascara }}
          </div>
          <div class="report">
            {{ $formatCpf(item.cpfUsuarioValidadorSemMascara) }}
          </div>
        </template>
        <template v-slot:[`item.nomeValidador`]="{ item }">
          {{ item.nomeValidador }}
        </template>
        <template v-slot:[`item.dataValidacao`]="{ item }">
          {{ item.dataDeValidacao }}
        </template>
      </v-data-table>
      <!-- Se o resultado da consulta de relatório for sintético -->
      <v-data-table
        v-else
        class="mt-5 printable"
        :headers="getFilteredHeader(headersSintetico)"
        :items="form.lists.listRelatorios"
        :items-per-page="form.itemsPerPage"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template v-slot:[`item.semestre`]="{ item }">
          {{ item.semestre }}
        </template>
        <template v-slot:[`item.lotacao`]="{ item }">
          {{ item.lotacao }}
        </template>
        <template v-slot:[`item.validadas`]="{ item }">
          {{ item.comprovacoesValidadasLotacaoEdicao }}
        </template>
        <template v-slot:[`item.invalidadas`]="{ item }">
          {{ item.comprovacoesNaoValidadasLotacaoEdicao }}
        </template>
      </v-data-table>
      <avp-buttons
        v-if="form.lists.listRelatorios.length"
        :actionText="'Imprimir'"
        :actionIcon="'mdi-printer'"
        :cancelText="'Baixar CSV'"
        :cancelIcon="'mdi-file'"
        @click="imprimirRelatorioPagamento()"
        @cancel="baixarCSVRelatorioPagamento()"
      ></avp-buttons>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ListarRelatoriosDeValidacaoAcaoView",
  data: () => ({
    onReport: false,
    form: {
      filters: {
        lotacao: null,
        ano: null,
        semestre: null,
        situacao: null,
        analitico: true,
      },
      lists: {
        listRelatorios: [],
      },
      itemsPerPage: 10,
      geradorRelatorio: null,
      horaDaGeracao: null,
      totalComprovacoes: 0,
      mostrarAnalitico: true
    },
    headersAnalitico: [
      { text: "Ano", align: "center", value: "ano", onlyReport: true },
      { text: "Semestre", align: "center", value: "semestre", onlyReport: true },
      {
        text: "Profissional Validado",
        align: "center",
        value: "profissional",
        sortable: false,
      },
      { text: "Nome", align: "center", value: "nome" },
      { text: "CPF", align: "center", value: "cpf", sortable: false },
      { text: "Matrícula", align: "center", value: "matricula" },
      { text: "Cargo", align: "center", value: "cargo" },
      { text: "Lotação", align: "center", value: "lotacao" },
      {
        text: "Usuário Validador",
        align: "center",
        value: "validador",
        sortable: false,
      },
      { text: "Nome do Validador", align: "center", value: "nomeValidador" },
      { text: "Data de Validação", align: "center", value: "dataValidacao" },
    ],
    headersSintetico: [
      { text: "Ano da Comprovação", align: "center", value: "ano" },
      { text: "Semestre da Comprovação", align: "center", value: "semestre" },
      { text: "Lotação", align: "center", value: "lotacao", sortable: false },
      {
        text: "Qtd. de Comprovação Validada",
        align: "center",
        value: "validadas",
      },
      {
        text: "Qtd. de Comprovação Não Validada",
        align: "center",
        value: "invalidadas",
      },
    ],
  }),
  computed: {
    isAnalitico() {
      return this.form.filters.analitico;
    }
  },
  methods: {
    getFilteredHeader(headers){
      return headers.filter(header => {
        if(header.onlyView) return !this.onReport;
        if(header.onlyReport) return this.onReport;
        return true
      })

    },
    buscarRelatorios() {
      if (this.$validarFormulario()) {
        const buscarRelatorio = (params) => {
          return this.isAnalitico
            ? this.$obterRelatorioValidacao(params)
            : this.$obterRelatorioValidacaoSintetico(params);
        };
        buscarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
          ({ data }) => {
            this.form.mostrarAnalitico = this.isAnalitico;
            this.form.geradorRelatorio = data.relatorio.geradorRelatorio;
            this.form.horaDaGeracao = data.relatorio.horaDaGeracao;
            this.form.totalComprovacoes = data.relatorio.totalComprovacoes;
            this.form.lists.listRelatorios = data.relatorio.comprovacoes;
          }
        );
      }
    },
    imprimirRelatorioPagamento() {
      this.onReport = true;
      this.form.itemsPerPage = -1;
      const importCSStoPrint = () =>
        import("!raw-loader!sass-loader!/src/assets/styles/_print.scss");
      importCSStoPrint().then((scss) => {
        let newWindow = window.open();
        newWindow.document.write(`
        <div class="report">
          <style>${scss.default}</style>
          <div class="report-header-title">
            <h1>Relatório de Validação da Comprovação da Ação de Capacitação</h1>
          </div>
          ${document.querySelector(".printable").outerHTML}
          <div class="report-header-title">
            <h3>Quantidade Total de Validações: ${
              this.form.totalComprovacoes
            }</h3>
            <h3>Nome do usuário de Geração: ${this.form.geradorRelatorio}</h3>
            <h3>Data/Hora da Geração: ${this.form.horaDaGeracao}</h3>
          </div>
        </div>`);
        newWindow.print();
        newWindow.close();
        this.form.itemsPerPage = 10;
        this.onReport = false;
      });
    },
    baixarCSVRelatorioPagamento() {
      const baixarRelatorio = (params) => {
        return this.form.mostrarAnalitico
          ? this.$obterRelatorioCSVValidacao(params)
          : this.$obterRelatorioCSVValidacaoSintetico(params);
      };
      baixarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
        ({ data }) => this.$baixarRelatorioCSV(data, "relatorio-validacao")
      );
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listRelatorios = [];
    }
  },
};
</script>