var render = function render(){
  var _vm$form$lists$grupos, _vm$content$grupos, _vm$content$grupos2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-pencil")]), _vm._v(" " + _vm._s(_vm.edit ? "Editar" : "Cadastrar") + " Perfil ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_vm.visible ? _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome",
      "counter": "40",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 40);
      }]
    },
    model: {
      value: _vm.content.nome,
      callback: function ($$v) {
        _vm.$set(_vm.content, "nome", $$v);
      },
      expression: "content.nome"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Administrador",
      "items": [{
        label: 'Sim',
        value: true
      }, {
        label: 'Não',
        value: false
      }],
      "item-text": 'label',
      "item-value": 'value',
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.content.adm,
      callback: function ($$v) {
        _vm.$set(_vm.content, "adm", $$v);
      },
      expression: "content.adm"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    staticClass: "border"
  }, [_c('v-card-title', {
    staticClass: "d-flex justify-center"
  }, [_c('v-icon', {
    staticClass: "pr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-group")]), _vm._v(" Selecionar Grupo(s) ")], 1), !((_vm$form$lists$grupos = _vm.form.lists.grupos) !== null && _vm$form$lists$grupos !== void 0 && _vm$form$lists$grupos.length) ? _c('div', {
    class: _vm.getClassAuxiliarGruposSelecionados
  }, [_vm._v(" Arraste até aqui. "), _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-arrow-down-bold")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "drag-button"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.moverTodas();
      }
    }
  }, [_vm._v(" Adicionar Todos ")])], 1), _c('draggable', {
    staticClass: "drag-item",
    attrs: {
      "group": "grupos"
    },
    on: {
      "change": function ($event) {
        return _vm.verify($event);
      }
    },
    model: {
      value: _vm.form.lists.grupos,
      callback: function ($$v) {
        _vm.$set(_vm.form.lists, "grupos", $$v);
      },
      expression: "form.lists.grupos"
    }
  }, _vm._l(_vm.form.lists.grupos, function (item, i) {
    return _c('v-card', {
      key: i,
      staticClass: "drag-card"
    }, [_c('span', [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.nomeDeExibicao) || (item === null || item === void 0 ? void 0 : item.nome)))]), _c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
              attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "icon": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-chat-question")])], 1)];
        }
      }], null, true)
    }, [_vm._v(" Clique para arrastar e soltar no local desejado ")])], 1);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    staticClass: "border"
  }, [_c('v-card-title', {
    staticClass: "d-flex justify-center"
  }, [_c('v-icon', {
    staticClass: "pr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-select-group")]), _vm._v(" Grupo(s) Selecionados ")], 1), !((_vm$content$grupos = _vm.content.grupos) !== null && _vm$content$grupos !== void 0 && _vm$content$grupos.length) ? _c('div', {
    class: _vm.getClassAuxiliarGrupos
  }, [_vm._v(" Arraste até aqui. "), _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-arrow-down-bold")])], 1) : _vm._e()], 1), (_vm$content$grupos2 = _vm.content.grupos) !== null && _vm$content$grupos2 !== void 0 && _vm$content$grupos2.length ? _c('div', {
    staticClass: "drag-button"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.removerTodas();
      }
    }
  }, [_vm._v(" Remover Todas ")])], 1) : _vm._e(), _c('draggable', {
    staticClass: "drag-item",
    attrs: {
      "group": "grupos"
    },
    on: {
      "change": function ($event) {
        return _vm.verifyIncluidos($event);
      }
    },
    model: {
      value: _vm.content.grupos,
      callback: function ($$v) {
        _vm.$set(_vm.content, "grupos", $$v);
      },
      expression: "content.grupos"
    }
  }, _vm._l(_vm.content.grupos, function (item, i) {
    return _c('v-card', {
      key: i,
      staticClass: "drag-card"
    }, [_c('span', [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.nomeDeExibicao) || (item === null || item === void 0 ? void 0 : item.nome)))]), _c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref2) {
          var on = _ref2.on,
              attrs = _ref2.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "icon": ""
            },
            on: {
              "click": function ($event) {
                return _vm.remove(i);
              }
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-trash-can-outline")])], 1)];
        }
      }], null, true)
    }, [_vm._v(" Clique ou arraste para remover. ")])], 1);
  }), 1)], 1)], 1)], 1) : _vm._e()], 1), _c('avp-buttons', {
    attrs: {
      "actionText": 'Salvar'
    },
    on: {
      "click": function ($event) {
        return _vm.salvar();
      },
      "cancel": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }