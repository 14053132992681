<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <avp-select-lotacao
            :cols="2"
            v-model="form.filters"
            relatorio
            required
          ></avp-select-lotacao>
          <avp-select-ano-semestre
            :cols="4"
            :sufix="'da Comprovação'"
            v-model="form.filters"
            required
            all
          ></avp-select-ano-semestre>
          <avp-select-situacao
            :cols="2"
            v-model="form.filters"
            all
          ></avp-select-situacao>
          <avp-switch-button
            v-model="form.filters.analitico"
            :cols="2"
            label="Tipo de Relatório"
            labelChecked="Analítico"
            labelUnchecked="Sintético"
          ></avp-switch-button>
        </v-row>
        <div class="d-flex justify-center"></div>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarRelatorios()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="$resetForm()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <!-- Se o resultado da consulta de relatório for analítico -->
      <v-data-table
        v-if="form.mostrarAnalitico"
        class="mt-5 printable"
        :headers="getFilteredHeader(headersAnalitico)"
        :items="form.lists.listRelatorios"
        :items-per-page="form.itemsPerPage"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nomeProfissional }}
        </template>
        <template v-slot:[`item.cpf`]="{ item }">
          <div class="hide-report">
            {{ item.cpf }}
          </div>
          <div class="report">
            {{ $formatCpf(item.cpf) }}
          </div>
        </template>
        <template v-slot:[`item.nomeValidador`]="{ item }">
          {{ item.nomeValidador }}
        </template>
        <template v-slot:[`item.dataValidacao`]="{ item }">
          {{ item.dataDeValidacao }}
        </template>
      </v-data-table>
      <!-- Se o resultado da consulta de relatório for sintético -->
      <v-data-table
        v-else
        class="mt-5 printable"
        :headers="getFilteredHeader(headersSintetico)"
        :items="form.lists.listRelatorios"
        :items-per-page="form.itemsPerPage"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template v-slot:[`item.semestre`]="{ item }">
          {{ item.semestre }}
        </template>
        <template v-slot:[`item.lotacao`]="{ item }">
          {{ item.lotacao }}
        </template>
        <template v-slot:[`item.validadas`]="{ item }">
          {{ item.comprovacoesValidadasLotacaoEdicao }}
        </template>
        <template v-slot:[`item.invalidadas`]="{ item }">
          {{ item.comprovacoesNaoValidadasLotacaoEdicao }}
        </template>
      </v-data-table>
      <avp-buttons
        v-if="form.lists.listRelatorios.length"
        :actionText="'Imprimir'"
        :actionIcon="'mdi-printer'"
        :cancelText="'Baixar CSV'"
        :cancelIcon="'mdi-file'"
        @click="imprimirRelatorio()"
        @cancel="baixarCSVRelatorio()"
      ></avp-buttons>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ListarRelatoriosAcaoDeCapacitacaoView",
  data: () => ({
    onReport: false,
    form: {
      filters: {
        lotacao: null,
        ano: null,
        semestre: null,
        situacao: null,
        analitico: true,
      },
      lists: {
        listRelatorios: [],
      },
      itemsPerPage: 10,
      geradorRelatorio: null,
      horaDaGeracao: null,
      totalAcoesCapacitacao: 0,
      mostrarAnalitico: true,
    },
    headersAnalitico: [
      { text: "Ano", align: "center", value: "ano", onlyReport: true },
      {
        text: "Semestre",
        align: "center",
        value: "semestre",
        onlyReport: true,
      },
      { text: "Nome Do Profissional", align: "center", value: "nome" },
      { text: "CPF", align: "center", value: "cpf", onlyReport: true },
      {
        text: "Matrícula",
        align: "center",
        value: "matricula",
        onlyReport: true,
      },
      { text: "Lotação", align: "center", value: "lotacao", onlyPrint: true },
      {
        text: "Tipo da Capacitação",
        align: "center",
        value: "tipoAcaoCapacitacao",
      },
      {
        text: "Nome da Capacitação",
        align: "center",
        value: "nomeCapacitacao",
      },
      { text: "Eixo Temático", align: "center", value: "eixoTematico" },
      {
        text: "Trilha de Aprendizagem",
        align: "center",
        value: "trilhaAprendizagem",
      },
      { text: "Situação", align: "center", value: "situacao" },
    ],
    headersSintetico: [
      { text: "Ano", align: "center", value: "ano" },
      { text: "Semestre", align: "center", value: "semestre" },
      { text: "Lotação", align: "center", value: "lotacao", sortable: false },
      {
        text: "Qtd. de Comprovação Pendente de Validação",
        align: "center",
        value: "comprovacoesPendentesLotacaoEdicao",
        onlyReport: true,
      },
      {
        text: "Qtd. de Comprovação Validada",
        align: "center",
        value: "comprovacoesValidadasLotacaoEdicao",
        onlyReport: true,
      },
      {
        text: "Qtd. de Comprovação Não Validada",
        align: "center",
        value: "comprovacoesNaoValidadasLotacaoEdicao",
        onlyReport: true,
      },
      {
        text: "Quantidade de Comprovação de Capacitação",
        align: "center",
        value: "totalComprovacoesLotacaoEdicao",
        onlyView: true,
      },
    ],
  }),
  computed: {
    isAnalitico() {
      return this.form.filters.analitico;
    },
  },
  methods: {
    getFilteredHeader(headers) {
      return headers.filter((header) => {
        if (header.onlyView) return !this.onReport;
        if (header.onlyReport) return this.onReport;
        return true;
      });
    },
    buscarRelatorios() {
      if (this.$validarFormulario()) {
        const buscarRelatorio = (params) => {
          return this.isAnalitico
            ? this.$obterRelatorioAcaoDeCapacitacao(params)
            : this.$obterRelatorioAcaoDeCapacitacaoSintetico(params);
        };
        buscarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
          ({ data }) => {
            this.form.mostrarAnalitico = this.isAnalitico;
            this.form.geradorRelatorio = data.relatorio.geradorRelatorio;
            this.form.horaDaGeracao = data.relatorio.horaDaGeracao;
            this.form.totalAcoesCapacitacao = data.relatorio.totalComprovacoes;
            this.form.lists.listRelatorios = data.relatorio.comprovacoes;
          }
        );
      }
    },
    imprimirRelatorio() {
      this.onReport = true;
      this.form.itemsPerPage = -1;
      const importCSStoPrint = () =>
        import("!raw-loader!sass-loader!/src/assets/styles/_print.scss");
      importCSStoPrint().then((scss) => {
        let newWindow = window.open();
        newWindow.document.write(`
        <div class="report">
          <style>${scss.default}</style>
          <div class="report-header-title">
            <h1>Relatório de Ação de Capacitação</h1>
          </div>
          ${document.querySelector(".printable").outerHTML}
          <div class="report-header-title">
            <h3>Quantidade Total de Comprovação: ${
              this.form.totalAcoesCapacitacao
            }</h3>
            <h3>Nome do usuário de Geração: ${this.form.geradorRelatorio}</h3>
            <h3>Data/Hora da Geração: ${this.form.horaDaGeracao}</h3>
          </div>
        </div>`);
        newWindow.print();
        newWindow.close();
        this.form.itemsPerPage = 10;
        this.onReport = false;
      });
    },
    baixarCSVRelatorio() {
      const baixarRelatorio = (params) => {
        return params.params.analitico
          ? this.$obterRelatorioCSVAcaoDeCapacitacao(params)
          : this.$obterRelatorioCSVAcaoDeCapacitacaoSintetico(params);
      };
      baixarRelatorio(this.$montarParamsFilters(this.form.filters)).then(
        ({ data }) => this.$baixarRelatorioCSV(data, "relatorio-acao-capacitacao")
      );
    },
  },
};
</script>