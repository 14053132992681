var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-3"
  }, [_c('avp-date-picker', {
    attrs: {
      "label": "Data início da tentativa de login",
      "removeIcon": "",
      "cols": "auto",
      "dataEdicao": _vm.form.filters.dataInicioTentativaLogin
    },
    model: {
      value: _vm.form.filters.dataInicioTentativaLogin,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "dataInicioTentativaLogin", $$v);
      },
      expression: "form.filters.dataInicioTentativaLogin"
    }
  }), _c('avp-date-picker', {
    attrs: {
      "label": "Data fim da tentativa de login",
      "removeIcon": "",
      "cols": "auto",
      "dataEdicao": _vm.form.filters.dataFimTentativaLogin
    },
    model: {
      value: _vm.form.filters.dataFimTentativaLogin,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "dataFimTentativaLogin", $$v);
      },
      expression: "form.filters.dataFimTentativaLogin"
    }
  }), _c('avp-select-ano-semestre', {
    attrs: {
      "cols": 4,
      "sufix": 'da Comprovação',
      "required": "",
      "all": ""
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  })], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.filters.nome,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "nome", $$v);
      },
      expression: "form.filters.nome"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '###########',
      expression: "'###########'"
    }],
    attrs: {
      "label": "CPF",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.filters.cpf,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "cpf", $$v);
      },
      expression: "form.filters.cpf"
    }
  })], 1), _c('avp-switch-button', {
    attrs: {
      "cols": 2,
      "label": "Tipo de Relatório",
      "labelChecked": "Analítico",
      "labelUnchecked": "Sintético"
    },
    model: {
      value: _vm.form.filters.analitico,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "analitico", $$v);
      },
      expression: "form.filters.analitico"
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-center"
  }), _c('div', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-tooltip', {
    attrs: {
      "text": 'Buscar',
      "icon": 'mdi-magnify'
    },
    on: {
      "click": function ($event) {
        return _vm.buscarRelatorios();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Limpar',
      "icon": 'mdi-eraser'
    },
    on: {
      "click": function ($event) {
        return _vm.resetForm();
      }
    }
  })], 1)], 1)], 1), _vm.form.mostrarAnalitico ? _c('v-data-table', {
    staticClass: "mt-5 printable",
    attrs: {
      "headers": _vm.getFilteredHeader(_vm.headersAnalitico),
      "items": _vm.form.lists.listRelatorios,
      "items-per-page": _vm.form.itemsPerPage,
      "footer-props": _vm.$footerProps
    },
    scopedSlots: _vm._u([{
      key: `item.nome`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.nomeProfissional) + " ")];
      }
    }, {
      key: `item.cpf`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "hide-report"
        }, [_vm._v(" " + _vm._s(item.cpfComMascara) + " ")]), _c('div', {
          staticClass: "report"
        }, [_vm._v(" " + _vm._s(_vm.$formatCpf(item.cpfSemMascara)) + " ")])];
      }
    }], null, true)
  }) : _c('v-data-table', {
    staticClass: "mt-5 printable",
    attrs: {
      "headers": _vm.getFilteredHeader(_vm.headersSintetico),
      "items": _vm.form.lists.listRelatorios,
      "items-per-page": _vm.form.itemsPerPage,
      "footer-props": _vm.$footerProps
    },
    scopedSlots: _vm._u([{
      key: `item.nome`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.nomeProfissional) + " ")];
      }
    }, {
      key: `item.cpf`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "hide-report"
        }, [_vm._v(" " + _vm._s(item.cpfComMascara) + " ")]), _c('div', {
          staticClass: "report"
        }, [_vm._v(" " + _vm._s(_vm.$formatCpf(item.cpfSemMascara)) + " ")])];
      }
    }], null, true)
  }), _vm.form.lists.listRelatorios.length ? _c('avp-buttons', {
    attrs: {
      "actionText": 'Imprimir',
      "actionIcon": 'mdi-printer',
      "cancelText": 'Baixar CSV',
      "cancelIcon": 'mdi-file'
    },
    on: {
      "click": function ($event) {
        return _vm.imprimirRelatorio();
      },
      "cancel": function ($event) {
        return _vm.baixarCSVRelatorio();
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }