var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "1000"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-account-edit")]), _vm._v(" " + _vm._s(_vm.edit ? "Editar Validação da" : "Validar/Invalidar") + " Comprovação da Ação de Capacitação ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Profissional",
      "value": _vm.content.profissional,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '###.###.###-##',
      expression: "'###.###.###-##'"
    }],
    attrs: {
      "label": "CPF",
      "value": _vm.content.cpf,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Ano",
      "value": _vm.content.ano,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Semestre",
      "value": _vm.content.semestre,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome da Instituição",
      "value": _vm.content.instituicao.nomeDesOutro ? _vm.content.instituicao.nomeDesOutro : _vm.content.instituicao.nome,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Tipo da Ação de Capacitação",
      "value": _vm.content.tipoAcaoCapacitacao.nome,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Eixo Temático",
      "value": _vm.content.eixoTematico.nome,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Trilha de Aprendizagem",
      "value": _vm.content.trilhaDeAprendizagem.nome,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome da Capacitação",
      "value": _vm.content.nomeAcaoCapacitacao,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Discente/Docente",
      "value": _vm.content.tipoDaCondicaoCapacitacao,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Carga Horária",
      "value": _vm.content.cargaHoraria,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Dia, Mês e Ano de Início",
      "value": _vm.content.mesAnoInicio,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Dia, Mês e Ano de Conclusão",
      "value": _vm.content.mesAnoConclusao,
      "outlined": "",
      "dense": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "text-center"
  }, [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v("Anexo:")]), _vm.visible ? _c('v-btn', {
    directives: [{
      name: "download",
      rawName: "v-download",
      value: _vm.content.anexo.path,
      expression: "content.anexo.path"
    }],
    attrs: {
      "color": "primary",
      "outlined": ""
    }
  }, [_vm._v(" " + _vm._s(`${_vm.content.anexo.descricao}`) + " ")]) : _vm._e()], 1)], 1)], 1), _c('div', {
    staticClass: "btn-group text-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.validarComprovacao();
      }
    }
  }, [_vm._v(" Validar Comprovação ")]), _c('v-btn', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.openDialogInformarMotivo();
      }
    }
  }, [_vm._v(" Não Validar Comprovação ")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  }, [_vm._v(" Voltar ")])], 1)], 1)], 1), _c('InformarMotivoNaoValidacaoDialog', {
    attrs: {
      "visible": _vm.dialogInformarMotivo.visible,
      "content": _vm.dialogInformarMotivo.content
    },
    on: {
      "onClose": function ($event) {
        return _vm.dialogInformarMotivo.close();
      },
      "onSelectValue": function ($event) {
        return _vm.naoValidarComprovacao($event);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }