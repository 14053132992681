var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-pencil")]), _vm._v(" " + _vm._s(_vm.edit ? "Editar" : "Cadastrar") + " Termo ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_vm.visible ? _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome do Termo*",
      "counter": "50",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 50);
      }]
    },
    model: {
      value: _vm.content.nome,
      callback: function ($$v) {
        _vm.$set(_vm.content, "nome", $$v);
      },
      expression: "content.nome"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.cadastrarTipoTermo ? _c('v-text-field', {
    attrs: {
      "label": "Nome do Novo Tipo do Termo",
      "counter": "50",
      "append-icon": "mdi-close",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 50);
      }]
    },
    on: {
      "click:append": function ($event) {
        return _vm.alterarFlagCadastrarTipoTermo();
      }
    },
    model: {
      value: _vm.content.tipoDoTermo,
      callback: function ($$v) {
        _vm.$set(_vm.content, "tipoDoTermo", $$v);
      },
      expression: "content.tipoDoTermo"
    }
  }) : _c('v-autocomplete', {
    attrs: {
      "label": "Tipo do Termo*",
      "items": _vm.form.lists.tiposDeTermo,
      "item-text": 'descricao',
      "item-value": 'descricao',
      "rules": [function (v) {
        return _vm.$required(v);
      }],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.content.tipoDoTermo,
      callback: function ($$v) {
        _vm.$set(_vm.content, "tipoDoTermo", $$v);
      },
      expression: "content.tipoDoTermo"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('avp-date-picker', {
    attrs: {
      "dataEdicao": _vm.content.dataInicio,
      "label": "Data Início*",
      "cols": "6",
      "required": ""
    },
    model: {
      value: _vm.content.dataInicio,
      callback: function ($$v) {
        _vm.$set(_vm.content, "dataInicio", $$v);
      },
      expression: "content.dataInicio"
    }
  }), _c('avp-date-picker', {
    attrs: {
      "dataEdicao": _vm.content.dataFim,
      "label": "Data Fim*",
      "cols": "6",
      "required": ""
    },
    model: {
      value: _vm.content.dataFim,
      callback: function ($$v) {
        _vm.$set(_vm.content, "dataFim", $$v);
      },
      expression: "content.dataFim"
    }
  })], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-editor-html', {
    model: {
      value: _vm.content.html,
      callback: function ($$v) {
        _vm.$set(_vm.content, "html", $$v);
      },
      expression: "content.html"
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('avp-buttons', {
    attrs: {
      "actionText": 'Salvar'
    },
    on: {
      "click": function ($event) {
        return _vm.salvar();
      },
      "cancel": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }