<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="1000">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-account-edit</v-icon>
            {{ edit ? "Editar Validação da" : "Validar/Invalidar" }} Comprovação
            da Ação de Capacitação
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Profissional"
                :value="content.profissional"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="CPF"
                v-mask="'###.###.###-##'"
                :value="content.cpf"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Ano"
                :value="content.ano"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Semestre"
                :value="content.semestre"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Nome da Instituição"
                :value="
                  content.instituicao.nomeDesOutro
                    ? content.instituicao.nomeDesOutro
                    : content.instituicao.nome
                "
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Tipo da Ação de Capacitação"
                :value="content.tipoAcaoCapacitacao.nome"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Eixo Temático"
                :value="content.eixoTematico.nome"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Trilha de Aprendizagem"
                :value="content.trilhaDeAprendizagem.nome"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Nome da Capacitação"
                :value="content.nomeAcaoCapacitacao"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="d-block">
              <v-text-field
                label="Discente/Docente"
                :value="content.tipoDaCondicaoCapacitacao"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="d-block">
              <v-text-field
                label="Carga Horária"
                :value="content.cargaHoraria"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Dia, Mês e Ano de Início"
                :value="content.mesAnoInicio"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-block">
              <v-text-field
                label="Dia, Mês e Ano de Conclusão"
                :value="content.mesAnoConclusao"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col cols="12" class="d-block">
              <div>Anexo:</div>
              <v-btn
                color="primary"
                outlined
                v-if="visible"
                v-download="content.anexo.path"
              >
                <!-- TODO AJUSTAR ESSA DIRETIVA DE DOWNLOAD POIS ADICIONA AO DOM -->
                {{ `${content.anexo.descricao}` }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="btn-group text-center">
          <v-btn color="primary" @click="validarComprovacao()">
            Validar Comprovação
          </v-btn>
          <v-btn color="secondary" @click="openDialogInformarMotivo()">
            Não Validar Comprovação
          </v-btn>
          <v-btn color="primary" outlined @click="$emit('onClose')">
            Voltar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <InformarMotivoNaoValidacaoDialog
      :visible="dialogInformarMotivo.visible"
      :content="dialogInformarMotivo.content"
      @onClose="dialogInformarMotivo.close()"
      @onSelectValue="naoValidarComprovacao($event)"
    ></InformarMotivoNaoValidacaoDialog>
  </div>
</template>

<script>
import {
  Dialog,
  DialogProps,
  MotivoNaoValidacao,
  ValidarComprovacao,
} from "~/commons/classes";
import InformarMotivoNaoValidacaoDialog from "./InformarMotivoNaoValidacaoDialog.vue";

export default {
  components: { InformarMotivoNaoValidacaoDialog },
  props: new DialogProps(ValidarComprovacao),
  data: () => ({
    dialogInformarMotivo: Dialog.Build(),
  }),
  methods: {
    emitSuccess(mensagem) {
      this.$notifySuccess(mensagem);
      this.$emit("onSelectValue");
      this.$emit("onClose");
    },
    validarComprovacao() {
      this.$swal({
        text: `Confirma a validação da comprovação da ação de capacitação?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$validarComprovacao(this.content.id, {
          motivoRejeicaoId: null,
          motivoDetalhamento: null,
        }).then(({ data }) => this.emitSuccess(data.mensagem));
      });
    },
    naoValidarComprovacao(motivo) {
      this.$validarComprovacao(this.content.id, {
        motivoRejeicaoId: motivo.motivoSelecionado,
        motivoDetalhamento: motivo.detalhamentoMotivo,
      }).then(({ data }) => this.emitSuccess(data.mensagem));
    },
    openDialogInformarMotivo() {
      this.$listarMotivosParaValidar().then(({ data }) => {
        this.dialogInformarMotivo.visible = true;
        this.dialogInformarMotivo.content = this.edit
          ? new MotivoNaoValidacao({
              motivos: data.motivos,
              validacao: this.content.validacao,
            })
          : new MotivoNaoValidacao({
              motivos: data.motivos,
            });
      });
    },
  },
};
</script>
