<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="3">
            <v-text-field
              label="Perfil"
              v-model="form.filters.perfil"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <avp-select-ativo
            :cols="3"
            v-model="form.filters.ativo"
          ></avp-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarPerfis()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterPerfil()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listPerfis"
        :items-per-page="10"
        :footer-props="$footerProps"
        show-expand
      >
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <avp-label-ativo :ativo="item.ativo"></avp-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            :text="'Visualizar Perfil'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarPerfil(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="'Editar Perfil'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterPerfil(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Perfil`"
            :icon="`${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`"
            :small="true"
            @click="inativarAtivarPerfil(item)"
          ></avp-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="grupos" :colspan="headers.length">
            <v-chip-group active-class="primary--text" column>
              <v-chip
                class="chip-permissao"
                v-for="(grupo, i) in item.grupos"
                :key="i"
              >
                <v-icon class="grupo" small>
                  mdi-checkbox-marked-circle-outline
                </v-icon>
                {{ grupo.nome }}
              </v-chip>
            </v-chip-group>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarPerfilDialog
      :visible="dialogManterPerfil.visible"
      :content="dialogManterPerfil.content"
      :edit="dialogManterPerfil.edit"
      @onClose="dialogManterPerfil.close()"
      @onSave="buscarPerfis()"
    ></CadastrarEditarPerfilDialog>
    <VisualizarPerfilDialog
      :visible="dialogVisualizarPerfil.visible"
      :content="dialogVisualizarPerfil.content"
      @onClose="dialogVisualizarPerfil.close()"
    ></VisualizarPerfilDialog>
  </div>
</template>

<script>
import { Dialog, Perfil } from "~/commons/classes";
import CadastrarEditarPerfilDialog from "./dialogs/CadastrarEditarPerfilDialog.vue";
import VisualizarPerfilDialog from "./dialogs/VisualizarPerfilDialog.vue";

export default {
  name: "ManterPerfilView",
  components: {
    CadastrarEditarPerfilDialog,
    VisualizarPerfilDialog,
  },
  data: () => ({
    dialogManterPerfil: Dialog.Build(),
    dialogVisualizarPerfil: Dialog.Build(),
    form: {
      filters: {
        perfil: null,
        ativo: null,
      },
      lists: {
        listPerfis: []
      }
    },
    headers: [
      { text: "Nome", align: "center", value: "nome" },
      { text: "Grupos", align: "center", value: "data-table-expand" },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ]
  }),
  methods: {
    openDialogManterPerfil(perfil) {
      perfil
        ? this.$visualizarPerfil(perfil).then(({ data }) => {
            this.dialogManterPerfil.open(new Perfil(data.perfil), true);
          })
        : this.dialogManterPerfil.open(Perfil.Build());
    },
    openDialogVisualizarPerfil(perfil) {
      this.$visualizarPerfil(perfil).then(
        ({ data }) => (this.dialogVisualizarPerfil.open(data.perfil))
      );
    },
    buscarPerfis() {
      this.$buscarPerfis({ params: this.form.filters }).then(
        ({ data }) => (this.form.lists.listPerfis = data.perfis)
      );
    },
    inativarAtivarPerfil(perfil) {
      this.$swal({
        text: `Deseja confirmar a ${
          perfil.ativo ? "inativação" : "ativação"
        } do Perfil ${perfil.nome}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarAtivarPerfil(
          { id: perfil.id, ativo: !perfil.ativo },
          perfil.id
        ).then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.buscarPerfis();
        });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listPerfis = [];
      this.form.filters.ativo = null;
    },
  },
};
</script>
<style scoped>
.grupos {
  padding: 1em !important;
  text-align: center;
  font-weight: 500;
}
.grupo {
  padding-right: 0.5em;
}
</style>
