var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-3"
  }, [_c('avp-select-lotacao', {
    attrs: {
      "cols": 2,
      "relatorio": ""
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.filters.nome,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "nome", $$v);
      },
      expression: "form.filters.nome"
    }
  })], 1), _c('avp-select-situacao', {
    attrs: {
      "cols": 2,
      "exclude": [3],
      "all": ""
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  }), _c('avp-select-ano-semestre', {
    key: _vm.componentKey,
    attrs: {
      "cols": 4,
      "sufix": 'do Ciclo',
      "all": "",
      "disabled": _vm.disabledAnoSemestre,
      "active": _vm.form.filters.active
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-tooltip', {
    attrs: {
      "text": 'Buscar',
      "icon": 'mdi-magnify'
    },
    on: {
      "click": function ($event) {
        return _vm.buscarRelatorios();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Limpar',
      "icon": 'mdi-eraser'
    },
    on: {
      "click": function ($event) {
        return _vm.resetForm();
      }
    }
  })], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "mt-5 printable",
    attrs: {
      "headers": _vm.getFilteredHeader(_vm.headers),
      "items": _vm.form.lists.listRelatorios,
      "items-per-page": _vm.form.itemsPerPage,
      "footer-props": _vm.$footerProps,
      "show-expand": ""
    },
    scopedSlots: _vm._u([{
      key: `item.ano`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.ano) + " ")];
      }
    }, {
      key: `item.semestre`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.semestre) + " ")];
      }
    }, {
      key: `item.validado`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('avp-label-ativo', {
          attrs: {
            "ativo": item.validado
          }
        })];
      }
    }, {
      key: `item.nome`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.nome) + " ")];
      }
    }, {
      key: `item.cpf`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm.$formatCpf(item.cpf)) + " ")];
      }
    }, {
      key: `item.lotacao`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.lotacao) + " ")];
      }
    }, {
      key: "expanded-item",
      fn: function (_ref7) {
        var headers = _ref7.headers,
            item = _ref7.item;
        return [_c('td', {
          staticClass: "resposta hide-report",
          attrs: {
            "colspan": headers.length
          }
        }, [_vm._v(" " + _vm._s(item.motivo) + " ")])];
      }
    }, {
      key: `item.motivo`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.motivo) + " ")];
      }
    }, {
      key: `item.motivoDescricao`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.motivoDescricao) + " ")];
      }
    }, {
      key: `item.nomeDoValidador`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.nomeDoValidador) + " ")];
      }
    }, {
      key: `item.dataDaValidacao`,
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(item.dataDaValidacao) + " ")];
      }
    }, {
      key: `item.acao`,
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.acao) + " ")];
      }
    }], null, true)
  }), _vm.form.lists.listRelatorios.length ? _c('avp-buttons', {
    attrs: {
      "actionText": 'Imprimir',
      "actionIcon": 'mdi-printer',
      "cancelText": 'Baixar CSV',
      "cancelIcon": 'mdi-file'
    },
    on: {
      "click": function ($event) {
        return _vm.baixarRelatorioPdf();
      },
      "cancel": function ($event) {
        return _vm.baixarCSVRelatorio();
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }