var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-pencil")]), _vm._v(" " + _vm._s(_vm.edit ? "Editar" : "Cadastrar") + " Faixa de Pagamento ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_vm.visible ? _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('avp-select-ano-semestre', {
    attrs: {
      "cols": 12,
      "sufix": 'do Ciclo',
      "active": !_vm.edit,
      "required": "",
      "onlyActive": ""
    },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '###',
      expression: "'###'"
    }],
    attrs: {
      "label": "Porcentagem do Pagamento (%)",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$minValue(v, 1, 'A porcentagem de pagamento deve ser maior que zero');
      }]
    },
    model: {
      value: _vm.content.porcentagemDePagamento,
      callback: function ($$v) {
        _vm.$set(_vm.content, "porcentagemDePagamento", $$v);
      },
      expression: "content.porcentagemDePagamento"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Condição da Capacitação",
      "outlined": "",
      "dense": "",
      "items": _vm.$tipoCondicaoOptions,
      "item-text": 'descricao',
      "item-value": 'value',
      "rules": [function (v) {
        return _vm.$required(v);
      }]
    },
    model: {
      value: _vm.content.condicaoDeCapacitacao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "condicaoDeCapacitacao", $$v);
      },
      expression: "content.condicaoDeCapacitacao"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '######',
      expression: "'######'"
    }],
    attrs: {
      "label": "Carga Horária Mínima",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$minValue(v, 1, 'A carga horária mínima deve ser mais que zero');
      }]
    },
    on: {
      "keypress": function ($event) {
        return _vm.$isNumber($event);
      }
    },
    model: {
      value: _vm.content.cargaHorariaMinima,
      callback: function ($$v) {
        _vm.$set(_vm.content, "cargaHorariaMinima", _vm._n($$v));
      },
      expression: "content.cargaHorariaMinima"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '######',
      expression: "'######'"
    }],
    attrs: {
      "label": "Carga Horária Máxima",
      "outlined": "",
      "dense": "",
      "disabled": !_vm.content.cargaHorariaMinima,
      "rules": [function (v) {
        return v ? _vm.$minValue(v, _vm.content.cargaHorariaMinima) : true;
      }]
    },
    on: {
      "keypress": function ($event) {
        return _vm.$isNumber($event);
      }
    },
    model: {
      value: _vm.content.cargaHorariaMaxima,
      callback: function ($$v) {
        _vm.$set(_vm.content, "cargaHorariaMaxima", _vm._n($$v));
      },
      expression: "content.cargaHorariaMaxima"
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('avp-buttons', {
    attrs: {
      "actionText": 'Salvar'
    },
    on: {
      "click": function ($event) {
        return _vm.salvar();
      },
      "cancel": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }