<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon> Visualizar Parâmetros da Comprovação
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="12">
                <v-text-field
                  label="Nome"
                  outlined
                  dense
                  readonly
                  :value="content.nome"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Ano da comprovação"
                  outlined
                  dense
                  readonly
                  :value="content.ano"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Semestre da Comprovação"
                  outlined
                  dense
                  readonly
                  :value="content.semestre"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="6">
                <v-text-field
                  label="Data Início da Comprovação"
                  outlined
                  dense
                  readonly
                  :value="content.dataInicioDaComprovacao | date"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Data Fim da Comprovação"
                  outlined
                  dense
                  readonly
                  :value="content.dataFimDaComprovacao | date"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="6">
                <v-text-field
                  label="Data Inicio de Validação da Comprovação"
                  outlined
                  dense
                  readonly
                  :value="content.dataInicioDaValidacao | date"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Data Fim de Validação da Comprovação"
                  outlined
                  dense
                  readonly
                  :value="content.dataFimDaValidacao | date"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <div class="btn-group text-center">
          <v-btn color="primary" @click="$emit('onClose')">
            <v-icon>mdi-close</v-icon>
            Fechar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, ParametroDaComprovacao } from "~/commons/classes";

export default {
  props: new DialogProps(ParametroDaComprovacao),
};
</script>
