var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Tipo do Termo*",
      "items": _vm.form.lists.tiposDeTermo,
      "item-text": 'descricao',
      "item-value": 'id',
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.filters.tipoTermo,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "tipoTermo", $$v);
      },
      expression: "form.filters.tipoTermo"
    }
  })], 1), _c('avp-date-picker', {
    attrs: {
      "label": "Data início",
      "removeIcon": "",
      "cols": "auto",
      "dataEdicao": _vm.form.filters.dataInicio
    },
    model: {
      value: _vm.form.filters.dataInicio,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "dataInicio", $$v);
      },
      expression: "form.filters.dataInicio"
    }
  }), _c('avp-date-picker', {
    attrs: {
      "label": "Data Fim",
      "removeIcon": "",
      "cols": "auto",
      "dataEdicao": _vm.form.filters.dataFim
    },
    model: {
      value: _vm.form.filters.dataFim,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "dataFim", $$v);
      },
      expression: "form.filters.dataFim"
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-center"
  }), _c('div', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-tooltip', {
    attrs: {
      "text": 'Buscar',
      "icon": 'mdi-magnify'
    },
    on: {
      "click": function ($event) {
        return _vm.buscarRelatorios();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Limpar',
      "icon": 'mdi-eraser'
    },
    on: {
      "click": function ($event) {
        return _vm.resetForm();
      }
    }
  })], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "mt-5 printable",
    attrs: {
      "headers": _vm.getFilteredHeader(_vm.headers),
      "items": _vm.form.lists.listRelatorios,
      "items-per-page": _vm.form.itemsPerPage,
      "footer-props": _vm.$footerProps
    },
    scopedSlots: _vm._u([{
      key: `item.nome`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.nome) + " ")];
      }
    }, {
      key: `item.tipoTermo`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.tipoTermo) + " ")];
      }
    }, {
      key: `item.dataInicio`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.dataInicio) + " ")];
      }
    }, {
      key: `item.dataFim`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.dataFim) + " ")];
      }
    }, {
      key: `item.dataHoraAcao`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.dataHoraAcao) + " ")];
      }
    }, {
      key: `item.ativo`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('avp-label-ativo', {
          attrs: {
            "ativo": item.ativo
          }
        })];
      }
    }, {
      key: `item.acao`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.acao) + " ")];
      }
    }, {
      key: `item.nomeUsuarioAcao`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.nomeUsuarioAcao) + " ")];
      }
    }, {
      key: `item.usuarioAcao`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.usuarioAcao) + " ")];
      }
    }], null, true)
  }), _vm.form.lists.listRelatorios.length ? _c('avp-buttons', {
    attrs: {
      "actionText": 'Imprimir',
      "actionIcon": 'mdi-printer',
      "cancelText": 'Baixar CSV',
      "cancelIcon": 'mdi-file'
    },
    on: {
      "click": function ($event) {
        return _vm.baixarRelatorioPdf();
      },
      "cancel": function ($event) {
        return _vm.baixarCSVLogTermo();
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }