var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-pencil")]), _vm._v(" " + _vm._s(_vm.edit ? "Editar" : "Cadastrar") + " pergunta/resposta do FAQ ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_vm.visible ? _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Pergunta",
      "counter": "150",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 150);
      }, function (v) {
        return _vm.$min(v, 10);
      }]
    },
    model: {
      value: _vm.content.pergunta,
      callback: function ($$v) {
        _vm.$set(_vm.content, "pergunta", $$v);
      },
      expression: "content.pergunta"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Posição",
      "type": "number",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$minValue(v, 1);
      }]
    },
    model: {
      value: _vm.content.posicao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "posicao", $$v);
      },
      expression: "content.posicao"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "Resposta",
      "counter": "1000",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 1000);
      }, function (v) {
        return _vm.$min(v, 10);
      }]
    },
    model: {
      value: _vm.content.resposta,
      callback: function ($$v) {
        _vm.$set(_vm.content, "resposta", $$v);
      },
      expression: "content.resposta"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-combobox', {
    attrs: {
      "chips": "",
      "label": "Palavras-Chave",
      "hint": "Informe as palavras chave para essa Pergunta/Resposta",
      "multiple": "",
      "solo": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var attrs = _ref.attrs,
            item = _ref.item,
            select = _ref.select,
            selected = _ref.selected;
        return [_c('v-chip', _vm._b({
          attrs: {
            "close": "",
            "input-value": selected
          },
          on: {
            "click": select,
            "click:close": function ($event) {
              return _vm.remove(item);
            }
          }
        }, 'v-chip', attrs, false), [_c('strong', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-key")]), _vm._v(" " + _vm._s(item) + " ")], 1)])];
      }
    }], null, false, 3143207623),
    model: {
      value: _vm.content.palavrasChave,
      callback: function ($$v) {
        _vm.$set(_vm.content, "palavrasChave", $$v);
      },
      expression: "content.palavrasChave"
    }
  })], 1)], 1) : _vm._e()], 1), _c('avp-buttons', {
    attrs: {
      "actionText": 'Salvar'
    },
    on: {
      "click": function ($event) {
        return _vm.salvar();
      },
      "cancel": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }