<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon> Visualizar Dados do Ciclo
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="6">
              <v-text-field
                v-model="content.ano"
                label="Ano do Ciclo"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="content.semestre"
                label="Semestre do Ciclo"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="6">
              <v-text-field
                v-model="content.dataInicioSemestre"
                label="Data Inicio"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="content.dataFimSemestre"
                label="Data Fim"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="btn-group text-center">
            <v-btn color="primary" @click="$emit('onClose')">
              <v-icon>mdi-close</v-icon>
              Fechar
            </v-btn>
          </div>
          <template v-if="exibirTemplate">
            <span class="small text-center">Funcionalidades clonadas</span>
            <v-card class="border mt-5">
              <v-row
                class="mt-0 d-flex justify-center"
                v-if="content.clonarTrilha.ano"
              >
                <v-col cols="6">
                  <div>Clonar trilha e eixo a partir do ciclo:</div>
                </v-col>
                <avp-select-ano-semestre
                  :cols="6"
                  v-model="content.clonarTrilha"
                  :disabled="true"
                  :active="false"
                ></avp-select-ano-semestre>
              </v-row>
              <v-row
                class="mt-0 d-flex justify-center"
                v-if="content.clonarFaixaPagamento.ano"
              >
                <v-col cols="6">
                  <div>Clonar faixa de pagamento a partir do ciclo:</div>
                </v-col>
                <avp-select-ano-semestre
                  :cols="6"
                  v-model="content.clonarFaixaPagamento"
                  :disabled="true"
                  :active="false"
                ></avp-select-ano-semestre>
              </v-row>
              <v-row
                class="mt-0 d-flex justify-center"
                v-if="content.clonarValidador.ano"
              >
                <v-col cols="6">
                  <div>Clonar validador a partir do ciclo:</div>
                </v-col>
                <avp-select-ano-semestre
                  :cols="6"
                  v-model="content.clonarValidador"
                  :disabled="true"
                ></avp-select-ano-semestre>
              </v-row>
              <v-row
                class="mt-0 d-flex justify-center"
                v-if="content.clonarTipoDeAcao.ano"
              >
                <v-col cols="6">
                  <div>Clonar tipo de ação partir do ciclo:</div>
                </v-col>
                <avp-select-ano-semestre
                  :cols="6"
                  v-model="content.clonarTipoDeAcao"
                  :disabled="true"
                  :active="false"
                ></avp-select-ano-semestre>
              </v-row>
            </v-card>
          </template>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { DialogProps, Ciclo } from "~/commons/classes";

export default {
  props: new DialogProps(Ciclo),
  computed: {
    exibirTemplate() {
      // Verifica se todos os anos e semestres são nulos
      return !(
        this.content.clonarTrilha.ano === null &&
        this.content.clonarTrilha.semestre === null &&
        this.content.clonarFaixaPagamento.ano === null &&
        this.content.clonarFaixaPagamento.semestre === null &&
        this.content.clonarValidador.ano === null &&
        this.content.clonarValidador.semestre === null &&
        this.content.clonarTipoDeAcao.ano === null &&
        this.content.clonarTipoDeAcao.semestre === null
      );
    },
  }
};
</script>

<style scoped>
.v-icon.notranslate.mdi.mdi-key.theme--light {
  padding: 0.4em;
}

.title-palavras-chave {
  display: flex;
  padding: 1.5em;
  justify-content: center;
  border-bottom: 1px solid #d7d7d7;
}

.border {
  border: 1px solid #9e9e9e;
  box-shadow: none !important;
}
</style>