var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-3"
  }, [_c('avp-select-ano-semestre', {
    attrs: {
      "cols": 4,
      "all": ""
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-tooltip', {
    attrs: {
      "text": 'Buscar',
      "icon": 'mdi-magnify'
    },
    on: {
      "click": function ($event) {
        return _vm.buscarRelatorios();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Limpar',
      "icon": 'mdi-eraser'
    },
    on: {
      "click": function ($event) {
        return _vm.$resetForm();
      }
    }
  })], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "mt-5 printable",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.form.lists.listRelatorios,
      "items-per-page": 10,
      "footer-props": _vm.$footerProps
    },
    scopedSlots: _vm._u([{
      key: `item.estabelecimento`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.codigoEstabelecimento) + " ")];
      }
    }, {
      key: `item.nome`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.nome) + " ")];
      }
    }, {
      key: `item.cpf`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "hide-report"
        }, [_vm._v(_vm._s(_vm.$esconderCpf(item.cpf)))]), _c('div', {
          staticClass: "report"
        }, [_vm._v(_vm._s(_vm.$formatCpf(item.cpf)))])];
      }
    }, {
      key: `item.matricula`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.matricula) + " ")];
      }
    }, {
      key: `item.cargaHoraria`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.cargaHorariaTotal) + "h ")];
      }
    }, {
      key: `item.verba`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.verba) + " ")];
      }
    }, {
      key: `item.percentual`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.percentual) + "% ")];
      }
    }], null, true)
  }), _vm.form.lists.listRelatorios.length ? _c('avp-buttons', {
    attrs: {
      "actionText": 'Imprimir',
      "actionIcon": 'mdi-printer',
      "cancelText": 'Baixar CSV',
      "cancelIcon": 'mdi-file'
    },
    on: {
      "click": function ($event) {
        return _vm.imprimirRelatorioPagamento();
      },
      "cancel": function ($event) {
        return _vm.baixarCSVRelatorioPagamento();
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }