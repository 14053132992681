var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-3"
  }, [_c('avp-select-ano-semestre', {
    attrs: {
      "cols": 4,
      "sufix": 'da Comprovação'
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  }), _c('avp-select-ativo', {
    attrs: {
      "cols": 2
    },
    model: {
      value: _vm.form.filters.ativo,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "ativo", $$v);
      },
      expression: "form.filters.ativo"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-tooltip', {
    attrs: {
      "text": 'Buscar',
      "icon": 'mdi-magnify'
    },
    on: {
      "click": function ($event) {
        return _vm.buscarCiclo();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Limpar',
      "icon": 'mdi-eraser'
    },
    on: {
      "click": function ($event) {
        return _vm.resetForm();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Incluir',
      "icon": 'mdi-plus'
    },
    on: {
      "click": function ($event) {
        return _vm.openDialogManterCiclo();
      }
    }
  })], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "mt-5",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.form.lists.listCiclos,
      "items-per-page": 10,
      "footer-props": _vm.$footerProps
    },
    scopedSlots: _vm._u([{
      key: `item.ano`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.ano) + " ")];
      }
    }, {
      key: `item.semestre`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.semestre) + " ")];
      }
    }, {
      key: `item.dataInicioSemestre`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.$moment(item.dataInicioSemestre).format("DD/MM/YYYY")) + " ")];
      }
    }, {
      key: `item.dataFimSemestre`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.$moment(item.dataFimSemestre).format("DD/MM/YYYY")) + " ")];
      }
    }, {
      key: `item.ativo`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('avp-label-ativo', {
          attrs: {
            "ativo": item.ativo
          }
        })];
      }
    }, {
      key: `item.acoes`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('avp-tooltip', {
          attrs: {
            "text": 'Visualizar Ciclo',
            "icon": 'mdi-eye-outline',
            "small": true
          },
          on: {
            "click": function ($event) {
              return _vm.openDialogVisualizarCiclo(item);
            }
          }
        }), _c('avp-tooltip', {
          attrs: {
            "text": 'Editar Ciclo',
            "icon": 'mdi-pencil',
            "small": true
          },
          on: {
            "click": function ($event) {
              return _vm.openDialogManterCiclo(item);
            }
          }
        }), _c('avp-tooltip', {
          attrs: {
            "text": `${item.ativo ? 'Inativar' : 'Ativar'} Ciclo`,
            "icon": `${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`,
            "small": true
          },
          on: {
            "click": function ($event) {
              return _vm.inativarAtivarCiclo(item);
            }
          }
        })];
      }
    }], null, true)
  })], 1), _c('CadastrarEditarManterCicloDialog', {
    attrs: {
      "visible": _vm.dialogManterCiclo.visible,
      "content": _vm.dialogManterCiclo.content,
      "edit": _vm.dialogManterCiclo.edit
    },
    on: {
      "onClose": function ($event) {
        return _vm.dialogManterCiclo.close();
      },
      "onSave": function ($event) {
        return _vm.buscarCiclo();
      }
    }
  }), _c('VisualizarManterCicloDialog', {
    attrs: {
      "visible": _vm.dialogVisualizarCiclo.visible,
      "content": _vm.dialogVisualizarCiclo.content
    },
    on: {
      "onClose": function ($event) {
        return _vm.dialogVisualizarCiclo.close();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }