<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <avp-select-lotacao
            :cols="2"
            v-model="form.filters"
            relatorio
          ></avp-select-lotacao>
          <v-col cols="2">
            <v-text-field
              label="Nome"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <avp-select-ano-semestre
            :cols="4"
            :sufix="'do ciclo'"
            v-model="form.filters"
            all
            :active="form.filters.active"
          ></avp-select-ano-semestre>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarRelatorios()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5 printable"
        :headers="getFilteredHeader(headers)"
        :items="form.lists.listRelatorios"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template v-slot:[`item.semestre`]="{ item }">
          {{ item.semestre }}
        </template>
        <template v-slot:[`item.nomeProfissional`]="{ item }">
          {{ item.nomeProfissional }}
        </template>
        <template v-slot:[`item.cpf`]="{ item }">
          <div class="hide-report">{{ $esconderCpf(item.cpf) }}</div>
          <div class="report">{{ $formatCpf(item.cpf) }}</div>
        </template>
        <template v-slot:[`item.matricula`]="{ item }">
          {{ item.matricula }}
        </template>
        <template v-slot:[`item.descCargo`]="{ item }">
          {{ item.descCargo }}
        </template>      
         <template v-slot:[`item.descLotacao`]="{ item }">
          {{ item.descLotacao }}
        </template>
        <template v-slot:[`item.emailInstitucional`]="{ item }">
          {{ item.emailInstitucional }}
        </template>       
        <template v-slot:[`item.acao`]="{ item }">
          {{ item.acao }}
        </template>
        <template v-slot:[`item.dataHoraAcao`]="{ item }">
          <div class="report">{{ item.dataHoraAcao }}</div>
        </template>
      </v-data-table>
      <avp-buttons
        v-if="form.lists.listRelatorios.length"
        :actionText="'Imprimir'"
        :actionIcon="'mdi-printer'"
        :cancelText="'Baixar CSV'"
        :cancelIcon="'mdi-file'"
        @click="baixarRelatorioPdf()"
        @cancel="baixarCSVRelatorio()"
      ></avp-buttons>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "ListarRelatoriosDeLogDeComprovacaoDeCapacitacao",
  data: () => ({
    onReport: false,
    form: {
      filters: {
        codigoLotacao:null,
        nome:null,
        ano: null,
        semestre: null,
        tipoRelatorio: 1,
      },
      lists: {
        listRelatorios: [],
      },
      itemsPerPage: 10,
      geradorRelatorio: null,
      horaDaGeracao: null,
      totalRegistros: 0,
    },
    headers: [
      { text: "Ano", align: "center", value: "ano" },
      { text: "Semestre", align: "center", value: "semestre" },
      { text: "Nome Do Profissional", align: "center", value: "nomeProfissional" },
      { text: "CPF", align: "center", value: "cpf"},
      { text: "Matrícula", align: "center", value: "matricula" },
      { text: "Cargo", align: "center", value: "descCargo"  },
      { text: "Lotação", align: "center", value: "descLotacao" },
      { text: "E-mail Institucional", align: "center", value: "emailInstitucional" },
      { text: "Data/Hora da Ação", align: "center", value: "dataHoraAcao" , onlyReport: true},
      { text: "Ação", align: "center", value: "acao" },
    ],
  }),
  methods: {
    getFilteredHeader(headers) {
      return headers.filter(header => {
        if (header.onlyView) return !this.onReport;
        if (header.onlyReport) return this.onReport;
        return true
      })
    },
    buscarRelatorios() {
      if (this.$validarFormulario()) {
        this.$obterRelatorioLogProfissional(this.$montarParamsFilters(this.form.filters)).then(
          ({ data }) => {
          this.form.geradorRelatorio = data.relatorio.geradorRelatorio;
            this.form.horaDaGeracao = data.relatorio.horaDaGeracao;
            this.form.totalRegistros = data.relatorio.totalRegistros;
          this.form.lists.listRelatorios = data.relatorio.registros;
          }
        );
      }
    },
    imprimirRelatorio() {
      this.onReport = true;
      this.form.itemsPerPage = -1;
      const importCSStoPrint = () =>
        import("!raw-loader!sass-loader!/src/assets/styles/_print.scss");
      importCSStoPrint().then((scss) => {
        let newWindow = window.open();
        newWindow.document.write(`
        <div class="report">
          <style>${scss.default}</style>
          <div class="report-header-title">
            <h1>Relatório de log de Profissional</h1>
          </div>
          ${document.querySelector(".printable").outerHTML}
          <div class="report-header-title">
            <h3>Quantidade Total de Registros: ${
              this.form.totalRegistros
            }</h3>
            <h3>Nome do usuário de Geração: ${this.form.geradorRelatorio}</h3>
            <h3>Data/Hora da Geração: ${this.form.horaDaGeracao}</h3>
          </div>
        </div>`);
        newWindow.print();
        newWindow.close();
        this.form.itemsPerPage = 10;
        this.onReport = false;
      });
    },
    baixarCSVRelatorio() {
      this.$obterRelatorioCSVLogProfissional(this.$montarParamsFilters(this.form.filters)).then(
        ({ data }) => this.$baixarRelatorioCSV(data, "relatorio-log-profissional")
      );
    },
    baixarRelatorioPdf() {
        const baixarPdf = (params) => {
          return this.$obterRelatorioPdf(params);
        };
        baixarPdf(this.$montarParamsFilters(this.form.filters))
        .then(({data}) => {
          

          const file = this.$getFileFromBase64(
            data.resposta.arquivoBase64, 
            data.resposta.nome, 
            data.resposta.tipoConteudo, 
            data.resposta.extensao
            );

          this.$baixarArquivo(file, data.resposta.tipoConteudo);

        });
      },
    resetForm() {

        this.$resetForm();

        this.form.lists.listRelatorios = [];

      }
  },
};
</script>