var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-pencil")]), _vm._v(" " + _vm._s(_vm.edit ? "Editar" : "Cadastrar") + " Tipo de Ação de Capacitação ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_vm.visible ? _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Tipo de Ação de Capacitação",
      "counter": "50",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 50);
      }]
    },
    model: {
      value: _vm.content.nome,
      callback: function ($$v) {
        _vm.$set(_vm.content, "nome", $$v);
      },
      expression: "content.nome"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Carga Horária Mínima",
      "type": "number",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$min(v, 1);
      }, function (v) {
        return _vm.$minValue(v, 2);
      }, function (v) {
        return _vm.$maxValue(v, 99);
      }, function (v) {
        return _vm.$onlyTextAndNumbers(v);
      }]
    },
    model: {
      value: _vm.content.cargaHorariaMinima,
      callback: function ($$v) {
        _vm.$set(_vm.content, "cargaHorariaMinima", $$v);
      },
      expression: "content.cargaHorariaMinima"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Carga Horária Máxima",
      "type": "number",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$min(v, 1);
      }, function (v) {
        return _vm.$minValue(v, 2);
      }, function (v) {
        return _vm.$maxValue(v, 99);
      }, function (v) {
        return _vm.$onlyTextAndNumbers(v);
      }]
    },
    model: {
      value: _vm.content.cargaHorariaMaxima,
      callback: function ($$v) {
        _vm.$set(_vm.content, "cargaHorariaMaxima", $$v);
      },
      expression: "content.cargaHorariaMaxima"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('avp-select-ano-semestre', {
    attrs: {
      "cols": 12,
      "sufix": 'do Ciclo',
      "required": ""
    },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1)], 1) : _vm._e()], 1), _c('avp-buttons', {
    attrs: {
      "actionText": _vm.edit ? 'Editar' : 'Salvar'
    },
    on: {
      "click": function ($event) {
        return _vm.salvar();
      },
      "cancel": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }