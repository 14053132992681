var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-eye")]), _vm._v(" Visualizar dados da pergunta/resposta do FAQ ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Pergunta",
      "readonly": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.content.pergunta,
      callback: function ($$v) {
        _vm.$set(_vm.content, "pergunta", $$v);
      },
      expression: "content.pergunta"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Posição",
      "type": "number",
      "outlined": "",
      "readonly": "",
      "dense": ""
    },
    model: {
      value: _vm.content.posicao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "posicao", $$v);
      },
      expression: "content.posicao"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "Resposta",
      "outlined": "",
      "readonly": "",
      "dense": ""
    },
    model: {
      value: _vm.content.resposta,
      callback: function ($$v) {
        _vm.$set(_vm.content, "resposta", $$v);
      },
      expression: "content.resposta"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-sheet', {
    attrs: {
      "elevation": "10"
    }
  }, [_c('div', {
    staticClass: "title-palavras-chave"
  }, [_vm._v("Palavras Chave")]), _c('div', {
    staticClass: "pa-4"
  }, [_c('v-chip-group', {
    attrs: {
      "active-class": "primary--text",
      "column": ""
    }
  }, _vm._l(_vm.content.palavrasChave, function (tag) {
    return _c('v-chip', {
      key: tag
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-key")]), _vm._v(_vm._s(tag) + " ")], 1);
  }), 1)], 1)])], 1)], 1)], 1), _c('div', {
    staticClass: "btn-group text-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")]), _vm._v(" Fechar ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }