var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-eye")]), _vm._v(" Visualizar Termo ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.content.nome,
      "label": "Nome do Termo",
      "readonly": "",
      "outlined": "",
      "dense": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.content.tipoDoTermo,
      "label": "Tipo de Termo",
      "readonly": "",
      "outlined": "",
      "dense": ""
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm._f("date")(_vm.content.dataInicio),
      "label": "Data Início",
      "readonly": "",
      "outlined": "",
      "dense": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm._f("date")(_vm.content.dataFim),
      "label": "Data Fim",
      "readonly": "",
      "outlined": "",
      "dense": ""
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-editor-html', {
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.content.html,
      callback: function ($$v) {
        _vm.$set(_vm.content, "html", $$v);
      },
      expression: "content.html"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "btn-group text-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")]), _vm._v(" Fechar ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }