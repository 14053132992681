var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "800"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', [_vm._v("mdi-pencil")]), _vm._v(" " + _vm._s(_vm.edit ? "Editar" : "Cadastrar") + " Ciclo ")], 1)]), _c('div', {
    staticClass: "container dialog"
  }, [_vm.visible ? _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '####',
      expression: "'####'"
    }],
    attrs: {
      "label": "Ano do Ciclo",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$min(v, 4);
      }]
    },
    model: {
      value: _vm.content.ano,
      callback: function ($$v) {
        _vm.$set(_vm.content, "ano", $$v);
      },
      expression: "content.ano"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '####',
      expression: "'####'"
    }],
    attrs: {
      "label": "Semestre do Ciclo",
      "outlined": "",
      "dense": "",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$max(v, 4);
      }]
    },
    model: {
      value: _vm.content.semestre,
      callback: function ($$v) {
        _vm.$set(_vm.content, "semestre", $$v);
      },
      expression: "content.semestre"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  }, [_c('avp-date-picker', {
    attrs: {
      "label": "Data Início",
      "required": "",
      "removeIcon": "",
      "dataEdicao": _vm.content.dataInicioSemestre
    },
    model: {
      value: _vm.content.dataInicioSemestre,
      callback: function ($$v) {
        _vm.$set(_vm.content, "dataInicioSemestre", $$v);
      },
      expression: "content.dataInicioSemestre"
    }
  }), _c('avp-date-picker', {
    attrs: {
      "label": "Data Fim",
      "required": "",
      "removeIcon": "",
      "dataEdicao": _vm.content.dataFimSemestre
    },
    model: {
      value: _vm.content.dataFimSemestre,
      callback: function ($$v) {
        _vm.$set(_vm.content, "dataFimSemestre", $$v);
      },
      expression: "content.dataFimSemestre"
    }
  }), _c('v-checkbox', {
    staticClass: "mt-0",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('div', [_vm._v(_vm._s(_vm.edit ? "Editar Clone do" : "Clonar") + " Ciclo?")])];
      },
      proxy: true
    }], null, false, 691653231),
    model: {
      value: _vm.content.clonarCiclo,
      callback: function ($$v) {
        _vm.$set(_vm.content, "clonarCiclo", $$v);
      },
      expression: "content.clonarCiclo"
    }
  })], 1), _vm.content.clonarCiclo ? [_vm.content.clonarCiclo ? _c('v-row', {
    staticClass: "mt-0 d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_vm._v("Clonar trilha e eixo a partir do Ciclo:")])]), _c('avp-select-ano-semestre-ciclo', {
    attrs: {
      "cols": 6,
      "noClone": "",
      "tipo": 0
    },
    model: {
      value: _vm.content.clonarTrilha,
      callback: function ($$v) {
        _vm.$set(_vm.content, "clonarTrilha", $$v);
      },
      expression: "content.clonarTrilha"
    }
  })], 1) : _vm._e(), _vm.content.clonarCiclo ? _c('v-row', {
    staticClass: "mt-0 d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_vm._v("Clonar faixa de pagamento a partir do Ciclo:")])]), _c('avp-select-ano-semestre-ciclo', {
    attrs: {
      "cols": 6,
      "noClone": "",
      "tipo": 1
    },
    model: {
      value: _vm.content.clonarFaixaPagamento,
      callback: function ($$v) {
        _vm.$set(_vm.content, "clonarFaixaPagamento", $$v);
      },
      expression: "content.clonarFaixaPagamento"
    }
  })], 1) : _vm._e(), _vm.content.clonarCiclo ? _c('v-row', {
    staticClass: "mt-0 d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_vm._v("Clonar validador a partir do Ciclo:")])]), _c('avp-select-ano-semestre-ciclo', {
    attrs: {
      "cols": 6,
      "noClone": "",
      "tipo": 2
    },
    model: {
      value: _vm.content.clonarValidador,
      callback: function ($$v) {
        _vm.$set(_vm.content, "clonarValidador", $$v);
      },
      expression: "content.clonarValidador"
    }
  })], 1) : _vm._e(), _vm.content.clonarCiclo ? _c('v-row', {
    staticClass: "mt-0 d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_vm._v("Clonar tipo de ação partir do Ciclo:")])]), _c('avp-select-ano-semestre-ciclo', {
    attrs: {
      "cols": 6,
      "noClone": "",
      "tipo": 3
    },
    model: {
      value: _vm.content.clonarTipoDeAcao,
      callback: function ($$v) {
        _vm.$set(_vm.content, "clonarTipoDeAcao", $$v);
      },
      expression: "content.clonarTipoDeAcao"
    }
  })], 1) : _vm._e()] : _vm._e()], 2) : _vm._e()], 1), _c('avp-buttons', {
    attrs: {
      "actionText": 'Salvar'
    },
    on: {
      "click": function ($event) {
        return _vm.salvar();
      },
      "cancel": function ($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }