var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-3"
  }, [_c('avp-select-lotacao', {
    attrs: {
      "cols": 4,
      "relatorio": ""
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  }), _c('avp-select-ano-semestre', {
    attrs: {
      "cols": 4,
      "sufix": 'do Ciclo',
      "required": "",
      "all": ""
    },
    model: {
      value: _vm.form.filters,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filters", $$v);
      },
      expression: "form.filters"
    }
  })], 1), _c('v-row', {
    staticClass: "d-flex justify-center mt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.filters.nome,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "nome", $$v);
      },
      expression: "form.filters.nome"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '###########',
      expression: "'###########'"
    }],
    attrs: {
      "label": "CPF",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.filters.cpf,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "cpf", $$v);
      },
      expression: "form.filters.cpf"
    }
  })], 1), _c('avp-switch-button', {
    attrs: {
      "cols": 2,
      "label": "Tipo de Relatório",
      "labelChecked": "Analítico",
      "labelUnchecked": "Sintético"
    },
    model: {
      value: _vm.form.filters.analitico,
      callback: function ($$v) {
        _vm.$set(_vm.form.filters, "analitico", $$v);
      },
      expression: "form.filters.analitico"
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-center"
  }), _c('div', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('avp-tooltip', {
    attrs: {
      "text": 'Buscar',
      "icon": 'mdi-magnify'
    },
    on: {
      "click": function ($event) {
        return _vm.buscarRelatorios();
      }
    }
  }), _c('avp-tooltip', {
    attrs: {
      "text": 'Limpar',
      "icon": 'mdi-eraser'
    },
    on: {
      "click": function ($event) {
        return _vm.resetForm();
      }
    }
  })], 1)], 1)], 1), _vm.form.mostrarAnalitico ? _c('v-data-table', {
    staticClass: "mt-5 printable",
    attrs: {
      "headers": _vm.getFilteredHeader(_vm.headersAnalitico),
      "items": _vm.form.lists.listRelatorios,
      "items-per-page": _vm.form.itemsPerPage,
      "footer-props": _vm.$footerProps
    },
    scopedSlots: _vm._u([{
      key: `item.ano`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.ano) + " ")];
      }
    }, {
      key: `item.semestre`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.semestre) + " ")];
      }
    }, {
      key: `item.nome`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.nome) + " ")];
      }
    }, {
      key: `item.cpf`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "hide-report"
        }, [_vm._v(_vm._s(_vm.$esconderCpf(item.cpf)))]), _c('div', {
          staticClass: "report"
        }, [_vm._v(_vm._s(_vm.$formatCpf(item.cpf)))])];
      }
    }, {
      key: `item.matricula`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.matricula) + " ")];
      }
    }, {
      key: `item.cargo`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.cargo) + " ")];
      }
    }, {
      key: `item.lotacao`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.lotacao) + " ")];
      }
    }, {
      key: `item.realizaLogin`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.realizaLogin ? "Sim" : "Não") + " ")];
      }
    }, {
      key: `item.aceiteTermo`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.aceiteTermo ? "Sim" : "Não") + " ")];
      }
    }, {
      key: `item.cadastroAcao`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.cadastroAcao ? "Sim" : "Não") + " ")];
      }
    }, {
      key: `item.validacaoComprovacao`,
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(item.validacaoComprovacao ? "Sim" : "Não") + " ")];
      }
    }], null, true)
  }) : _c('v-data-table', {
    staticClass: "mt-5 printable",
    attrs: {
      "headers": _vm.getFilteredHeader(_vm.headersSintetico),
      "items": _vm.form.lists.listRelatorios,
      "items-per-page": _vm.form.itemsPerPage,
      "footer-props": _vm.$footerProps
    },
    scopedSlots: _vm._u([{
      key: `item.ano`,
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.ano) + " ")];
      }
    }, {
      key: `item.semestre`,
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(item.semestre) + " ")];
      }
    }, {
      key: `item.lotacao`,
      fn: function (_ref14) {
        var item = _ref14.item;
        return [_vm._v(" " + _vm._s(item.lotacao) + " ")];
      }
    }, {
      key: `item.qtdRealizaLogin`,
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_vm._v(" " + _vm._s(item.qtdRealizaLogin) + " ")];
      }
    }, {
      key: `item.qtdAceiteTermo`,
      fn: function (_ref16) {
        var item = _ref16.item;
        return [_vm._v(" " + _vm._s(item.qtdAceiteTermo) + " ")];
      }
    }, {
      key: `item.qtdCadastroAcao`,
      fn: function (_ref17) {
        var item = _ref17.item;
        return [_vm._v(" " + _vm._s(item.qtdCadastroAcao) + " ")];
      }
    }, {
      key: `item.qtdValidacaoComprovacao`,
      fn: function (_ref18) {
        var item = _ref18.item;
        return [_vm._v(" " + _vm._s(item.qtdValidacaoComprovacao) + " ")];
      }
    }], null, true)
  }), _vm.form.lists.listRelatorios.length ? _c('avp-buttons', {
    attrs: {
      "actionText": 'Imprimir',
      "actionIcon": 'mdi-printer',
      "cancelText": 'Baixar CSV',
      "cancelIcon": 'mdi-file'
    },
    on: {
      "click": function ($event) {
        return _vm.baixarRelatorioPdf();
      },
      "cancel": function ($event) {
        return _vm.baixarCSVQuantitativoCiclo();
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }