<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Editar" : "Cadastrar" }} Usuário
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="4">
                <v-text-field
                  v-model="content.nome"
                  label="Nome do Usuário*"
                  counter="252"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $max(v, 252)]"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="content.cpf"
                  v-mask="'###.###.###-##'"
                  label="CPF*"
                  outlined
                  dense
                  :rules="[(v) => $required(v)]"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="content.email"
                  label="E-mail*"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $email(v)]"
                  ></v-text-field>
              </v-col>
              <v-col cols="9">
                <v-autocomplete
                  v-model="content.perfis"
                  label="Perfil*"
                  outlined
                  dense
                  multiple
                  :items="form.lists.listPerfis"
                  :item-text="'nome'"
                  :item-value="'id'"
                  :rules="[
                    (p) => p.some((v) => $required(v)) || 'Campo obrigatório',
                  ]"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-switch
                  v-model="content.usuarioSadRh"
                  :disabled="edit"
                  label="Usuário SADRH"
                  class="no-margin"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row v-if="content.perfis.includes(5) || content.usuarioValidador">
              <avp-select-ano-semestre
                :cols="12"
                :active="false"
                :sufix="'*'"
                required
                v-model="content"
              ></avp-select-ano-semestre>
            </v-row>
            <v-row v-if="content.usuarioValidador">
              <v-col cols="12">
                <v-autocomplete
                  v-model="content.lotacoes"
                  label="Lotações"
                  multiple
                  outlined
                  dense
                  :items="form.lists.listLotacoes"
                  :item-text="'nome'"
                  :item-value="'codigo'"
                  @input="searchInput= null"
                  :search-input.sync="searchInput"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-card>
              <v-row align="center" justify="center">
                <v-col cols="3">
                  <v-switch
                    v-model="content.usuarioValidador"
                    label="Validador"
                  ></v-switch>
                </v-col>
                <v-col v-if="content.usuarioValidador" cols="3">
                  <v-switch
                    v-model="content.validarTodasLotacoes"
                    label="Validar Todas as Lotações"
                  ></v-switch>
                </v-col>
                <v-col v-if="content.usuarioValidador" cols="3">
                  <v-switch
                    v-model="content.visualizarTodasLotacoes"
                    label="Visualizar Todas as Lotações"
                  ></v-switch>
                </v-col>
                <v-col v-if="content.usuarioValidador" cols="3">
                  <v-switch
                    v-model="content.validadorAtivo"
                    label="Validador Ativo"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </div>
        <avp-buttons
          @click="salvar()"
          @cancel="$emit('onClose')"
          :actionText="edit ? 'Editar' : 'Salvar'"
        ></avp-buttons>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, Usuario } from "~/commons/classes";

export default {
  props: new DialogProps(Usuario),
  data: () => ({
    form: {
      lists: {
        listGrupos: [],
        listPerfis: [],
        listLotacoes: [],
      },
    },
    searchInput: null,
  }),
  watch: {
    visible(value) {
      value && this.buscarLotacoes();
    },
    "content.usuarioValidador": {
      handler(v) {
        v || (this.content.validadorAtivo = false);
      },
    },
  },
  methods: {
    salvar() {
      const save = () => {
        return this.$swal({
          text: `Confirma ${this.edit ? "a edição" : "o cadastro"} do Usuário?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          this.content.cpf = this.$unMask(this.content.cpf);
          return this.edit
            ? this.$editarUsuario(this.content, this.content.id)
            : this.$cadastrarUsuario(this.content);
        });
      };
      if (this.$validarFormulario()) {
        save().then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.$emit("onClose");
          this.$emit("onSave");
        });
      }
    },
    buscarLotacoes() {
      this.$buscarLotacoesUsuario({ params: { filhas: true } }).then(
        ({ data }) => {
          if (this.edit) {
            // inclui todas as lotações mães e as filhas na lista de lotação
            new Promise((resolve) => {
              const lotacoes = new Array();
              data.lotacoes.forEach(
                (l) =>
                  lotacoes.push(l) && l.filhas.forEach((f) => lotacoes.push(f))
              );
              return resolve(lotacoes);
            }).then((list) => {
              this.form.lists.listLotacoes = list.map((l) => ({
                nome: l.lotacaoDsMae || l.lotacaoDsFilha,
                codigo: l.lotacaoCodSadMae || l.lotacaoCodSadFilha,
              }));
            });
          } else {
            // inclui apenas as lotações mães
            this.form.lists.listLotacoes = data.lotacoes.map((l) => ({
              nome: `${l.lotacaoDsMae.trim()}`,
              codigo: l.lotacaoCodSadMae,
            }));
          }
        }
      );
    },
  },
  created() {
    this.$buscarPerfis({
      params: {
        semProfissionais: true,
      },
    }).then(({ data }) => {
      this.form.lists.listPerfis = data.perfis;
    });
  },
};
</script>
<style scoped>
.no-margin {
  margin-top: 0.4em;
  padding: 0;
}
</style>

